import { Collection } from 'src/types';
import { db, auth } from '../../firebase';
import {
  INVOICE_HAS_MORE,
  INVOICE_LIMIT,
  INVOICE_LIST_FAIL,
  INVOICE_LIST_REQUEST,
  INVOICE_LIST_SUCCESS,
} from '../constants/invoiceConstants';

export const getInvoices = (userId) => async (dispatch, getState) => {
  dispatch({
    type: INVOICE_LIST_REQUEST,
  });
  try {
    // TODO: Change user id to current user
    const customerSnap = await db.collection(Collection.STRIPE_CUSTOMERS).doc(userId).get();
    let invoicesSnap;
    const { invoices } = getState().invoice;
    if (invoices.length && invoices[invoices.length - 1]) {
      invoicesSnap = await customerSnap.ref
        .collection(Collection.INVOICES)
        .orderBy('invoice.created', 'desc')
        .startAfter(invoices[invoices.length - 1].invoice.created)
        .limit(INVOICE_LIMIT)
        .get();
    } else {
      invoicesSnap = await customerSnap.ref
        .collection(Collection.INVOICES)
        .orderBy('invoice.created', 'desc')
        .limit(INVOICE_LIMIT)
        .get();
    }
    const invoicesArr = [];
    invoicesSnap.forEach((snapChild) => {
      const data = snapChild.data();
      invoicesArr.push(data);
    });
    dispatch({
      type: INVOICE_LIST_SUCCESS,
      payload: invoicesArr,
    });
    if (invoicesArr.length < INVOICE_LIMIT) {
      dispatch({
        type: INVOICE_HAS_MORE,
        payload: false,
      });
    } else {
      dispatch({
        type: INVOICE_HAS_MORE,
        payload: true,
      });
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: INVOICE_LIST_FAIL,
    });
  }
};
