import { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import ButtonIcon from '../common/ButtonIcon';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../common/Loader';
import { getFacilities } from '../../redux/actions/facilityActions';
import AddFacilityModal from '../calendar/AddFacilityModal';

const sNoFormatter = (_: any, __: any, index: number) => <span>{index + 1}</span>;

const columns = [
  {
    dataField: '',
    text: '#',
    headerClasses: 'border-0',
    classes: 'border-0 py-2 align-middle',
    formatter: sNoFormatter,
    sort: true,
  },
  {
    dataField: 'facilityName',
    text: 'Name',
    headerClasses: 'border-0',
    classes: 'border-0 py-2 align-middle',
    sort: true,
  },
  {
    dataField: 'facilityCity',
    text: 'City',
    headerClasses: 'border-0',
    classes: 'border-0 py-2 align-middle',
    sort: true,
  },
  {
    dataField: 'facilityState',
    text: 'State',
    headerClasses: 'border-0',
    classes: 'border-0 py-2 align-middle',
    sort: true,
  },
];

interface FacilitiesTableProps {
  companyId: string;
}

const FacilitiesTable = ({ companyId }: FacilitiesTableProps) => {
  const [isOpenCreateFacilityModal, setIsOpenCreateFacilityModal] = useState(false);
  // @ts-ignore
  const { facilities, loading } = useSelector((state) => state.facility);
  const dispatch = useDispatch();
  useEffect(() => {
    if (companyId) {
      dispatch(getFacilities({ companyId }));
    }
  }, [dispatch, companyId]);
  return (
    <>
      <Card className="mb-3">
        <FalconCardHeader title="Facilities" light={false}>
          <>
            <>
              <ButtonIcon
                onClick={() => setIsOpenCreateFacilityModal(true)}
                icon="plus"
                transform="shrink-3 down-2"
                color="falcon-default"
                size="sm"
              >
                New
              </ButtonIcon>
            </>
          </>
        </FalconCardHeader>

        {facilities?.length > 0 ? (
          <CardBody className="p-0">
            <>
              <div className="table-responsive">
                {loading ? (
                  <Loader />
                ) : (
                  <BootstrapTable
                    bootstrap4
                    keyField="id"
                    data={facilities}
                    columns={columns}
                    bordered={false}
                    classes="table-dashboard table-striped table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                    rowClasses="btn-reveal-trigger border-top border-200"
                    headerClasses="bg-200 text-900 border-y border-200"
                  />
                )}
              </div>
            </>
          </CardBody>
        ) : loading ? (
          <Loader />
        ) : null}
      </Card>
      <AddFacilityModal
        isOpenCreateFacilityModal={isOpenCreateFacilityModal}
        setIsOpenCreateFacilityModal={setIsOpenCreateFacilityModal}
        companyId={companyId}
      />
    </>
  );
};

export default FacilitiesTable;
