import { shift } from 'src/test/fixtures/shifts';
import { resourceLimits } from 'worker_threads';
import { auth, db } from '../firebase';
import { CancellationReasonType, Collection, Shift, ShiftStatus, TimesheetAction, WithId } from '../types';
import { getTimesheetActionUsersFromShifts, getWorkersFromShifts } from './worker';

const shiftsCollection = db.collection(Collection.SHIFTS);

export const getShiftData = async (id: string): Promise<Shift> => {
  const shift = await getShift(id);
  return shift.data() as Shift;
};

export const getShift = (id: string) => {
  return shiftsCollection.doc(id).get();
};

export const getAllShifts = () => {
  return shiftsCollection.get();
};

export const getShiftRef = (shiftId: string) => {
  if (shiftId) {
    return shiftsCollection.doc(shiftId);
  }
  return shiftsCollection;
};

export const updateShift = ({ shiftId, data }: { shiftId: string; data: Partial<Shift> }) => {
  return shiftsCollection.doc(shiftId).update(data);
};

export const cancelShift = async (
  shiftId: string,
  reason: CancellationReasonType | null,
  reasonOtherDetails: string | undefined
) => {
  const shiftData = await getShiftData(shiftId);
  if (!shiftData) {
    return {
      error: true,
      message: 'Failed to cancel shift. Please try again later or contact support.',
    };
  }
  const now = new Date();
  if (shiftData.start.toDate() < now && shiftData.nurseId) {
    return { error: true, message: 'Shifts that have already started cannot be cancelled' };
  }
  await db
    .collection(Collection.SHIFTS)
    .doc(shiftId)
    .set(
      {
        status: 'cancelled',
        cancelledAt: new Date(),
        cancelledBy: auth.currentUser?.uid,
        cancellationReason: {
          type: reason,
          ...(reasonOtherDetails ? { details: reasonOtherDetails } : {}),
        },
      },
      { merge: true }
    );
  return { error: false, message: '' };
};

export const getShiftsByDate = async (facilityIds: string[], date: Date): Promise<WithId<Shift>[]> => {
  const nextSunday = new Date(date);
  nextSunday.setDate(date.getDate() + 7);

  const shifts = await db
    .collection(Collection.SHIFTS)
    .where('facilityIdentifier', 'in', facilityIds)
    .where('start', '>', date)
    .where('start', '<', nextSunday)
    .orderBy('start', 'asc')
    .get();
  const result: WithId<Shift>[] = [];
  const now = new Date();
  const THIRTY_MIN_IN_MS = 30 * 60 * 1000;

  shifts.docs.forEach((shift) => {
    const shData = shift.data() as Shift;
    const shiftEnd = shData.end.toDate();
    if (
      shData.status === ShiftStatus.CANCELLED ||
      !shData.nurseId ||
      // Don't show shifts that ended < 30min ago
      now.getTime() - shiftEnd.getTime() < THIRTY_MIN_IN_MS
    ) {
      return;
    }
    const shiftResult = { id: shift.id, ...shData };
    if (!shiftResult.clockIn) {
      shiftResult.clockIn = shiftResult.start;
    }
    if (!shiftResult.clockOut) {
      shiftResult.clockOut = shiftResult.end;
    }
    result.push(shiftResult);
  });
  const nurses = await getWorkersFromShifts(result);
  const timesheetActionMadeBy = await getTimesheetActionUsersFromShifts(result);
  return result.map((res) => ({
    ...res,
    ...(res.nurseId
      ? { workerFullName: `${nurses[res.nurseId].firstName.trim()} ${nurses[res.nurseId].lastName.trim()}` }
      : {}),
    ...(res.timesheetActionMadeBy
      ? {
          timesheetActionMadeByFullName: `${timesheetActionMadeBy[
            res.timesheetActionMadeBy
          ].firstName.trim()} ${timesheetActionMadeBy[res.timesheetActionMadeBy].lastName.trim()}`,
        }
      : {}),
  }));
};

export const timesheetActionForShift = async (userId: string, shiftId: string, timesheetAction: TimesheetAction) => {
  return db.collection(Collection.SHIFTS).doc(shiftId).update({
    timesheetActionMadeBy: userId,
    timesheetActionMadeAt: new Date(),
    timesheetAction,
  });
};

export const signOffShifts = (userId: string, shiftIds: string[]) => {
  const batch = db.batch();
  const date = new Date();
  shiftIds.forEach((shiftId) => {
    const shiftRef = db.collection(Collection.SHIFTS).doc(shiftId);
    batch.update(shiftRef, {
      timesheetActionMadeBy: userId,
      timesheetActionMadeAt: date,
      timesheetAction: TimesheetAction.APPROVED,
    });
  });
  return batch.commit();
};
