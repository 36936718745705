import { useState, useEffect, FormEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import withRedirect from '../../hoc/withRedirect';
import { useDispatch } from 'react-redux';
import { resetPassword } from '../../redux/actions/resetPassword';

interface ForgetPasswordFormProps {
  setRedirect: (redirect: boolean) => void;
  setRedirectUrl: (redirectUrl: string) => void;
  layout?: string;
}

const ForgetPasswordForm = ({ setRedirect, setRedirectUrl, layout = 'basic' }: ForgetPasswordFormProps) => {
  // State
  const [email, setEmail] = useState('');

  const dispatch = useDispatch();

  // Handler
  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    if (email) {
      localStorage.setItem('passwordResetEmail', JSON.stringify(email));
      dispatch(resetPassword(email));
      toast.success(`An email has been sent to ${email} with password reset link`);
      setRedirect(true);
    }
  };

  useEffect(() => {
    setRedirectUrl(`/authentication/${layout}/confirm-mail`);
  }, [setRedirectUrl, layout]);

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <FormGroup>
        <Input
          className="form-control"
          placeholder="Email address"
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </FormGroup>
      <FormGroup>
        <Button color="primary" block disabled={!email}>
          Send reset link
        </Button>
      </FormGroup>
      <Link className="fs--1 text-600" to="#!">
        {`I can't recover my account using this page`}
        <span className="d-inline-block ml-1">&rarr;</span>
      </Link>
    </Form>
  );
};

export default withRedirect(ForgetPasswordForm);
