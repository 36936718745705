export const USER_GOOGLE_LOGIN_REQUEST = 'USER_GOOGLE_LOGIN_REQUEST';
export const USER_GOOGLE_LOGIN_SUCCESS = 'USER_GOOGLE_LOGIN_SUCCESS';
export const USER_GOOGLE_LOGIN_FAIL = 'USER_GOOGLE_LOGIN_FAIL';

export const USER_FACEBOOK_LOGIN_REQUEST = 'USER_FACEBOOK_LOGIN_REQUEST';
export const USER_FACEBOOK_LOGIN_SUCCESS = 'USER_FACEBOOK_LOGIN_SUCCESS';
export const USER_FACEBOOK_LOGIN_FAIL = 'USER_FACEBOOK_LOGIN_FAIL';

export const USER_EMAIL_LOGIN_REQUEST = 'USER_EMAIL_LOGIN_REQUEST';
export const USER_EMAIL_LOGIN_SUCCESS = 'USER_EMAIL_LOGIN_SUCCESS';
export const USER_EMAIL_LOGIN_FAIL = 'USER_EMAIL_LOGIN_FAIL';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

export const USER_PERSIST_SESSION_SUCCESS = 'USER_PERSIST_SESSION_SUCCESS';

export const USER_UPDATE_PASSWORD_REQUEST = 'USER_UPDATE_PASSWORD_REQUEST';
export const USER_UPDATE_PASSWORD_SUCCESS = 'USER_UPDATE_PASSWORD_SUCCESS';
export const USER_UPDATE_PASSWORD_FAIL = 'USER_UPDATE_PASSWORD_FAIL';

export const USER_RESET_PASSWORD_REQUEST = 'USER_RESET_PASSWORD_REQUEST';
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';
export const USER_RESET_PASSWORD_FAIL = 'USER_RESET_PASSWORD_FAIL';

export const FETCH_FACILITY_PROFILE_REQUEST = 'FETCH_FACILITY_PROFILE_REQUEST';
export const FETCH_FACILITY_PROFILE_SUCCESS = 'FETCH_FACILITY_PROFILE_SUCCESS';
export const FETCH_FACILITY_PROFILE_FAIL = 'FETCH_FACILITY_PROFILE_FAIL';

export const FETCH_COMPANY_PROFILE_REQUEST = 'FETCH_COMPANY_PROFILE_REQUEST';
export const FETCH_COMPANY_PROFILE_SUCCESS = 'FETCH_COMPANY_PROFILE_SUCCESS';
export const FETCH_COMPANY_PROFILE_FAIL = 'FETCH_COMPANY_PROFILE_FAIL';

export const CHANGE_TO_BLOCKLIST_REQUEST = 'CHANGE_TO_BLOCKLIST_REQUEST';
export const CHANGE_TO_BLOCKLIST_SUCCESS = 'CHANGE_TO_BLOCKLIST_SUCCESS';

export const USER_LOGOUT = 'USER_LOGOUT';
