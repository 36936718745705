import { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import FalconCardHeader from '../common/FalconCardHeader';
import ShiftsTable from './ShiftsTable';
import FacilitiesTable from './FacilitiesTable';
import AddFirstCard from './AddFirstCard';
import { analytics } from '../../firebase';
import AddFacilityModal from '../calendar/AddFacilityModal';
import AddScheduleModal from '../calendar/AddScheduleModal';
import { getShiftsAndEmpty } from '../../redux/actions/shiftActions';
import { getReviewsToFillOut, getReviewsToFillOutByCompany } from '../../api/reviews';
import ReviewsToFillOutCard from './ReviewsToFillOutCard';
import BlockedEmployeesTable from './BlockedEmployeesTable';
import PendingCard from './PendingCard';
import WelcomeCard from '../common/WelcomeCard';
import { AppUserType, ReviewShift } from 'src/types';
import PurchasesTable from './PurchasesTable';
import useAppSelector from 'src/hooks/useAppSelector';
import { isBrookdale } from 'src/helpers/utils';
import PremierEmployeesTable from './PremierEmployeesTable';

const Dashboard = () => {
  const { userType } = useAppSelector((state) => state.user);
  const { facilities, loading: facilitiesLoading } = useAppSelector((state) => state.facility);
  const { shifts, loading: shiftLoading } = useAppSelector((state) => state.shift);
  const { companyProfile } = useAppSelector((state) => state.companyprofile);
  const { facilityProfile } = useAppSelector((state) => state.facilityprofile);
  const [reviewsToFillOut, setReviewsToFillOut] = useState<ReviewShift[]>([]);
  useEffect(() => {
    toast(
      <>
        Welcome to <strong>PT Nurse</strong>!<br />
      </>
    );
    analytics.logEvent('dashboard_view');
  }, []);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getShiftsAndEmpty());
  }, [userType, facilities.length, facilityProfile, companyProfile]);
  const entityIsActive =
    userType === AppUserType.COMPANY ? companyProfile?.status === 'active' : facilityProfile?.status === 'active';
  const entityIsPending =
    userType === AppUserType.COMPANY ? companyProfile?.status === 'pending' : facilityProfile?.status === 'pending';
  const companyHasNoFacilities = userType === AppUserType.COMPANY && facilities.length === 0;
  const companyHasNoShifts = userType === AppUserType.COMPANY && shifts.length === 0;
  const facilityHasNoShifts = userType === AppUserType.FACILITY && shifts.length === 0;
  const shouldShowFirstShiftCard =
    ((userType === AppUserType.FACILITY && facilityProfile && facilityHasNoShifts && !shiftLoading) ||
      (userType === AppUserType.COMPANY &&
        companyProfile &&
        !companyHasNoFacilities &&
        !facilitiesLoading &&
        companyHasNoShifts &&
        !shiftLoading)) &&
    entityIsActive;
  const shouldShowBlockedEmployeesTable =
    userType === AppUserType.FACILITY &&
    facilityProfile?.facilityBlockList &&
    facilityProfile?.facilityBlockList.length > 0;
  const shouldShowFirstFacilityCard =
    userType === AppUserType.COMPANY &&
    companyProfile &&
    companyHasNoFacilities &&
    !entityIsPending &&
    !facilitiesLoading;

  useEffect(() => {
    const getEligibleReviews = async () => {
      if (userType === AppUserType.COMPANY && facilities?.length) {
        const reviews = await getReviewsToFillOutByCompany(facilities);
        setReviewsToFillOut(reviews);
      } else if (userType === AppUserType.FACILITY && facilityProfile?.id) {
        const reviews = await getReviewsToFillOut(facilityProfile?.id);
        setReviewsToFillOut(reviews);
      }
    };
    getEligibleReviews();
  }, [userType, facilities, facilityProfile?.id]);

  return (
    <>
      <WelcomeCard />

      {entityIsPending && (
        <PendingCard>
          <div style={{ textAlign: 'center', color: 'black', fontWeight: 600 }}>
            <p>Thank you for signing up with PT Nurse!</p>
            <p>We will reach out to you shortly to complete the signup process.</p>
          </div>
        </PendingCard>
      )}

      {Boolean(reviewsToFillOut.length) && <ReviewsToFillOutCard reviewsToFillOut={reviewsToFillOut.slice(0, 5)} />}

      {userType === AppUserType.COMPANY && !companyHasNoFacilities && companyProfile && (
        <FacilitiesTable companyId={companyProfile?.id} />
      )}
      {!companyHasNoFacilities && !shouldShowFirstShiftCard && !entityIsPending && (
        <>
          <ShiftsTable showLoadMore={false} />
          {facilityProfile && isBrookdale(facilityProfile.facilityName) && process.env.NODE_ENV === 'development' && (
            <PremierEmployeesTable facilityProfile={facilityProfile} />
          )}
          {shouldShowBlockedEmployeesTable && <BlockedEmployeesTable facilityProfile={facilityProfile} />}
          <Card className="mb-3">
            <FalconCardHeader title="Invoices" light={false} />
            <CardBody className="p-0">
              <PurchasesTable showLoadMore={false} />
            </CardBody>
          </Card>
        </>
      )}

      {shouldShowFirstFacilityCard && (
        <AddFirstCard buttonText="Click here to add your first facility!">
          {(
            isOpenCreateFacilityModal: boolean,
            setIsOpenCreateFacilityModal: (isOpenCreateFacilityModal: boolean) => void
          ) => (
            <AddFacilityModal
              isOpenCreateFacilityModal={isOpenCreateFacilityModal}
              setIsOpenCreateFacilityModal={setIsOpenCreateFacilityModal}
              companyId={companyProfile?.id}
            />
          )}
        </AddFirstCard>
      )}
      {shouldShowFirstShiftCard && (
        <AddFirstCard buttonText="Click here to add your first shift!">
          {(isOpenScheduleModal: boolean, setIsOpenScheduleModal: (isOpenScheduleModal: boolean) => void) => (
            <AddScheduleModal
              isOpenScheduleModal={isOpenScheduleModal}
              setIsOpenScheduleModal={setIsOpenScheduleModal}
              userType={userType}
              facilities={facilities}
              initialFacilityId={facilities.length > 0 ? facilities[0].id : undefined}
            />
          )}
        </AddFirstCard>
      )}
    </>
  );
};

export default Dashboard;
