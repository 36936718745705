import { Collection, Invite } from 'src/types';
import { db } from '../firebase';

const invitesCollection = db.collection(Collection.INVITES);

export const getInviteData = async (id: string): Promise<Invite> => {
  const invite = await getInvite(id);
  return invite.data() as Invite;
};

export const getInvite = (id: string) => {
  return invitesCollection.doc(id).get();
};

export const getAllInvites = () => {
  return invitesCollection.get();
};

export const getInviteRef = (inviteId: string) => {
  if (inviteId) {
    return invitesCollection.doc(inviteId);
  }
  return invitesCollection;
};

export const updateInvite = ({ inviteId, data }: { inviteId: string; data: Partial<Invite> }) => {
  return invitesCollection.doc(inviteId).update(data);
};
