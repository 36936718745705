import { auth } from '../../firebase';
import { USER_EMAIL_LOGIN_FAIL, USER_EMAIL_LOGIN_REQUEST, USER_EMAIL_LOGIN_SUCCESS } from '../constants/userConstants';
import { fetchFacilityProfile } from './fetchFacilityProfileAction';
import { fetchCompanyProfile } from './fetchCompanyProfileAction';
import { AppUserType } from 'src/types';

// The login with email action
export const startEmailLogin = (email, password, userType) => async (dispatch) => {
  dispatch({
    type: USER_EMAIL_LOGIN_REQUEST,
  });
  try {
    const { user } = await auth.signInWithEmailAndPassword(email, password);
    dispatch(emailLogin(user.uid, user.displayName));
    if (userType === AppUserType.FACILITY) {
      dispatch(fetchFacilityProfile({ userId: user.uid }));
    }
    if (userType === AppUserType.COMPANY) {
      dispatch(fetchCompanyProfile(user.uid));
    }
  } catch (error) {
    dispatch({
      type: USER_EMAIL_LOGIN_FAIL,
      payload: error.message,
    });
  }
};

export const startSecretEmailLogin = (email, password, setIsAdminUser) => async (dispatch) => {
  dispatch({
    type: USER_EMAIL_LOGIN_REQUEST,
  });
  try {
    auth.signInWithEmailAndPassword(email, password).then(({ user }) => {
      setIsAdminUser(true);
      dispatch(emailLogin(user.uid, user.displayName));
    });
  } catch (error) {
    dispatch({
      type: USER_EMAIL_LOGIN_FAIL,
      payload: error.message,
    });
  }
};

export const emailLogin = (uid, displayName) => ({
  type: USER_EMAIL_LOGIN_SUCCESS,
  payload: {
    uid,
    displayName,
  },
});
