import { auth } from '../../firebase';
import {
  USER_RESET_PASSWORD_FAIL,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
} from '../constants/userConstants';

// The reset password action
export const resetPassword = (email) => async (dispatch) => {
  dispatch({
    type: USER_RESET_PASSWORD_REQUEST,
  });
  auth
    .sendPasswordResetEmail(email)
    .then(function () {
      dispatch({
        type: USER_RESET_PASSWORD_SUCCESS,
      });
      console.log('The password reset e-mail was sent');
    })
    .catch(function (error) {
      dispatch({
        type: USER_RESET_PASSWORD_FAIL,
      });
      console.log('The password reset e-mail was not sent');
    });
};
