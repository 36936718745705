import { Col, Row } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import UserForm from './UserForm';
import Section from '../../common/Section';
import Logo from '../../navbar/Logo';
import AuthWizardProvider from './AuthWizardProvider';
import { AppUserType } from 'src/types';

const WizardLayout = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const userType = (query.get('userType') || AppUserType.FACILITY) as AppUserType;
  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={10} lg={7} className="col-xxl-5">
          <Logo />
          <AuthWizardProvider>
            <UserForm userType={userType} />
          </AuthWizardProvider>
        </Col>
      </Row>
    </Section>
  );
};

export default WizardLayout;
