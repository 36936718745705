import { Route, Redirect, RouteProps } from 'react-router-dom';
import { UserContext } from '../context/Context';
import { useContext } from 'react';

interface PrivateRouteProps {
  component: React.FunctionComponent<RouteProps>;
  path?: string;
}

export const SecretPrivateRoute = ({ component: Component, path, ...rest }: PrivateRouteProps) => {
  const { isAdminUser } = useContext(UserContext);
  return (
    <Route
      {...rest}
      exact
      path={path}
      component={(props: RouteProps) => (isAdminUser ? <Component {...props} /> : <Redirect to="/" />)}
    />
  );
};
