import { Fragment, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { Button, Col, CustomInput, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { startSecretEmailLogin } from 'src/redux/actions/loginWithEmailAction';
import { UserContext } from 'src/context/Context';

const LoginForm = () => {
  const { setIsAdminUser } = useContext(UserContext);
  const [userEmail, setUserEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);

  const dispatch = useDispatch();

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    await dispatch(startSecretEmailLogin(userEmail, password, setIsAdminUser));
  };

  useEffect(() => {
    setIsDisabled(!userEmail || !password);
  }, [userEmail, password]);

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Label>Email Address</Label>
        <Input value={userEmail} onChange={({ target }) => setUserEmail(target.value)} type="email" />
      </FormGroup>
      <FormGroup>
        <Label>Password</Label>
        <Input value={password} onChange={({ target }) => setPassword(target.value)} type="password" />
      </FormGroup>
      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <CustomInput
            id="customCheckRemember"
            label="Remember me"
            checked={remember}
            onChange={({ target }) => setRemember(target.checked)}
            type="checkbox"
          />
        </Col>
      </Row>
      <FormGroup>
        <Button color="primary" block className="mt-3" disabled={isDisabled}>
          Log in
        </Button>
      </FormGroup>
    </Form>
  );
};

const Login = () => {
  return (
    <Fragment>
      <Row className="text-left justify-content-between">
        <Col xs="auto">
          <h5>Login</h5>
        </Col>
      </Row>
      <LoginForm />
    </Fragment>
  );
};

export default Login;
