import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledDropdown,
  Modal,
} from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch } from 'react-redux';
import { fetchFacilityProfile, removeFromBlockList } from '../../redux/actions/fetchFacilityProfileAction';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import { capitalize } from 'lodash';
import { getWorkerData } from '../../api/worker';
import { BlocklistEvent, FacilityProfile, Worker } from 'src/types';
import { firebase } from 'src/firebase';
import useUserId from 'src/hooks/useUserId';

interface UnblockEmployeeModalProps {
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  employeeName?: string;
  uid?: string;
}

const UnblockEmployeeModal = ({ setIsOpen, isOpen, employeeName, uid }: UnblockEmployeeModalProps) => {
  const toggle = () => setIsOpen(!isOpen);

  const [reason, setReason] = useState('');
  const actionText = 'unblock';

  const closeBtn = (
    <button className="close font-weight-normal" onClick={toggle}>
      &times;
    </button>
  );
  const dispatch = useDispatch();
  const userId = useUserId();
  const blockUserAction = async (reason: string) => {
    await dispatch(removeFromBlockList(uid, reason));
    toast.success(`${employeeName} has been unblocked and is eligible to pick future shifts`);
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader close={closeBtn}>{`${capitalize(actionText)} worker`}</ModalHeader>
      <ModalBody>
        <p>{`Why do you want to unblock ${employeeName}?`}</p>
        <Input onChange={(e) => setReason(e.target.value)} value={reason} />
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={async () => {
            if (!reason) {
              toast.error('Reason is required');
              return;
            }
            await blockUserAction(reason);
            setIsOpen(false);
            dispatch(fetchFacilityProfile({ userId }));
          }}
          disabled={!reason}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

interface BlockedEmployeesTableActionsProps {
  unblockEmployee: () => void;
}

const BlockedEmployeesTableActions = ({ unblockEmployee }: BlockedEmployeesTableActionsProps) => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle color="link" size="sm" className="text-600 btn-reveal">
        <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
      </DropdownToggle>

      <DropdownMenu right className="border py-2">
        <DropdownItem onClick={() => unblockEmployee()}>Unblock</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

type FormattedEvents = { [nurseId: string]: { reason: string; blockedAt: firebase.firestore.Timestamp } };

const getFormattedEvents = (blockedEvents: BlocklistEvent[] | undefined): FormattedEvents => {
  return (blockedEvents || []).reduce((acc: FormattedEvents, evt) => {
    if (evt.action === 'blocked') {
      acc[evt.nurseId] = {
        reason: evt.reason,
        blockedAt: evt.timestamp,
      };
    }
    return acc;
  }, {});
};

interface BlockedEmployeesTableProps {
  facilityProfile: FacilityProfile;
}

type BlockedEmployee = Worker & {
  id: string;
  fullName: string;
  reason: string | null;
  blockedAt: firebase.firestore.Timestamp | null;
};

const BlockedEmployeesTable = ({ facilityProfile }: BlockedEmployeesTableProps) => {
  const blockedEmployees = facilityProfile.facilityBlockList;
  const blockedEvents = facilityProfile.facilityBlockListEvents;
  const blockedDetails = getFormattedEvents(blockedEvents);
  const [blockedEmployeeData, setBlockedEmployeeData] = useState<BlockedEmployee[] | undefined>();
  const [employeeToUnblock, setEmployeeToUnblock] = useState<BlockedEmployee | undefined>();
  const [isOpen, setIsOpen] = useState(false);
  const timeFormatter = (time: firebase.firestore.Timestamp | undefined) => (
    <span>
      {time
        ? facilityProfile.tz
          ? moment(time?.toDate()).tz(facilityProfile.tz).format('MM/DD/YYYY h:mm A')
          : time?.toDate().toLocaleString()
        : null}
    </span>
  );
  useEffect(() => {
    const updateEmployees = async () => {
      const employeePromises = (blockedEmployees || []).map((eeID): Promise<BlockedEmployee> => {
        return new Promise(async (resolve) => {
          const data = await getWorkerData(eeID);
          const fullName = `${data.firstName.trim()} ${data.lastName.trim()}`;
          const eeBlockedDetails = blockedDetails[eeID] || { reason: null, blockedAt: null };
          resolve({ ...data, id: eeID, fullName, ...eeBlockedDetails });
        });
      });
      const employees: BlockedEmployee[] = await Promise.all(employeePromises);
      setBlockedEmployeeData(employees);
    };
    updateEmployees();
  }, [blockedEmployees]);

  const actionFormatter = (uid: string, row: BlockedEmployee) => (
    <BlockedEmployeesTableActions
      unblockEmployee={() => {
        setEmployeeToUnblock(row);
        setIsOpen(true);
      }}
    />
  );

  const columns = [
    {
      dataField: 'fullName',
      text: 'Employee Name',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true,
    },
    {
      dataField: 'nurseType',
      text: 'Position',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true,
    },
    {
      dataField: 'blockedAt',
      text: 'Blocked At',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: timeFormatter,
    },
    {
      dataField: 'reason',
      text: 'Reason',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
    },
    {
      dataField: 'id',
      text: '',
      headerClasses: 'border-0',
      formatter: actionFormatter,
      classes: 'border-0 py-2 align-right',
      align: 'right',
    },
  ];

  return (
    <>
      <Card className="mb-3">
        <FalconCardHeader title="Blocked Employees" light={false} />

        <CardBody className="p-0">
          <>
            <div className="table-responsive">
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={blockedEmployeeData || []}
                columns={columns}
                bordered={false}
                classes="table-dashboard table-striped table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                rowClasses="btn-reveal-trigger border-top border-200"
                headerClasses="bg-200 text-900 border-y border-200"
                sort={{ dataField: 'blockedAt', order: 'desc' }}
              />
            </div>
          </>
        </CardBody>
      </Card>
      <UnblockEmployeeModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        uid={employeeToUnblock?.id}
        employeeName={employeeToUnblock?.fullName}
      />
    </>
  );
};

export default BlockedEmployeesTable;
