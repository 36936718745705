import { useContext, useEffect } from 'react';
import { Route, RouteProps, Switch, useHistory } from 'react-router-dom';

import Dashboard from '../components/dashboard/Dashboard';
import AppContext, { UserContext } from '../context/Context';

import NavbarVertical from '../components/navbar/NavbarVertical';
import NavbarTop from '../components/navbar/NavbarTop';
import ShiftDetails from '../components/page/ShiftDetails';
import Footer from '../components/footer/Footer';
import ShiftDetailsEdit from '../components/page/ShiftDetailsEdit';
import ShiftDetailsReview from '../components/page/ShiftDetailsReview';
import InvoiceDetails from '../components/page/InvoiceDetails';
import ShiftDetailsDelete from '../components/page/ShiftDetailsDelete';
import Invoices from '../components/page/Invoices';
import Shifts from '../components/page/Shifts';
import Reviews from '../components/page/Reviews';
import Calendar from 'src/components/calendar/Calendar';
import { SecretPrivateRoute } from './SecretPrivateRoute';
import SecretLoginForm from 'src/components/auth/basic/SecretLoginForm';
import Timesheet from 'src/components/page/Timesheet';

const DashboardLayout = ({ location }: RouteProps) => {
  const { isFluid, isVertical, navbarStyle } = useContext(AppContext);
  const history = useHistory();
  const { secretUserId, isAdminUser } = useContext(UserContext);

  useEffect(() => {
    // This means that an admin user logged in, but has not entered impersonation userId yet
    if (isAdminUser && location?.pathname === '/' && !secretUserId) {
      history.push('/otherLoginForm');
    }
  }, [isAdminUser, location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);
  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      {isVertical && <NavbarVertical navbarStyle={navbarStyle} />}

      <div className="content">
        <NavbarTop />
        <Switch>
          <SecretPrivateRoute path="/otherLoginForm" component={SecretLoginForm} />
          <Route path="/" exact component={Dashboard} />
          <Route path="/invoices" exact component={Invoices} />
          <Route path="/shifts" exact component={Shifts} />
          <Route path="/reviews" exact component={Reviews} />
          <Route path="/shift-details/:id" exact component={ShiftDetails} />
          <Route path="/timesheet" exact component={Timesheet} />
          <Route path="/shift-details/:id/review" exact component={ShiftDetailsReview} />
          <Route path="/shift-details/:id/edit" exact component={ShiftDetailsEdit} />
          <Route path="/shift-details/:id/delete" exact component={ShiftDetailsDelete} />
          <Route path="/invoice/:id" exact component={InvoiceDetails} />
          <Route path="/calendar" exact component={Calendar} />
        </Switch>
        <Footer />
      </div>
    </div>
  );
};

export default DashboardLayout;
