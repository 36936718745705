import { USER_LOGOUT, USER_PERSIST_SESSION_SUCCESS } from '../constants/userConstants';

export interface UserPersistLoginReducerState {
  loading?: boolean;
  loggedin?: boolean;
  uid?: string;
  email?: string;
  name?: string;
  provider?: string;
}
export interface UserPersistLoginReducerAction {
  type: typeof USER_LOGOUT | typeof USER_PERSIST_SESSION_SUCCESS;
  payload: { uid: string; email: string; displayName: string; provider: string };
}

export const userPersistLoginReducer = (
  state: UserPersistLoginReducerState = {},
  action: UserPersistLoginReducerAction
): UserPersistLoginReducerState => {
  switch (action.type) {
    case USER_PERSIST_SESSION_SUCCESS:
      return {
        loading: false,
        loggedin: true,
        uid: action.payload.uid,
        email: action.payload.email,
        name: action.payload.displayName,
        provider: action.payload.provider,
      };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};
