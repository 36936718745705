import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import { userManualLoginReducer } from '../reducers/userManualLoginReducer';
import { userGoogleLoginReducer } from '../reducers/userGoogleLoginReducer';
import { userFacebookLoginReducer } from '../reducers/userFacebookLoginReducer';
import { userPersistLoginReducer } from '../reducers/userPersistLoginReducer';
import { eventListReducer } from '../reducers/eventListReducer';
import { dashboardShiftListReducer } from '../reducers/dashboardEventListReducer';
import { shiftAddDiscontentReducer } from '../reducers/shiftAddDiscontentReducer';
import { userFetchFacilityProfileActionReducer } from '../reducers/userFetchFacilityProfileActionReducer';
import { userFetchCompanyProfileActionReducer } from '../reducers/userFetchCompanyProfileActionReducer';
import { userReducer } from '../reducers/userReducer';
import { invoiceReducer } from '../reducers/invoiceReducer';
import { shiftReducer } from '../reducers/shiftReducer';
import { facilityReducer } from '../reducers/facilityReducer';
import { timesheetReducer } from '../reducers/timesheetReducer';
import { shiftEditReducer } from '../reducers/shiftEditReducer';

const store = configureStore({
  reducer: {
    // @ts-ignore
    email: userManualLoginReducer,
    // @ts-ignore
    googleauth: userGoogleLoginReducer,
    // @ts-ignore
    facebookauth: userFacebookLoginReducer,
    // @ts-ignore
    persistedsession: userPersistLoginReducer,
    // @ts-ignore
    events: eventListReducer,
    // @ts-ignore
    dashboardshiftlist: dashboardShiftListReducer,
    // @ts-ignore
    shiftedit: shiftEditReducer,
    // @ts-ignore
    shiftdiscontent: shiftAddDiscontentReducer,
    // @ts-ignore
    facilityprofile: userFetchFacilityProfileActionReducer,
    // @ts-ignore
    companyprofile: userFetchCompanyProfileActionReducer,
    // @ts-ignore
    invoice: invoiceReducer,
    // @ts-ignore
    shift: shiftReducer,
    // @ts-ignore
    timesheet: timesheetReducer,
    // @ts-ignore
    user: userReducer,
    // @ts-ignore
    facility: facilityReducer,
  },
  middleware: [thunk],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
