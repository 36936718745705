import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { Collection, Facility, WithId } from 'src/types';
import { db } from '../../firebase';
import { FACILITY_LIST_FAIL, FACILITY_LIST_REQUEST, FACILITY_LIST_SUCCESS } from '../constants/facilityConstants';
import { RootState } from '../store/store';

interface GetFacilitiesInput {
  companyId: string;
}

export const getFacilities =
  ({ companyId }: GetFacilitiesInput): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch({
      type: FACILITY_LIST_REQUEST,
    });
    try {
      const facilitiesSnap = await db.collection(Collection.FACILITIES).where('companyId', '==', companyId).get();
      const facilitiesArr: WithId<Facility>[] = [];
      facilitiesSnap.forEach((snapChild) => {
        const data = {
          ...(snapChild.data() as Facility),
          id: snapChild.id,
        };
        facilitiesArr.push(data);
      });

      dispatch({
        type: FACILITY_LIST_SUCCESS,
        payload: {
          facilities: facilitiesArr,
        },
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: FACILITY_LIST_FAIL,
      });
    }
  };
