import { Invoice } from 'src/types';
import {
  INVOICE_LIST_FAIL,
  INVOICE_LIST_REQUEST,
  INVOICE_LIST_SUCCESS,
  INVOICE_HAS_MORE,
} from '../constants/invoiceConstants';

export interface InvoiceReducerState {
  invoices: Invoice[];
  loading: boolean;
  error?: boolean;
  hasMore?: boolean;
}

export interface InvoiceReducerAction {
  payload: { invoices?: Invoice[]; hasMore?: boolean };
  type: typeof INVOICE_LIST_FAIL | typeof INVOICE_LIST_REQUEST | typeof INVOICE_LIST_SUCCESS | typeof INVOICE_HAS_MORE;
}

const initialState = {
  invoices: [],
  loading: false,
  error: false,
  hasMore: true,
};

export const invoiceReducer = (
  state: InvoiceReducerState = initialState,
  { type, payload }: InvoiceReducerAction
): InvoiceReducerState => {
  switch (type) {
    case INVOICE_LIST_REQUEST:
      return { ...state, loading: true, error: false };
    case INVOICE_LIST_SUCCESS:
      return { invoices: [...state.invoices, ...(payload.invoices || [])], loading: false };
    case INVOICE_LIST_FAIL:
      return { ...state, loading: false, error: true };
    case INVOICE_HAS_MORE:
      return { ...state, hasMore: payload.hasMore };
    default:
      return state;
  }
};
