import { useEffect, useState } from 'react';

import CardSummary from '../dashboard/CardSummary';
import { getUserFacilityData } from '../../api/facilities';
import { auth } from '../../firebase';
import { AppUserType, FacilityAdminOwner } from 'src/types';
import useAppSelector from 'src/hooks/useAppSelector';

const WelcomeCard = () => {
  const [user, setUser] = useState<FacilityAdminOwner | undefined>();
  const [userLoading, setUserLoading] = useState(false);
  const { userType } = useAppSelector((state) => state.user);
  const { companyProfile } = useAppSelector((state) => state.companyprofile);
  const { facilityProfile } = useAppSelector((state) => state.facilityprofile);
  let entityName = facilityProfile?.facilityName;
  if (userType === AppUserType.COMPANY && companyProfile) {
    entityName = companyProfile.companyName;
  }
  const userId = auth?.currentUser?.uid;
  useEffect(() => {
    setUserLoading(true);
    if (userId) {
      getUserFacilityData(userId).then((userData) => {
        setUser(userData);
        setUserLoading(false);
      });
    }
  }, [userId]);
  const title = user?.firstName ? `Welcome ${user?.firstName}!` : userLoading ? <span>&nbsp;</span> : 'Welcome!';
  return (
    <div className="card-deck">
      <CardSummary
        showLinkTest={false}
        title={title}
        color="warning"
        bottomText="Please call customer support at 815-601-7011 for urgent issues"
      >
        {!entityName ? <span>&nbsp;</span> : entityName}
      </CardSummary>
    </div>
  );
};

export default WelcomeCard;
