import {
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Radio,
  RadioGroup,
  Select,
  Stack,
} from '@chakra-ui/react';
import { useState, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { getAllCompanies, getCompanyData } from 'src/api/companies';
import { getAllFacilities, getAllUserDataByFacilityId, getUserFacilityData } from 'src/api/facilities';
import { AppUserType } from 'src/types';
import { UserContext } from 'src/context/Context';
import { useHistory } from 'react-router-dom';

interface FormValues {
  appUserType?: AppUserType;
  entityId?: string;
  userId?: string;
}

interface Option {
  label: string;
  value: string;
}

const SecretLoginForm = () => {
  const [loading, setLoading] = useState(false);
  const [entityOptions, setEntityOptions] = useState<Option[]>([]);
  const [userOptions, setUserOptions] = useState<Option[]>([]);
  const { setSecretUserId } = useContext(UserContext);
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<FormValues>();
  const history = useHistory();

  watch(async ({ appUserType }: FormValues, { name, type }) => {
    if (name === 'appUserType' && type === 'change') {
      if (appUserType === AppUserType.FACILITY) {
        const facilitySnaps = await getAllFacilities();
        const facilityOptions: Option[] = [];
        facilitySnaps.forEach((fac) => facilityOptions.push({ label: fac.data().facilityName, value: fac.id }));
        setEntityOptions(facilityOptions);
      }
      if (appUserType === AppUserType.COMPANY) {
        const companySnaps = await getAllCompanies();
        const companyOptions: Option[] = [];
        companySnaps.forEach((c) => companyOptions.push({ label: c.data().companyName, value: c.id }));
        setEntityOptions(companyOptions);
      }
    }
  });

  watch(async ({ entityId, appUserType }: FormValues, { name, type }) => {
    if (name === 'entityId' && type === 'change') {
      if (!entityId) {
        setUserOptions([]);
      } else if (appUserType === AppUserType.FACILITY) {
        const users = await getAllUserDataByFacilityId(entityId);
        const userOptions = users.map((user) => ({
          label: `${user.firstName.trim()} ${user.lastName.trim()}`,
          value: user.id,
        }));
        setUserOptions(userOptions);
      } else if (appUserType === AppUserType.COMPANY) {
        const { ownerId } = await getCompanyData(entityId);
        const { firstName, lastName } = await getUserFacilityData(ownerId);
        const userOptions = [
          {
            label: `${firstName.trim()} ${lastName.trim()}`,
            value: ownerId,
          },
        ];
        setUserOptions(userOptions);
      }
    }
  });

  const onSubmit = async (data: FormValues) => {
    setLoading(true);
    if (data.userId) {
      setSecretUserId(data.userId);
      history.replace('/');
    }
    setLoading(false);
  };
  return (
    <Container style={{ backgroundColor: 'white', padding: '15px' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4} align="stretch" mb={4}>
          <Container>
            <FormControl isInvalid={Boolean(errors.appUserType)}>
              <FormLabel htmlFor="appUserType">Choose entity type</FormLabel>
              <Controller
                rules={{ required: 'Entity type is required' }}
                render={({ field }) => (
                  <RadioGroup spacing={4} {...field}>
                    <Stack>
                      <Radio value={AppUserType.FACILITY}>Facility</Radio>
                      <Radio value={AppUserType.COMPANY}>Company</Radio>
                    </Stack>
                  </RadioGroup>
                )}
                name="appUserType"
                control={control}
              />
              <FormErrorMessage>{errors.appUserType && errors.appUserType.message}</FormErrorMessage>
            </FormControl>
          </Container>
          <Container>
            <FormControl isInvalid={Boolean(errors.entityId)}>
              <FormLabel htmlFor="entityId">Entity</FormLabel>
              <Controller
                render={({ field }) => (
                  <Select {...field}>
                    <option key="none" value="none">
                      None
                    </option>
                    {entityOptions.map((opt) => (
                      <option key={opt.value} value={opt.value}>
                        {opt.label}
                      </option>
                    ))}
                  </Select>
                )}
                name="entityId"
                control={control}
              />
              <FormErrorMessage>{errors.entityId && errors.entityId.message}</FormErrorMessage>
            </FormControl>
          </Container>
          <Container>
            <FormControl isInvalid={Boolean(errors.userId)}>
              <FormLabel htmlFor="userId">Choose user to impersonate</FormLabel>
              <Controller
                render={({ field }) => (
                  <Select {...field}>
                    <option key="none" value="none">
                      None
                    </option>
                    {userOptions.map((opt) => (
                      <option key={opt.value} value={opt.value}>
                        {opt.label}
                      </option>
                    ))}
                  </Select>
                )}
                name="userId"
                control={control}
              />
              <FormErrorMessage>{errors.userId && errors.userId.message}</FormErrorMessage>
            </FormControl>
          </Container>
        </Stack>

        <>
          <Button isLoading={loading} colorScheme="blue" mr={3} type="submit">
            Continue
          </Button>
        </>
      </form>
    </Container>
  );
};

export default SecretLoginForm;
