import { Collection } from 'src/types';
import { db } from '../../firebase';
import { EVENT_LIST_FAIL, EVENT_LIST_REQUEST, EVENT_LIST_SUCCESS } from '../constants/eventConstants';

export const loadEvents = (uid) => async (dispatch) => {
  dispatch({
    type: EVENT_LIST_REQUEST,
  });

  try {
    const eventsSnap = await db.collection(Collection.SHIFTS).where(`facilityIdentifier`, `==`, `${uid}`).get();
    const events = [];

    eventsSnap.forEach((snapChild) => {
      events.push({
        id: snapChild.id,
        ...snapChild.data(),
      });
    });
    dispatch({
      type: EVENT_LIST_SUCCESS,
      payload: events,
    });

    return events;
  } catch (error) {
    dispatch({
      type: EVENT_LIST_FAIL,
      error: error.message,
    });
  }
};
