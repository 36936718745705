import { FunctionComponent } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import rocket from '../../assets/img/illustrations/rocket.png';

interface LogoutContentProps {
  layout?: string;
  titleTag?: FunctionComponent;
}

const DefaultTitleTag: FunctionComponent = (props: any) => <h4 {...props} />;

const LogoutContent = ({ layout = 'basic', titleTag: TitleTag = DefaultTitleTag }: LogoutContentProps) => {
  return (
    <>
      <img className="d-block mx-auto mb-4" src={rocket} alt="shield" width={70} />
      <TitleTag>See you again!</TitleTag>
      <p>
        You are <br className="d-none d-sm-block" />
        now successfully signed out.
      </p>
      <Button tag={Link} color="primary" size="sm" className="mt-3" to={`/authentication/${layout}/login`}>
        <FontAwesomeIcon icon="chevron-left" transform="shrink-4 down-1" className="mr-1" />
        Return to Login
      </Button>
    </>
  );
};

export default LogoutContent;
