import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Col,
  CustomInput,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import Loader from '../common/Loader';
import FalconCardHeader from '../common/FalconCardHeader';
import { useDispatch, useSelector } from 'react-redux';
import { fetchShiftsOnTheDashboard } from '../../redux/actions/fetchShiftsOnTheDashboardAction';
import { useState } from 'react';
import { analytics, firebase, auth } from '../../firebase';
import { getFacilities } from '../../redux/actions/facilityActions';
import { checkShiftRequiresMedpass } from '../../helpers/shifts';
import { RevieweeType, ReviewerType, shiftReviewQuestions, shiftReviewQuestionsOrder } from '../../constants';
import {
  createReview,
  getReviewByShiftId,
  getReviewsToFillOut,
  getReviewsToFillOutByCompany,
  updateReview,
} from '../../api/reviews';
import { toast } from 'react-toastify';
import { getWorkerData } from '../../api/worker';
import { AppUserType } from 'src/types';

const ShiftReviewModal = ({ isOpen, setIsOpen, rating, eligibleReviews }) => {
  const ratingTextMap = {
    1: 'We are sorry that you did not have a great experience. We will be reaching out shortly to see how we can improve.',
    2: 'We are sorry that you did not have a great experience. We will be reaching out shortly to see how we can improve.',
    3: 'Thank you for reviewing this shift. We actively monitor reviews, so that we can constantly improve the quality of our platform.',
    4: 'Thank you for reviewing this shift. We actively monitor reviews, so that we can constantly improve the quality of our platform.',
    5: 'We are glad you had a great experience with this shift!',
  };
  const toggle = () => setIsOpen(!isOpen);
  const closeBtn = (
    <button className="close font-weight-normal" onClick={toggle}>
      &times;
    </button>
  );
  const plural = eligibleReviews.length === 1 ? '' : 's';
  return (
    <Modal isOpen={isOpen} toggle={toggle} modalClassName="theme-modal" contentClassName="border">
      <div style={{ height: '300px', display: 'flex', flexDirection: 'column' }}>
        <ModalHeader toggle={toggle} className="bg-light d-flex flex-between-center" close={closeBtn}>
          Thank you!
        </ModalHeader>
        <ModalBody>
          <p>{ratingTextMap[rating]}</p>
          {eligibleReviews.length > 0 && (
            <p style={{ fontWeight: 600 }}>{`You have ${eligibleReviews.length} more shift${plural} to review`}</p>
          )}
        </ModalBody>
        {eligibleReviews.length > 0 && (
          <ModalFooter className="border-top-0" style={{ width: '100%' }}>
            <Link style={{ width: '100%' }} to={`/shift-details/${eligibleReviews[0].id}/review`}>
              <Button style={{ width: '100%' }} color="primary" className="px-4">
                Review next shift
              </Button>
            </Link>
          </ModalFooter>
        )}
      </div>
    </Modal>
  );
};

const ShiftReviewQuestion = ({ questionInfo, questionId, handleChange, value, invalid }) => {
  if (questionInfo.type === 'boolean') {
    const onChange = (e) => {
      const idMap = {
        [`${questionId}-no`]: false,
        [`${questionId}-yes`]: true,
      };
      handleChange({ name: questionId, value: idMap[e.target.id] });
    };
    return (
      <FormGroup tag="fieldset">
        <Label for={questionId}>
          {questionInfo.text}
          {questionInfo.required && <span style={{ color: '#ff0000' }}>{` *`}</span>}
        </Label>
        <div>
          <CustomInput
            checked={value === true}
            onChange={onChange}
            type="radio"
            inline
            label="Yes"
            id={`${questionId}-yes`}
            name={questionId}
            invalid={invalid}
          />
          <CustomInput
            checked={value === false}
            onChange={onChange}
            type="radio"
            inline
            label="No"
            id={`${questionId}-no`}
            name={questionId}
            invalid={invalid}
          />
          <Input invalid={invalid} style={{ display: 'none' }} />
          <FormFeedback>This question is required</FormFeedback>
        </div>
      </FormGroup>
    );
  }
  if (questionInfo.type === 'numericScale') {
    const [start, end] = questionInfo.bounds;
    const arr = [...Array(end).keys()].map((x) => x + start);
    const onChange = (e) => {
      handleChange({ name: questionId, value: parseInt(e.target.id.split('-')[1]) });
    };
    return (
      <FormGroup tag="fieldset">
        <Label for={questionId}>
          {questionInfo.text}
          {questionInfo.required && <span style={{ color: '#ff0000' }}>{` *`}</span>}
        </Label>
        <div>
          {arr.map((num) => {
            return (
              <CustomInput
                checked={num === value}
                onChange={onChange}
                type="radio"
                inline
                label={num}
                key={num}
                id={`${questionId}-${num}`}
                name={questionId}
                invalid={invalid}
              />
            );
          })}
          <Input invalid={invalid} style={{ display: 'none' }} />
          <FormFeedback>This question is required</FormFeedback>
        </div>
      </FormGroup>
    );
  }
  if (questionInfo.type === 'textarea') {
    return (
      <FormGroup>
        <Label for={questionId}>
          {questionInfo.text}
          {questionInfo.required && <span style={{ color: '#ff0000' }}>{` *`}</span>}
        </Label>
        <Input
          value={value}
          type="textarea"
          name={questionId}
          id={questionId}
          onChange={(e) => handleChange({ name: questionId, value: e.target.value })}
          invalid={invalid}
        />
        <FormFeedback>This question is required</FormFeedback>
      </FormGroup>
    );
  }
};

const ShiftDetailRow = ({ title, isLastItem, children }) => (
  <Row>
    <Col xs={5} sm={4}>
      <p
        className={classNames('font-weight-semi-bold', {
          'mb-0': isLastItem,
          'mb-1': !isLastItem,
        })}
      >
        {title}
      </p>
    </Col>
    <Col>{children}</Col>
  </Row>
);

ShiftDetailRow.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isLastItem: PropTypes.bool,
};

ShiftDetailRow.defaultProps = { last: false };

const ShiftDetail = ({ shift, review }) => {
  const { userType } = useSelector((state) => state.user);
  const [nurse, setNurse] = useState();
  const [saveLoading, setSaveLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [eligibleReviews, setEligibleReviews] = useState([]);
  const defaultFormObj =
    review && review.responses
      ? review.responses.reduce((acc, value) => ({ ...acc, [value.questionId]: value.responseValue }), {})
      : {
          rating: null,
          onTime: null,
        };
  const [formObj, setFormObj] = useState(defaultFormObj);
  const [formValid, setFormValid] = useState({
    rating: true,
    onTime: true,
    additionalComments: true,
  });
  useEffect(() => {
    if (shift.id) {
      const params = new URLSearchParams(window.location.search);
      let source = params.get('source');
      analytics.logEvent('shift_details_review_view', { shiftId: shift.id, source });
    }
  }, [shift.id]);
  useEffect(() => {
    const loadNurse = async (nId) => {
      const data = await getWorkerData(nId);
      setNurse(data);
    };

    if (shift) {
      loadNurse(shift.nurseId);
    }
  }, [shift]);

  useEffect(() => {
    const submitted = async () => {
      if (review?.submittedAt) {
        let eligibleReviews = [];
        if (userType === AppUserType.FACILITY) {
          eligibleReviews = await getReviewsToFillOut(shift.facilityIdentifier);
        } else if (userType === AppUserType.COMPANY) {
          eligibleReviews = await getReviewsToFillOutByCompany(shift.facilityIdentifier);
        }
        setEligibleReviews(eligibleReviews);
        setModalIsOpen(true);
      }
    };
    submitted();
  }, [Boolean(review?.submittedAt)]);

  const requiresMedPass = checkShiftRequiresMedpass(shift);

  const handleChange = (item) => {
    if (!formValid[item.name] && item.value !== null && item.value !== '') {
      setFormValid({ ...formValid, [item.name]: true });
    }
    setFormObj({ ...formObj, [item.name]: item.value });
  };

  if (!shift) {
    return <Loader />;
  }

  const rating = ((review?.responses || []).find((res) => res.questionId === 'rating') || {}).responseValue;

  const onSave = async () => {
    const invalidQuestions = Object.keys(formObj)
      .filter((key) => shiftReviewQuestions[key].required)
      .filter((key) => formObj[key] === null);
    const isError = invalidQuestions.length > 0;
    if (isError) {
      const inValidQuestionsObj = invalidQuestions.reduce((acc, val) => ({ ...acc, [val]: false }), {});
      setFormValid({ ...formValid, ...inValidQuestionsObj });
      return;
    }
    setSaveLoading(true);
    const shiftId = shift.id;
    const reviewerType = ReviewerType.FACILITY;
    const revieweeType = RevieweeType.WORKER;
    const nurseId = shift.nurseId;
    const facilityIdentifier = shift.facilityIdentifier;
    const newReview = {
      reviewerType,
      revieweeType,
      nurseId,
      facilityIdentifier,
      responses: Object.keys(formObj).map((key) => ({ questionId: key, responseValue: formObj[key] })),
      submittedAt: firebase.firestore.Timestamp.fromDate(new Date()),
      status: 'submitted',
      rating: formObj.rating,
      reviewerId: auth.currentUser?.uid,
    };
    try {
      if (review) {
        await updateReview(newReview, shiftId);
      } else {
        await createReview(newReview, shiftId);
      }
      toast.success('Review saved successfully');
    } catch (e) {
      console.error(e);
      toast.error('Review failed to save');
    } finally {
      setSaveLoading(false);
    }
  };
  return (
    <div style={{ position: 'relative' }}>
      <Card className="mb-3">
        <FalconCardHeader title="Shift Information" />
        <CardBody className="bg-light border-top">
          <>
            <Row>
              <Col lg className="col-xxl-5">
                <ShiftDetailRow title="Description">
                  {shift.description ? shift.description : <p className="font-italic text-400 mb-1">No information</p>}
                </ShiftDetailRow>
                <ShiftDetailRow title="Nurse type">
                  {shift.nurseType ? shift.nurseType : <p className="font-italic text-400 mb-1">No information</p>}
                </ShiftDetailRow>
                <ShiftDetailRow title="Required Med Pass">{requiresMedPass ? 'Yes' : 'No'}</ShiftDetailRow>
                <ShiftDetailRow title="Additional notes">
                  {shift.additionalnotes ? (
                    shift.additionalnotes
                  ) : (
                    <p className="font-italic text-400 mb-0">No information</p>
                  )}
                </ShiftDetailRow>
                {userType === AppUserType.COMPANY && (
                  <ShiftDetailRow title="Facility Name" isLastItem>
                    {shift.facilityName ? (
                      shift.facilityName
                    ) : (
                      <p className="font-italic text-400 mb-0">No information</p>
                    )}
                  </ShiftDetailRow>
                )}
              </Col>
              <Col lg className="col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
                <ShiftDetailRow title="Nurse">
                  {nurse ? (
                    <span>{nurse?.firstName + ' ' + nurse?.lastName}</span>
                  ) : (
                    <p className="font-italic text-400 mb-1">No information</p>
                  )}
                </ShiftDetailRow>
                <ShiftDetailRow title="Start">
                  {shift.startTime ? shift.startTime : <p className="font-italic text-400 mb-1">No information</p>}
                </ShiftDetailRow>
                <ShiftDetailRow title="End" isLastItem>
                  {' '}
                  {shift.endTime ? shift.endTime : <p className="font-italic text-400 mb-1">No information</p>}{' '}
                </ShiftDetailRow>
              </Col>
            </Row>
          </>
        </CardBody>
      </Card>
      <Card>
        <FalconCardHeader title="Shift Review" />
        <CardBody className="bg-light border-top">
          {shift.isFree || Date.now() < shift.end.toDate() ? (
            <p>This shift is not available for review</p>
          ) : (
            <Form>
              {shiftReviewQuestionsOrder.map((id) => {
                return (
                  <ShiftReviewQuestion
                    key={id}
                    questionInfo={shiftReviewQuestions[id]}
                    questionId={id}
                    handleChange={handleChange}
                    value={formObj[id]}
                    invalid={!formValid[id]}
                  />
                );
              })}
              <Button disabled={saveLoading} onClick={onSave}>
                Save
              </Button>
            </Form>
          )}
        </CardBody>
      </Card>
      <ShiftReviewModal
        eligibleReviews={eligibleReviews}
        rating={rating}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
      />
    </div>
  );
};

const ShiftDetailsReview = () => {
  // Fetching the dashboard shift list from Redux state
  const dashboardShiftListFromRedux = useSelector((state) => state.dashboardshiftlist.dashboardshifts);
  // Checking if the data is loading
  const { loading } = useSelector((state) => state.dashboardshiftlist);
  const { facilityProfile } = useSelector((state) => state.facilityprofile);
  const { facilities } = useSelector((state) => state.facility);
  const facilityIds = facilities.map((facility) => facility.id).join(',');
  const dispatch = useDispatch();
  const [review, setReview] = useState(null);
  const [reviewIsLoading, setReviewIsLoading] = useState(false);

  const { id } = useParams();
  const { companyProfile } = useSelector((state) => state.companyprofile);
  const companyId = companyProfile?.id;

  useEffect(() => {
    if (companyId) {
      dispatch(getFacilities({ companyId }));
    }
  }, [dispatch, companyId]);

  useEffect(() => {
    dispatch(fetchShiftsOnTheDashboard(facilityProfile?.id));
  }, [dispatch, facilityProfile?.id, facilityIds]);

  useEffect(() => {
    const getReview = async () => {
      const review = await getReviewByShiftId(id);
      setReview(review);
      setReviewIsLoading(false);
    };
    setReviewIsLoading(true);
    getReview();
  }, [id]);

  const shift = (dashboardShiftListFromRedux || []).find(function (item) {
    return item.id === id;
  });

  if (loading || reviewIsLoading) {
    return <Loader />;
  }

  if (!shift) {
    return <div>Shift not found</div>;
  }
  return <ShiftDetail shift={shift} review={review} />;
};

export default ShiftDetailsReview;
