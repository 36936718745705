import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Row, Col, FormGroup, Input, CustomInput, Label } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { startEmailLogin } from '../../redux/actions/loginWithEmailAction';
import { capitalize } from '../../helpers/utils';
import { AppUserType } from 'src/types';
import useAppSelector from 'src/hooks/useAppSelector';
import * as Sentry from '@sentry/react';

interface LoginFormProps {
  hasLabel?: boolean;
  layout?: string;
  userType?: AppUserType;
}

const errorMap: { [err: string]: string } = {
  'The password is invalid or the user does not have a password.':
    'Password is incorrect. Try again or reset password.',
  'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.':
    'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.',
  'There is no user record corresponding to this identifier. The user may have been deleted.': 'Email is invalid',
};

const LoginForm = ({ hasLabel, layout, userType }: LoginFormProps) => {
  const [userEmail, setUserEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);

  const { error } = useAppSelector((state) => state.email);

  const dispatch = useDispatch();

  const handleSubmit: React.FormEventHandler = (e) => {
    e.preventDefault();
    dispatch(startEmailLogin(userEmail, password, userType));
  };

  useEffect(() => {
    setIsDisabled(!userEmail || !password);
  }, [userEmail, password]);

  let errorText = '';
  if (error) {
    if (error in errorMap) {
      errorText = errorMap[error];
    } else {
      errorText = 'Unexpected error';
      Sentry.captureException(new Error('Unexpected auth error'), { extra: { error } });
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        {hasLabel && <Label>{`${capitalize(userType)} Email Address`}</Label>}
        <Input
          placeholder={!hasLabel ? 'Email address' : ''}
          value={userEmail}
          onChange={({ target }) => setUserEmail(target.value)}
          type="email"
        />
      </FormGroup>
      <FormGroup>
        {hasLabel && <Label>Password</Label>}
        <Input
          placeholder={!hasLabel ? 'Password' : ''}
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          type="password"
        />
      </FormGroup>
      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <CustomInput
            id="customCheckRemember"
            label="Remember me"
            checked={remember}
            onChange={({ target }) => setRemember(target.checked)}
            type="checkbox"
          />
        </Col>
        <Col xs="auto">
          <Link className="fs--1" to={`/authentication/${layout}/forget-password`}>
            Forgot Password?
          </Link>
        </Col>
      </Row>
      <FormGroup>
        <Button color="primary" block className="mt-3" disabled={isDisabled}>
          Log in
        </Button>
      </FormGroup>
      {error && <p style={{ color: 'red' }}>{errorText}</p>}
    </Form>
  );
};

export default LoginForm;
