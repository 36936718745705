import { Payout, WithId } from 'src/types';
import {
  TIMESHEET_ALL_CHANGE_SUCCESS,
  TIMESHEET_CHANGE_REQUEST,
  TIMESHEET_CHANGE_SUCCESS,
  TIMESHEET_LIST_FAIL,
  TIMESHEET_LIST_REQUEST,
  TIMESHEET_LIST_SUCCESS,
} from '../constants/timesheetConstants';

export interface TimesheetReducerState {
  loading: boolean;
  error?: boolean;
  loadingChange?: boolean;
  timesheets: WithId<Payout>[];
}

export interface TimesheetChangeSuccessPayload {
  id: string;
  status: string;
  reason: string;
  signedOffBy: string;
}

export interface TimesheetAllChangeSuccessPayload {
  signedOffBy: string;
}

export interface TimesheetListSuccessPayload {
  empty?: boolean;
  timesheets: WithId<Payout>[];
}

export interface TimesheetReducerAction {
  type:
    | typeof TIMESHEET_ALL_CHANGE_SUCCESS
    | typeof TIMESHEET_CHANGE_REQUEST
    | typeof TIMESHEET_CHANGE_SUCCESS
    | typeof TIMESHEET_LIST_FAIL
    | typeof TIMESHEET_LIST_REQUEST
    | typeof TIMESHEET_LIST_SUCCESS;
  payload: TimesheetListSuccessPayload | TimesheetChangeSuccessPayload | TimesheetAllChangeSuccessPayload;
}
const initialState = {
  timesheets: [],
  loading: false,
  error: false,
  loadingChange: false,
};

export const timesheetReducer = (
  state: TimesheetReducerState = initialState,
  { type, payload }: TimesheetReducerAction
): TimesheetReducerState => {
  switch (type) {
    case TIMESHEET_LIST_REQUEST:
      return { ...state, loading: true, error: false };
    case TIMESHEET_CHANGE_REQUEST:
      return { ...state, loadingChange: true };
    case TIMESHEET_CHANGE_SUCCESS:
      const timesheetChangeSuccessPayload = payload as TimesheetChangeSuccessPayload;
      const index = state.timesheets.findIndex((a) => a.id === timesheetChangeSuccessPayload.id);
      const newState = { ...state };
      newState.timesheets[index] = { ...state.timesheets[index], ...timesheetChangeSuccessPayload };
      return { ...newState, loadingChange: false };
    case TIMESHEET_ALL_CHANGE_SUCCESS:
      const timesheetAllChangeSuccessPayload = payload as TimesheetChangeSuccessPayload;
      const ts: WithId<Payout>[] = [];
      state.timesheets.forEach((t) => ts.push({ ...t, ...timesheetAllChangeSuccessPayload }));
      return { ...state, timesheets: ts, loadingChange: false };
    case TIMESHEET_LIST_SUCCESS:
      const timesheetListSuccessPayload = payload as TimesheetListSuccessPayload;
      let timesheets = [];
      if (timesheetListSuccessPayload.empty) {
        timesheets = [...timesheetListSuccessPayload.timesheets];
      } else {
        timesheets = [...state.timesheets, ...timesheetListSuccessPayload.timesheets];
      }
      return { timesheets, loading: false };
    case TIMESHEET_LIST_FAIL:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
};
