// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import { startLogout } from '../../redux/actions/logoutAction';
import Avatar from '../common/Avatar';

const ProfileDropdown = () => {
  const avatarUrl = localStorage.getItem('facilityAvatar');

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const dispatch = useDispatch();

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(startLogout());
  };

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(false);
      }}
    >
      <DropdownToggle nav className="pr-0">
        {avatarUrl && avatarUrl != 'undefined' ? (
          <Avatar src={avatarUrl} />
        ) : (
          <Avatar name={localStorage.getItem('facilityName')?.[0] || 'U'} />
        )}
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <div className="bg-white rounded-soft py-2">
          <DropdownItem tag={Link} to="/pages/billing">
            Billing
          </DropdownItem>
          <DropdownItem tag={Link} to="/pages/settings">
            Account
          </DropdownItem>
          <DropdownItem onClick={handleLogout} tag={Link} to="/authentication/basic/logout">
            Logout
          </DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;
