import { getFacilityByUserId, updateFacilityById } from '../../api/facilities';
import { updateWorker } from '../../api/worker';
import { firebase } from '../../firebase';

import {
  FETCH_FACILITY_PROFILE_REQUEST,
  FETCH_FACILITY_PROFILE_SUCCESS,
  FETCH_FACILITY_PROFILE_FAIL,
  CHANGE_TO_BLOCKLIST_REQUEST,
  CHANGE_TO_BLOCKLIST_SUCCESS,
} from '../constants/userConstants';

export const fetchFacilityProfile =
  ({ userId }) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_FACILITY_PROFILE_REQUEST,
    });

    try {
      const facility = await getFacilityByUserId(userId);
      const facilityData = facility.data();
      const facilityId = facility.id;

      const facilityAvatar = facilityData.facilityAvatar;
      const facilityName = facilityData.facilityName;
      const facilityPhoneNumber = facilityData.facilityPhoneNumber;
      const facilityAddress = facilityData.facilityAddress;
      const facilityCity = facilityData.facilityCity;
      const facilityState = facilityData.facilityState;
      const facilityPostalCode = facilityData.facilityPostalCode;
      const facilityIntro = facilityData.facilityIntro;
      const facilityNotifications = facilityData.notifications;
      const facilityContactOptions = facilityData.contactOptions;
      const facilityContactInfoVisibility = facilityData.contactInfoVisibility;
      const facilityBlockList = facilityData.blocklist;
      const facilityBlockListEvents = facilityData.blocklistEvents;
      const premierlist = facilityData.premierlist;
      const shiftTemplates = facilityData.shiftTemplates;
      const tz = facilityData.tz;
      const userType = facilityData.userType;
      const status = facilityData.status;
      const isTest = facilityData.isTest;
      const requiresCovidVaccination = facilityData.requiresCovidVaccination;

      const facilityProfile = {
        facilityAvatar,
        facilityName,
        facilityPhoneNumber,
        facilityAddress,
        facilityCity,
        facilityState,
        facilityBlockList,
        facilityBlockListEvents,
        facilityPostalCode,
        facilityIntro,
        status,
        premierlist,
        shiftTemplates,
        tz,
        userType,
        facilityNotifications,
        facilityContactOptions,
        facilityContactInfoVisibility,
        id: facilityId,
        requiresCovidVaccination,
        isTest,
      };

      dispatch({
        type: FETCH_FACILITY_PROFILE_SUCCESS,
        payload: facilityProfile,
      });

      return facilityProfile;
    } catch (error) {
      console.error(error);
      dispatch({
        type: FETCH_FACILITY_PROFILE_FAIL,
        error: error.message,
      });
      return;
    }
  };

export const addToBlockList = (uid, reason) => async (dispatch, getState) => {
  dispatch({ type: CHANGE_TO_BLOCKLIST_REQUEST });
  const now = new Date();
  const { facilityBlockList: blocklist, id: facilityId } = getState().facilityprofile.facilityProfile;

  await updateFacilityById(
    {
      blocklist: firebase.firestore.FieldValue.arrayUnion(uid),
      blocklistEvents: firebase.firestore.FieldValue.arrayUnion({
        timestamp: now,
        nurseId: uid,
        reason,
        action: 'blocked',
      }),
    },
    facilityId
  );

  await updateWorker({
    userId: uid,
    data: {
      blocklistedBy: firebase.firestore.FieldValue.arrayUnion(facilityId),
      blocklistedByEvents: firebase.firestore.FieldValue.arrayUnion({
        timestamp: now,
        facilityId: facilityId,
        reason,
        action: 'blocked',
      }),
    },
  });

  blocklist.push(uid);
  dispatch({ type: CHANGE_TO_BLOCKLIST_SUCCESS, payload: blocklist });
};

export const removeFromBlockList = (uid, reason) => async (dispatch, getState) => {
  dispatch({ type: CHANGE_TO_BLOCKLIST_REQUEST });
  const now = new Date();
  const { facilityBlockList: blocklist, id: facilityId } = getState().facilityprofile.facilityProfile;

  await updateFacilityById(
    {
      blocklist: firebase.firestore.FieldValue.arrayRemove(uid),
      blocklistEvents: firebase.firestore.FieldValue.arrayUnion({
        timestamp: now,
        nurseId: uid,
        reason,
        action: 'unblocked',
      }),
    },
    facilityId
  );

  await updateWorker({
    userId: uid,
    data: {
      blocklistedBy: firebase.firestore.FieldValue.arrayRemove(facilityId),
      blocklistedByEvents: firebase.firestore.FieldValue.arrayUnion({
        timestamp: now,
        facilityId,
        reason,
        action: 'unblocked',
      }),
    },
  });

  const index = blocklist.findIndex((a) => a === uid);
  blocklist.splice(index, 1);
  dispatch({ type: CHANGE_TO_BLOCKLIST_SUCCESS, payload: blocklist });
};
