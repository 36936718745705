import { Col, Row } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import LoginForm from '../LoginForm';
import { capitalize } from '../../../helpers/utils';
import { AppUserType } from 'src/types';

const Login = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  let userType = query.get('userType');
  if (userType !== AppUserType.COMPANY) {
    userType = AppUserType.FACILITY;
  }
  const appUserType = userType as AppUserType;

  return (
    <>
      <Row className="text-left justify-content-between">
        <Col xs="auto">
          <h5>{`${capitalize(appUserType)} Login`}</h5>
        </Col>
        <Col xs="auto">
          <p className="fs--1 text-600">
            or{' '}
            <Link
              to={{
                pathname: '/authentication/wizard',
                search: `?userType=${appUserType}`,
              }}
            >
              create an account
            </Link>
          </p>
        </Col>
      </Row>
      <LoginForm userType={appUserType} />
    </>
  );
};

export default Login;
