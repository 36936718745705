import { AppUserType, Collection } from 'src/types';
import { db } from '../../firebase';
import {
  DASHBOARD_SHIFT_LIST_REQUEST,
  DASHBOARD_SHIFT_LIST_SUCCESS,
  DASHBOARD_SHIFT_LIST_FAIL,
} from '../constants/eventConstants';
import moment from 'moment-timezone';

export const fetchShiftsOnTheDashboard = (facilityId) => async (dispatch, getState) => {
  dispatch({
    type: DASHBOARD_SHIFT_LIST_REQUEST,
  });

  try {
    const { userType } = getState().user;
    const { facilities } = getState().facility;
    const facilityIds = facilities.map((facility) => facility.id);
    let shiftsRef;
    if (userType === 'facility' && facilityId) {
      shiftsRef = db.collection(Collection.SHIFTS).where(`facilityIdentifier`, `==`, facilityId);
    }
    if (userType === AppUserType.COMPANY) {
      shiftsRef = db.collection(Collection.SHIFTS).where(`facilityIdentifier`, `in`, facilityIds);
    }
    const dashboardshiftsSnap = await shiftsRef.get();
    const dashboardshifts = [];

    dashboardshiftsSnap.forEach((snapChild) => {
      const {
        description,
        isFree,
        numberOfNurse,
        nurseType,
        additionalnotes,
        additionalRequirements,
        facilityIdentifier,
        hasShiftDiscontent,
        nurseId,
        status,
        clockIn,
        clockOut,
        tz = 'America/Chicago',
      } = snapChild.data();
      let facilityName = '';
      if (userType === AppUserType.COMPANY) {
        facilityName = facilities.find((f) => f.id === facilityIdentifier).facilityName;
      }

      const { start, end } = snapChild.data();
      const startTime = moment(start.toDate()).tz(tz).format('M/D/Y h:mm A zz');
      const endTime = moment(end.toDate()).tz(tz).format('M/D/Y h:mm A zz');
      const clockInTime = clockIn ? moment(clockIn.toDate()).tz(tz).format('M/D/Y h:mm A zz') : undefined;
      const clockOutTime = clockOut ? moment(clockOut.toDate()).tz(tz).format('M/D/Y h:mm A zz') : undefined;
      dashboardshifts.push({
        id: snapChild.id,
        description,
        isFree,
        start,
        end,
        nurseId,
        startTime,
        status,
        endTime,
        numberOfNurse,
        nurseType,
        additionalnotes,
        additionalRequirements,
        facilityIdentifier,
        facilityName,
        hasShiftDiscontent,
        clockInTime,
        clockOutTime,
      });
    });

    dispatch({
      type: DASHBOARD_SHIFT_LIST_SUCCESS,
      payload: dashboardshifts.sort((a, b) => b.start - a.start),
    });

    return dashboardshifts;
  } catch (error) {
    console.error(error);
    dispatch({
      type: DASHBOARD_SHIFT_LIST_FAIL,
      error: error.message,
    });
    return;
  }
};
