import { useState, useEffect } from 'react';
import { Button, Card, CardBody, Col, Row, Form, FormGroup, Input, Label, CustomInput, CardFooter } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Datetime from 'react-datetime';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase';
import moment from 'moment';
import Loader from '../common/Loader';
import { toast } from 'react-toastify';
import { startEditShift } from '../../redux/actions/editShiftAction';
import { NurseType } from '../../constants';
import { getShiftData } from 'src/api/shifts';

interface FormObj {
  isFree: boolean;
  startTime: string | moment.Moment | undefined;
}

const ShiftDetailsEdit = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  // @ts-ignore
  const { loading } = useSelector((state) => state.shiftedit.loading);

  const dispatch = useDispatch();

  const [description, setDescription] = useState('');
  const [tz, setTZ] = useState<string | undefined>();
  const [startTime, setStartTime] = useState<moment.Moment | undefined>();
  const [endTime, setEndTime] = useState<moment.Moment | undefined>();
  const [durationInHours, setDurationInHours] = useState(8);
  // eslint-disable-next-line
  const [isFree, setIsFree] = useState<string | boolean>('');
  const [nurseType, setNurseType] = useState(NurseType.CAREGIVER);
  const [numberOfNurse, setNumberOfNurse] = useState<string | number>('');
  const [additionalnotes, setAdditionalnotes] = useState<string | undefined>('');
  const [formObj, setFormObj] = useState<FormObj>();
  useEffect(() => {
    const end = moment(startTime).clone().add(durationInHours, 'h');
    setEndTime(end);
  }, [startTime]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (!startTime || !endTime) {
      toast('Please select start date and end date to continue', {
        type: 'error',
      });
      return false;
    }

    const data = {
      description,
      nurseType,
      additionalnotes: additionalnotes || '',
      start: firebase.firestore.Timestamp.fromDate(moment(startTime).toDate()),
      end: firebase.firestore.Timestamp.fromDate(moment(endTime).toDate()),
    };

    try {
      await dispatch(startEditShift(id, data));
      history.replace(`/shift-details/${id}`);
    } catch (error) {
      toast.warn('This action is not available at the moment, try again later');
    }
  };

  useEffect(() => {
    getShiftData(id).then((shift) => {
      setDescription(shift.description);
      setStartTime(moment(shift.start?.toDate()).tz(shift.tz || 'America/Chicago'));
      setEndTime(moment(shift.end?.toDate()).tz(shift.tz || 'America/Chicago'));
      setIsFree(shift.isFree);
      setNurseType(shift.nurseType);
      setNumberOfNurse(shift.numberOfNurse);
      setAdditionalnotes(shift.additionalnotes);
      setTZ(shift.tz);
    });
  }, [id]);

  type DateTarget = {
    name: string;
    value: firebase.firestore.Timestamp;
  };

  const handleChange = (target: DateTarget) => {
    const { name, value } = target;
    setFormObj({ ...formObj, startTime, isFree: true, [name]: value });
  };

  const now = moment(new Date()).tz(tz || 'America/Chicago');

  const disabled = Boolean(startTime && now > startTime);

  return (
    <>
      {startTime ? (
        <>
          <Form onSubmit={handleSubmit}>
            <Card className="mb-3">
              <FalconCardHeader title="Shift Details" light={false} />
              <CardBody className="bg-light">
                <Row form>
                  <Col sm tag={FormGroup}>
                    <Label for="shift-description">Shift Description</Label>
                    <Input
                      defaultValue={description}
                      type="text"
                      name="description"
                      id="shift-description"
                      placeholder="Shift Description"
                      disabled={disabled}
                      onChange={({ target }) => setDescription(target.value)}
                    />
                  </Col>
                </Row>

                <Row form>
                  <Col sm tag={FormGroup}>
                    <Label for="eventStart">Start Date</Label>
                    <Datetime
                      timeFormat={true}
                      isValidDate={(currentDate) => currentDate.isAfter(moment().add(-1, 'd'))}
                      value={startTime}
                      onChange={(dateTime) => {
                        if (typeof dateTime === 'string') {
                          return;
                        }
                        if (dateTime.isValid()) {
                          setStartTime(dateTime);
                          const date = {
                            value: firebase.firestore.Timestamp.fromDate(
                              moment(dateTime.format(), moment.defaultFormat).toDate()
                            ),
                            name: 'start',
                          };
                          handleChange(date);
                        }
                      }}
                      dateFormat="MM-DD-YYYY"
                      inputProps={{ placeholder: 'MM-DD-YYYY H:M', id: 'eventStart', disabled }}
                    />
                  </Col>
                  <Col sm tag={FormGroup}>
                    <Label for="eventEnd">End Date</Label>
                    <Datetime
                      value={endTime}
                      timeFormat={true}
                      isValidDate={(currentDate) => {
                        if (!endTime) {
                          return false;
                        }
                        const currentDateWithTime = currentDate.clone().set({
                          hour: endTime.get('hour'),
                          minute: endTime.get('minute'),
                          second: endTime.get('second'),
                        });
                        return (
                          currentDateWithTime.diff(moment(startTime), 'h') <= 12 &&
                          currentDateWithTime.diff(moment(startTime), 'h') >= 0
                        );
                      }}
                      onChange={(dateTime) => {
                        if (typeof dateTime === 'string') {
                          return;
                        }
                        if (dateTime.isValid()) {
                          setDurationInHours(dateTime.diff(moment(startTime), 'h'));
                          setEndTime(dateTime);
                          const date = {
                            value: firebase.firestore.Timestamp.fromDate(
                              moment(dateTime.format(), moment.defaultFormat).toDate()
                            ),
                            name: 'end',
                          };
                          handleChange(date);
                        }
                      }}
                      dateFormat="MM-DD-YYYY"
                      inputProps={{ placeholder: 'MM-DD-YYYY H:M', id: 'eventEnd', disabled: Boolean(disabled) }}
                    />
                  </Col>
                </Row>

                <Row form>
                  <FormGroup>
                    <Label for="eventNurseType">Position</Label>
                    <CustomInput
                      value={nurseType}
                      type="select"
                      id="eventNurseType"
                      name="nurseType"
                      disabled={disabled}
                      onChange={({ target }) => setNurseType(target.value)}
                    >
                      <option value={NurseType.CAREGIVER}>{NurseType.CAREGIVER}</option>
                      <option value={NurseType.CNA}>{NurseType.CNA}</option>
                      <option value={NurseType.LPN}>{NurseType.LPN}</option>
                      <option value={NurseType.RN}>{NurseType.RN}</option>
                    </CustomInput>
                  </FormGroup>
                </Row>
                <Row form>
                  <Col sm>
                    <Label for="additionalnotes">Additional Notes</Label>
                    <Input
                      id="additionalnotes"
                      type="textarea"
                      rows={6}
                      placeholder="Additional Notes"
                      value={additionalnotes}
                      disabled={disabled}
                      onChange={({ target }) => setAdditionalnotes(target.value)}
                    />
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row className="justify-content-between align-items-center">
                  <Col md />
                  <Col xs="auto">
                    <Button color="falcon-default" size="sm" className="mr-2" disabled={disabled}>
                      Save changes
                    </Button>
                    <Button
                      onClick={() => {
                        history.push('/shifts');
                      }}
                      color="falcon-warning"
                      size="sm"
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </>
      ) : loading ? (
        <Loader />
      ) : (
        <h6>{`The shift wasn't found`}</h6>
      )}
    </>
  );
};

export default ShiftDetailsEdit;
