import { DashboardShift } from 'src/types';
import {
  DASHBOARD_SHIFT_LIST_REQUEST,
  DASHBOARD_SHIFT_LIST_SUCCESS,
  DASHBOARD_SHIFT_LIST_FAIL,
} from '../constants/eventConstants';
import { USER_LOGOUT } from '../constants/userConstants';

export interface DashboardShiftListReducerState {
  dashboardshifts: DashboardShift[];
  loading: boolean;
  error?: any;
}

const initialState: DashboardShiftListReducerState = { dashboardshifts: [], loading: false };

export interface DashboardShiftListReducerAction {
  type:
    | typeof DASHBOARD_SHIFT_LIST_REQUEST
    | typeof DASHBOARD_SHIFT_LIST_SUCCESS
    | typeof DASHBOARD_SHIFT_LIST_FAIL
    | typeof USER_LOGOUT;
  payload: DashboardShift[];
}
export const dashboardShiftListReducer = (
  state: DashboardShiftListReducerState = initialState,
  action: DashboardShiftListReducerAction
): DashboardShiftListReducerState => {
  switch (action.type) {
    case DASHBOARD_SHIFT_LIST_REQUEST:
      return { loading: true, dashboardshifts: [] };
    case DASHBOARD_SHIFT_LIST_SUCCESS:
      return {
        loading: false,
        dashboardshifts: action.payload,
      };
    case DASHBOARD_SHIFT_LIST_FAIL:
      return { loading: false, error: action.payload, dashboardshifts: [] };
    case USER_LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};
