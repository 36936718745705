import { isBrookdale } from 'src/helpers/utils';
import {
  Collection,
  Facility,
  PremierStatus,
  PremierWorker,
  Worker,
  WithId,
  NewFacilityParams,
  FacilityAdminOwner,
  UserType,
} from 'src/types';
import { db, firebase, auth } from '../firebase';
import { getWorkerData } from './worker';

const userCollection = db.collection(Collection.USER);
const facilitiesCollection = db.collection(Collection.FACILITIES);
const invitesCollection = db.collection(Collection.INVITES);

/* User Table */

export const getUserFacilityData = async (id: string) => {
  const user = await getUserFacility(id);
  return user.data() as FacilityAdminOwner;
};

export const getUserFacility = (id: string) => {
  return userCollection.doc(id).get();
};

export const updateUserFacility = ({ userId, data }: { userId: string; data: Partial<FacilityAdminOwner> }) => {
  return userCollection.doc(userId).update(data);
};

export const getUserFacilityRef = (id: string) => {
  if (id) {
    return userCollection.doc(id);
  }
  return userCollection;
};

/* Facilities Table */

export const getFacilityData = async (id: string) => {
  const facility = await getFacility(id);
  return facility.data() as Facility;
};

export const getFacilityDataByOwnerId = async (ownerId: string) => {
  const facility = await getFacilityByUserId(ownerId);
  return facility.data();
};

export const getFacility = (id: string) => {
  return facilitiesCollection.doc(id).get();
};

export const updateFacilityById = (data: Partial<Facility>, id: string) => {
  return facilitiesCollection.doc(id).set(data, { merge: true });
};

export const getFacilityByUserId = async (userId: string) => {
  const facilityRef = await facilitiesCollection.where('ownerId', '==', userId).get();
  if (facilityRef.docs.length === 1) {
    return facilityRef.docs[0];
  }
  const adminFacilityRef = await facilitiesCollection.where('adminIds', 'array-contains', userId).get();
  if (adminFacilityRef.docs.length === 1) {
    return adminFacilityRef.docs[0];
  }
  throw new Error(`User with id ${userId} is not an admin or owner of any facilities`);
};

export const getFacilityRef = (userId: string) => {
  if (userId) {
    return facilitiesCollection.doc(userId);
  }
  return facilitiesCollection;
};

export const getAllFacilities = () => {
  return facilitiesCollection.orderBy('facilityName').get();
};

export const createFacility = (data: NewFacilityParams) => {
  return facilitiesCollection.add(data);
};

export const getAllUserDataByFacilityId = async (facilityId: string): Promise<WithId<FacilityAdminOwner>[]> => {
  const { adminIds, ownerId } = await getFacilityData(facilityId);
  const userIds: string[] = [...(adminIds || [])];
  if (ownerId) {
    userIds.push(ownerId);
  }
  console.log({ adminIds, ownerId, userIds });
  return Promise.all(
    userIds.map((userId: string) => {
      return new Promise(async (resolve: (user: WithId<FacilityAdminOwner>) => void) => {
        const userData = await getUserFacilityData(userId);
        const userDataWithId = { ...userData, id: userId };
        resolve(userDataWithId);
      });
    })
  );
};

/* Other */

export const createFacilityAdmin = ({
  userData,
  facilityId,
  userId,
  inviteId,
}: {
  userData: FacilityAdminOwner;
  facilityId: string;
  userId: string;
  inviteId: string;
}) => {
  return db.runTransaction(async (transaction) => {
    const userFacilitiesRef = userCollection.doc(userId);
    const facilitiesRef = facilitiesCollection.doc(facilityId);
    const invitesRef = invitesCollection.doc(inviteId);
    transaction.set(userFacilitiesRef, {
      isTest: false,
      ...userData,
      userType: UserType.FACILITY,
      facilityAdminOf: facilityId,
    });
    transaction.update(facilitiesRef, { adminIds: firebase.firestore.FieldValue.arrayUnion(userId) });
    transaction.update(invitesRef, { userId });
  });
};

export const createFacilityAndOwner = ({
  ownerId,
  facilityData,
  ownerData,
}: {
  ownerId: string;
  facilityData: Partial<Facility>;
  ownerData: Partial<FacilityAdminOwner>;
}) => {
  return db.runTransaction(async (transaction) => {
    const ownerRef = userCollection.doc(ownerId);
    const facilityRef = facilitiesCollection.doc();
    transaction.set(ownerRef, {
      userType: UserType.FACILITY,
      facilityOwnerOf: facilityRef.id,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      notifications: 'all-notifications',
      contactOptions: 'email-option',
      isTest: false,
      ...ownerData,
    });
    transaction.set(facilityRef, {
      ownerId,
      status: 'pending',
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      facilityIntro: 'About this Facility',
      contactInfoVisibility: true,
      allowedPositions: [],
      isTest: false,
      ...facilityData,
    });
  });
};

export const requestPremierWorker = async (facilityId: string, workerId: string, isTest: boolean) => {
  const brookdaleFacilities = await getAllBrookdaleFacilities(isTest);
  const batch = db.batch();
  const userRef = userCollection.doc(workerId);
  batch.update(userRef, {
    brookdalePremier: { status: PremierStatus.REQUESTED, requestedBy: auth.currentUser?.uid, facilityId },
  });
  brookdaleFacilities.forEach(({ id }) => {
    const facilitiesRef = facilitiesCollection.doc(id);
    const premierWorker: PremierWorker = {
      id: workerId,
      status: PremierStatus.REQUESTED,
    };
    batch.update(facilitiesRef, { premierlist: firebase.firestore.FieldValue.arrayUnion(premierWorker) });
  });
  await batch.commit();
};

export const getWorkersByFacility = async (facilityId: string): Promise<WithId<Worker>[]> => {
  const completedShifts = await db
    .collection(Collection.SHIFTS)
    .where('facilityIdentifier', '==', facilityId)
    .where('clockOut', '!=', null)
    .get();
  const workerSet = new Set<string>();
  completedShifts.docs.forEach((doc) => {
    const { nurseId, clockIn, clockOut } = doc.data();
    if (!clockIn || !clockOut || !nurseId) {
      return;
    }
    workerSet.add(nurseId);
  });
  const workerPromises = [...workerSet].map((workerId) => {
    return new Promise(async (resolve: (worker: WithId<Worker>) => void) => {
      const workerData = await getWorkerData(workerId);
      resolve({ ...workerData, id: workerId });
    });
  });
  return Promise.all(workerPromises);
};

const getAllBrookdaleFacilities = async (isTest: boolean) => {
  const allFacilities = await getAllFacilities();
  const brookdaleFacilities: WithId<Facility>[] = [];
  allFacilities.docs.forEach((doc) => {
    if (isBrookdale(doc.data().facilityName) && doc.data().isTest === isTest) {
      brookdaleFacilities.push({ ...doc.data(), id: doc.id } as WithId<Facility>);
    }
  });
  return brookdaleFacilities;
};
