import { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
} from 'reactstrap';
import Datetime from 'react-datetime';
import moment from 'moment-timezone';
import firebase from 'firebase';
import Flex from '../common/Flex';
import { useDispatch, useSelector } from 'react-redux';
import { startAddShift } from '../../redux/actions/addShiftAction';
import { toast } from 'react-toastify';
import { AdditionalRequirements, AppUserType, Facility, NurseType, WithId, ShiftTemplate } from 'src/types';
import { facilityShouldShowMedpass } from 'src/helpers/utils';

const Required = () => <span style={{ color: 'red' }}>&nbsp;*</span>;

const getFacilityById = (facilities: Array<WithId<Facility>>, facilityId: string | undefined) =>
  facilities.find((facility) => facility.id === facilityId);

const getValidatedForm = (
  formObj: FormObj,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null
):
  | { validatedForm: ValidatedFormObj; validatedStartDate: moment.Moment; validatedEndDate: moment.Moment }
  | undefined => {
  const { start, end, numberOfNurse, nurseType, shiftTemplateIndex, description, userType, facilityIdentifier } =
    formObj;
  let facilityIdentifierRequired = false;
  if (userType === AppUserType.COMPANY) {
    facilityIdentifierRequired = true;
  }
  const shiftTemplateChosen = shiftTemplateIndex !== null && shiftTemplateIndex && shiftTemplateIndex >= 0;
  if (
    start &&
    description &&
    numberOfNurse &&
    nurseType &&
    (end || shiftTemplateChosen) &&
    (!facilityIdentifierRequired || facilityIdentifier) &&
    userType &&
    startDate &&
    endDate
  ) {
    const validatedForm: ValidatedFormObj = {
      ...formObj,
      start,
      description,
      numberOfNurse,
      nurseType,
      userType,
      additionalRequirements: formObj.additionalRequirements || [],
    };
    const validatedStartDate: moment.Moment = startDate;
    const validatedEndDate: moment.Moment = endDate;
    return { validatedForm, validatedStartDate, validatedEndDate };
  }
  return;
};

interface AddScheduleModalProps {
  setIsOpenScheduleModal: (isOpenScheduleModal: boolean) => void;
  isOpenScheduleModal: boolean;
  userType: AppUserType;
  initialFacilityId: string | undefined;
  initialStartDate?: moment.Moment | undefined;
  facilities: Array<WithId<Facility>>;
  onSuccess?: () => void;
}

interface FormObj {
  numberOfNurse: string;
  nurseType: NurseType;
  shiftTemplateIndex?: number | null;
  description?: string;
  userType?: AppUserType;
  facilityIdentifier?: string;
  start?: firebase.firestore.Timestamp;
  end?: firebase.firestore.Timestamp;
  isFree?: boolean;
  additionalRequirements?: Array<AdditionalRequirements>;
}
interface ValidatedFormObj {
  numberOfNurse: string;
  nurseType: NurseType;
  shiftTemplateIndex?: number | null;
  description: string;
  userType: AppUserType;
  facilityIdentifier?: string;
  start: firebase.firestore.Timestamp;
  end?: firebase.firestore.Timestamp;
  isFree?: boolean;
  additionalRequirements: Array<AdditionalRequirements>;
}

type DateTarget = {
  name: string;
  value: firebase.firestore.Timestamp;
};

const AddScheduleModal = ({
  setIsOpenScheduleModal,
  isOpenScheduleModal,
  /* eslint-disable-next-line */
  onSuccess = () => {},
  userType,
  initialStartDate,
  facilities,
  initialFacilityId,
}: AddScheduleModalProps) => {
  const initialDurationInHours = 8;
  const toggle = () => setIsOpenScheduleModal(!isOpenScheduleModal);
  const [loading, setLoading] = useState(false);
  const [durationInHours, setDurationInHours] = useState(initialDurationInHours);
  const [startDate, setStartDate] = useState<moment.Moment | null>(null);
  const [endDate, setEndDate] = useState<moment.Moment | null>(null);
  const [requiresMedPass, setRequiresMedPass] = useState(false);
  const [requiresMedPassDisabled, setRequiresMedPassDisabled] = useState(false);
  const [shiftTemplateIndex, setShiftTemplateIndex] = useState<null | number>(null);
  // @ts-ignore
  const { facilityProfile } = useSelector((state) => state.facilityprofile);
  const initialState: FormObj = {
    nurseType: NurseType.CAREGIVER,
    numberOfNurse: '1',
  };
  const [formObj, setFormObj] = useState(initialState);
  const resetFormState = () => {
    // Don't reset shift template index
    // because it causes corrupted state
    setFormObj(initialState);
    setDurationInHours(initialDurationInHours);
    setRequiresMedPassDisabled(false);
    setRequiresMedPass(false);
    setStartDate(null);
    setEndDate(null);
  };

  useEffect(() => {
    if (userType === AppUserType.COMPANY && initialFacilityId !== formObj.facilityIdentifier) {
      setFormObj({ ...formObj, facilityIdentifier: initialFacilityId });
    }
  }, [userType, initialFacilityId]);

  useEffect(() => {
    if (initialStartDate) {
      setStartDate(initialStartDate);
      const date = {
        value: firebase.firestore.Timestamp.fromDate(moment(initialStartDate.format(), moment.defaultFormat).toDate()),
        name: 'start',
      };
      handleChange(date);
    }
  }, [initialStartDate]);

  // Set start and end date on first render
  useEffect(() => {
    const start = moment().add(2, 'days');
    start.set({ hour: 9, minute: 0, second: 0 });
    setStartDate(start);
  }, []);

  // Change end datetime to be start datetime + duration
  // Only run whenever start time is changed
  useEffect(() => {
    if (!startDate) {
      return;
    }
    const end = moment(startDate).clone().add(durationInHours, 'h');
    setEndDate(end);
    setFormObj({
      ...formObj,
      start: firebase.firestore.Timestamp.fromDate(startDate.toDate()),
      end: firebase.firestore.Timestamp.fromDate(end.toDate()),
    });
  }, [startDate]);

  const closeBtn = (
    <button
      className="close font-weight-normal"
      onClick={(e) => {
        e.preventDefault();
        toggle();
      }}
    >
      &times;
    </button>
  );

  const dispatch = useDispatch();

  const handleChange = (target: (EventTarget & HTMLInputElement) | DateTarget) => {
    let name = target.name;
    let value: boolean | Array<AdditionalRequirements> | number | string | firebase.firestore.Timestamp =
      name === 'requiresMedPass' && 'checked' in target ? target.checked : target.value;
    if (name === 'requiresMedPass') {
      name = 'additionalRequirements';
      value = value ? [AdditionalRequirements.MED_PASS] : [];
    }
    if (name === 'numberOfNurse' && typeof value === 'string') {
      value = parseInt(value);
    }
    // if the formObj has facilityIdentifier, then override the default facilityIdentifier
    // this should happen when logged in as company owner
    setFormObj({ facilityIdentifier: facilityProfile?.id, ...formObj, isFree: true, [name]: value });
  };

  useEffect(() => {
    if ([NurseType.LPN, NurseType.RN].includes(formObj.nurseType)) {
      setRequiresMedPass(true);
      setRequiresMedPassDisabled(true);
      setFormObj({ ...formObj, additionalRequirements: [AdditionalRequirements.MED_PASS] });
    }
    if ([NurseType.CNA, NurseType.CAREGIVER].includes(formObj.nurseType)) {
      setRequiresMedPass(false);
      setRequiresMedPassDisabled(false);
      setFormObj({ ...formObj, additionalRequirements: [] });
    }
  }, [formObj.nurseType]);

  const chosenCompanyFacility = getFacilityById(facilities, formObj.facilityIdentifier);

  const shiftTemplates: Array<ShiftTemplate> = !userType
    ? []
    : userType === AppUserType.FACILITY
    ? facilityProfile?.shiftTemplates || []
    : chosenCompanyFacility
    ? chosenCompanyFacility.shiftTemplates
    : [];
  const showFacilityShiftTemplateOptions = shiftTemplates?.length > 0;

  useEffect(() => {
    if (shiftTemplates && shiftTemplates.length && shiftTemplateIndex === null) {
      setShiftTemplateIndex(0);
    }
  }, [shiftTemplates]);

  const tz = !userType
    ? null
    : userType === AppUserType.FACILITY
    ? facilityProfile?.tz || null
    : chosenCompanyFacility
    ? chosenCompanyFacility.tz
    : null;

  const facilityState = !userType
    ? null
    : userType === AppUserType.FACILITY
    ? facilityProfile?.facilityState || null
    : chosenCompanyFacility
    ? chosenCompanyFacility.facilityState
    : null;

  const facilityIsTest = !userType
    ? false
    : userType === AppUserType.FACILITY
    ? facilityProfile?.isTest || false
    : chosenCompanyFacility
    ? chosenCompanyFacility.isTest
    : false;

  const validationResult = getValidatedForm({ ...formObj, shiftTemplateIndex, userType }, startDate, endDate);

  return (
    <Modal isOpen={isOpenScheduleModal} toggle={toggle} modalClassName="theme-modal" contentClassName="border">
      <Form
        onSubmit={async (e) => {
          try {
            setLoading(true);
            e.preventDefault();
            if (!validationResult) {
              toast.error('Please make sure all required fields are filled out');
              return false;
            }
            const { validatedForm, validatedStartDate, validatedEndDate } = validationResult;

            let start = validatedStartDate.toDate();
            let end;
            if (shiftTemplateIndex !== null && shiftTemplateIndex >= 0) {
              const shiftTemplate = shiftTemplates[shiftTemplateIndex];
              start.setHours(shiftTemplate.startTime.toDate().getHours());
              start.setMinutes(shiftTemplate.startTime.toDate().getMinutes());
              end = new Date(start);
              end.setHours(shiftTemplate.endTime.toDate().getHours());
              end.setMinutes(shiftTemplate.endTime.toDate().getMinutes());
              if (end < start) {
                end.setDate(start.getDate() + 1);
              }
            } else if (tz) {
              end = moment(validatedEndDate.toDate()).tz(tz, true).toDate();
              start = moment(validatedStartDate.toDate()).tz(tz, true).toDate();
            } else {
              end = validatedEndDate.toDate();
            }
            validatedForm.start = firebase.firestore.Timestamp.fromDate(start);
            validatedForm.end = firebase.firestore.Timestamp.fromDate(end);
            const facility = !userType
              ? null
              : userType === AppUserType.FACILITY
              ? facilityProfile
              : chosenCompanyFacility || null;
            if (facility.requiresCovidVaccination) {
              validatedForm.additionalRequirements = [
                ...(validatedForm.additionalRequirements || []),
                AdditionalRequirements.COVID_VACCINATION,
              ];
            }
            const { numberOfNurse, nurseType, description, isFree, additionalRequirements } = validatedForm;
            await dispatch(
              startAddShift({
                start,
                end,
                facilityIdentifier: facility.id,
                isFree,
                additionalRequirements,
                description,
                nurseType,
                numberOfNurse,
                isTest: facilityIsTest,
              })
            );
            toast.success('Successfully created shift!');
            setIsOpenScheduleModal(false);
            await onSuccess();
            resetFormState();
          } catch (e) {
            console.log(e);
            toast.error('Failed to create shift');
          }
          setLoading(false);
        }}
      >
        <ModalHeader toggle={toggle} className="bg-light d-flex flex-between-center border-bottom-0" close={closeBtn}>
          Add a shift to the schedule
        </ModalHeader>
        <ModalBody>
          {userType === AppUserType.COMPANY && facilities && facilities.length > 0 && (
            <FormGroup>
              <Label className="fs-0" for="facilityIdentifier">
                Facility Name
                <Required />
              </Label>
              <CustomInput
                required
                type="select"
                id="facilityIdentifier"
                name="facilityIdentifier"
                onChange={({ target }) => handleChange(target)}
              >
                {facilities.map((facility) => (
                  <option key={facility.id} value={facility.id}>
                    {facility.facilityName}
                  </option>
                ))}
              </CustomInput>
            </FormGroup>
          )}
          <FormGroup>
            <Label className="fs-0" for="eventDescription">
              Description
              <Required />
            </Label>
            <Input
              required
              name="description"
              id="eventDescription"
              value={formObj?.description}
              onChange={({ target }) => handleChange(target)}
            />
          </FormGroup>
          {showFacilityShiftTemplateOptions && (
            <FormGroup>
              <Label className="fs-0" for="shiftTemplate">
                Shift Template
                <Required />
              </Label>
              <CustomInput
                required
                type="select"
                id="shiftTemplate"
                name="shiftTemplate"
                onChange={({ target }) => setShiftTemplateIndex(parseInt(target.value))}
                value={shiftTemplateIndex || undefined}
              >
                {shiftTemplates.map((t, index) => (
                  <option key={index} value={index}>
                    {t.name}
                  </option>
                ))}
                <option value={-1}>None</option>
              </CustomInput>
            </FormGroup>
          )}

          {showFacilityShiftTemplateOptions && shiftTemplateIndex !== null && shiftTemplateIndex >= 0 && (
            <FormGroup>
              <Label className="fs-0" for="shiftStart">
                Start Date
                <Required />
              </Label>
              <Datetime
                timeFormat={false}
                value={startDate || undefined}
                onChange={(date) => {
                  if (typeof date === 'string') {
                    return;
                  }
                  if (date.isValid()) {
                    setStartDate(date);
                    const dateObj = {
                      value: firebase.firestore.Timestamp.fromDate(
                        moment(date.format(), moment.defaultFormat).tz(tz).toDate()
                      ),
                      name: 'start',
                    };
                    handleChange(dateObj);
                  }
                }}
                dateFormat="MM-DD-YYYY"
                inputProps={{ required: true, placeholder: 'MM-DD-YYYY', id: 'shiftStart' }}
              />
            </FormGroup>
          )}
          {(!showFacilityShiftTemplateOptions || shiftTemplateIndex === -1) && (
            <>
              <FormGroup>
                <Label className="fs-0" for="eventStart">
                  Start
                  <Required />
                </Label>
                <Datetime
                  timeFormat={true}
                  value={startDate || undefined}
                  isValidDate={(currentDate) => currentDate.isAfter(moment().add(-1, 'd'))}
                  onChange={(dateTime) => {
                    if (typeof dateTime === 'string') {
                      return;
                    }
                    if (dateTime.isValid()) {
                      setStartDate(dateTime);
                      const date = {
                        value: firebase.firestore.Timestamp.fromDate(
                          moment(dateTime.format(), moment.defaultFormat).toDate()
                        ),
                        name: 'start',
                      };
                      handleChange(date);
                    }
                  }}
                  dateFormat="MM-DD-YYYY"
                  inputProps={{
                    required: true,
                    placeholder: 'MM-DD-YYYY H:M',
                    id: 'eventStart',
                    autoComplete: 'off',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label className="fs-0" for="eventEnd">
                  End
                  <Required />
                </Label>
                <Datetime
                  value={endDate || undefined}
                  timeFormat={true}
                  isValidDate={(currentDate) => {
                    if (!endDate) {
                      return false;
                    }
                    const currentDateWithTime = currentDate.clone().set({
                      hour: endDate.get('hour'),
                      minute: endDate.get('minute'),
                      second: endDate.get('second'),
                    });
                    return (
                      currentDateWithTime.diff(moment(startDate), 'h') <= 12 &&
                      currentDateWithTime.diff(moment(startDate), 'h') >= 0
                    );
                  }}
                  onChange={(dateTime) => {
                    if (typeof dateTime === 'string') {
                      return;
                    }
                    if (dateTime.isValid()) {
                      setDurationInHours(dateTime.diff(moment(startDate), 'h'));
                      setEndDate(dateTime);
                      const date = {
                        value: firebase.firestore.Timestamp.fromDate(
                          moment(dateTime.format(), moment.defaultFormat).toDate()
                        ),
                        name: 'end',
                      };
                      handleChange(date);
                    }
                  }}
                  dateFormat="MM-DD-YYYY"
                  inputProps={{ required: true, autoComplete: 'off', placeholder: 'MM-DD-YYYY H:M', id: 'eventEnd' }}
                />
              </FormGroup>
            </>
          )}
          <FormGroup>
            <Label for="eventNurseType" className="fs-0">
              Position
              <Required />
            </Label>
            <CustomInput
              required
              type="select"
              id="eventNurseType"
              name="nurseType"
              onChange={({ target }) => handleChange(target)}
            >
              <option value={NurseType.CAREGIVER}>{NurseType.CAREGIVER}</option>
              <option value={NurseType.CNA}>{NurseType.CNA}</option>
              <option value={NurseType.LPN}>{NurseType.LPN}</option>
              <option value={NurseType.RN}>{NurseType.RN}</option>
            </CustomInput>
          </FormGroup>
          {facilityShouldShowMedpass(facilityState) && (
            <FormGroup className="form-check">
              <Input
                type="checkbox"
                id="requiresMedPass"
                name="requiresMedPass"
                checked={requiresMedPass}
                disabled={requiresMedPassDisabled}
                onChange={({ target }) => {
                  setRequiresMedPass(target.checked);
                  handleChange(target);
                }}
              />
              <Label check>Requires Med Pass</Label>
            </FormGroup>
          )}

          <FormGroup>
            <Label className="fs-0" for="eventNumberOfNurse">
              Number of workers
              <Required />
            </Label>
            <Input
              required
              type="number"
              id="eventNumberOfNurse"
              name="numberOfNurse"
              value={formObj.numberOfNurse}
              onChange={({ target }) => handleChange(target)}
            />
          </FormGroup>
          <FormGroup>
            <Label className="fs-0" for="eventAdditionalNotes">
              Additional Notes
            </Label>
            <Input
              type="textarea"
              name="additionalnotes"
              id="eventAdditionalNotes"
              onChange={({ target }) => handleChange(target)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter tag={Flex} justify="end" align="center" className="bg-light border-top-0">
          <Button disabled={!validationResult || loading} color="primary" type="submit" className="px-4">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddScheduleModal;
