import { AppUserType, Collection } from 'src/types';
import { db } from '../../firebase';
import {
  SHIFT_HAS_MORE,
  SHIFT_LIMIT,
  SHIFT_LIST_FAIL,
  SHIFT_LIST_REQUEST,
  SHIFT_LIST_SUCCESS,
} from '../constants/shiftConstants';

export const getShifts = () => async (dispatch, getState) => {
  dispatch({
    type: SHIFT_LIST_REQUEST,
  });
  const shiftsArr = [];
  try {
    const { shifts } = getState().shift;
    const { userType } = getState().user;
    const { facilities } = getState().facility;
    const { facilityProfile } = getState().facilityprofile;
    const facilityIds = facilities.map((facility) => facility.id);
    if (!userType) {
      return;
    }
    let shiftsSnap;
    if (userType === 'facility') {
      shiftsSnap = db.collection(Collection.SHIFTS).where(`facilityIdentifier`, `==`, facilityProfile.id);
    }
    if (userType === AppUserType.COMPANY) {
      shiftsSnap = db.collection(Collection.SHIFTS).where(`facilityIdentifier`, `in`, facilityIds);
    }
    if (shifts.length && shifts[shifts.length - 1]) {
      shiftsSnap = await shiftsSnap
        .orderBy('createdAt', 'desc')
        .startAfter(shifts[shifts.length - 1].createdAt)
        .limit(SHIFT_LIMIT)
        .get();
    } else {
      shiftsSnap = await shiftsSnap.orderBy('createdAt', 'desc').limit(SHIFT_LIMIT).get();
    }

    shiftsSnap.forEach((snapChild) => {
      const data = {
        ...snapChild.data(),
        id: snapChild.id,
      };
      shiftsArr.push(data);
    });
    dispatch({
      type: SHIFT_LIST_SUCCESS,
      payload: {
        shifts: shiftsArr,
      },
    });

    if (shiftsArr.length < SHIFT_LIMIT) {
      dispatch({
        type: SHIFT_HAS_MORE,
        payload: { hasMore: false },
      });
    } else {
      dispatch({
        type: SHIFT_HAS_MORE,
        payload: { hasMore: true },
      });
    }
  } catch (e) {
    console.error(e);
    dispatch({
      type: SHIFT_LIST_FAIL,
    });
  }
};

export const getShiftsAndEmpty = () => async (dispatch, getState) => {
  dispatch({
    type: SHIFT_LIST_REQUEST,
  });
  try {
    const { userType } = getState().user;
    const { facilities } = getState().facility;
    const { facilityProfile } = getState().facilityprofile;
    const facilityIds = facilities.map((facility) => facility.id);
    if (!userType || (userType === AppUserType.COMPANY && facilityIds.length === 0)) {
      return;
    }
    let shiftsRef;
    if (userType === 'facility') {
      shiftsRef = db.collection(Collection.SHIFTS).where(`facilityIdentifier`, `==`, facilityProfile.id);
    }
    if (userType === AppUserType.COMPANY) {
      shiftsRef = db.collection(Collection.SHIFTS).where(`facilityIdentifier`, `in`, facilityIds);
    }
    let shiftsSnap = await shiftsRef.orderBy('createdAt', 'desc').limit(SHIFT_LIMIT).get();
    const shiftsArr = [];
    shiftsSnap.forEach((snapChild) => {
      const data = {
        ...snapChild.data(),
        id: snapChild.id,
      };
      shiftsArr.push(data);
    });
    dispatch({
      type: SHIFT_LIST_SUCCESS,
      payload: {
        shifts: shiftsArr,
        empty: true,
      },
    });
    if (shiftsArr.length < SHIFT_LIMIT) {
      dispatch({
        type: SHIFT_HAS_MORE,
        payload: { hasMore: false },
      });
    } else {
      dispatch({
        type: SHIFT_HAS_MORE,
        payload: { hasMore: true },
      });
    }
  } catch (e) {
    console.error(e);
    dispatch({
      type: SHIFT_LIST_FAIL,
    });
  }
};
