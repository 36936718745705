import FacilityShiftTemplateCard from '../common/FacilityShiftTemplateCard';
import WelcomeCard from '../common/WelcomeCard';
import { AppUserType } from 'src/types';
import ShiftsTable from '../dashboard/ShiftsTable';
import useAppSelector from 'src/hooks/useAppSelector';

const Shifts = () => {
  const { userType } = useAppSelector((state) => state.user);
  const { facilityProfile } = useAppSelector((state) => state.facilityprofile);
  return (
    <>
      <WelcomeCard />
      <ShiftsTable />
      {userType === AppUserType.FACILITY && facilityProfile && (
        <FacilityShiftTemplateCard facility={facilityProfile} />
      )}{' '}
    </>
  );
};

export default Shifts;
