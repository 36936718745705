import {
  USER_EMAIL_LOGIN_FAIL,
  USER_EMAIL_LOGIN_REQUEST,
  USER_EMAIL_LOGIN_SUCCESS,
  USER_LOGOUT,
} from '../constants/userConstants';

export interface UserManualLoginReducerState {
  loading?: boolean;
  uid?: string;
  email?: string;
  name?: string;
  error?: any;
}

export interface UserManualLoginReducerAction {
  payload: {
    uid?: string;
    email?: string;
    name?: string;
    displayName?: string;
  };
  type:
    | typeof USER_EMAIL_LOGIN_REQUEST
    | typeof USER_EMAIL_LOGIN_SUCCESS
    | typeof USER_EMAIL_LOGIN_FAIL
    | typeof USER_LOGOUT;
}

// When the user logs in with previously created email and password
export const userManualLoginReducer = (
  state: UserManualLoginReducerState = {},
  action: UserManualLoginReducerAction
): UserManualLoginReducerState => {
  switch (action.type) {
    case USER_EMAIL_LOGIN_REQUEST:
      return { loading: true };
    case USER_EMAIL_LOGIN_SUCCESS:
      return {
        loading: false,
        uid: action.payload.uid,
        email: action.payload.email,
        name: action.payload.displayName,
      };
    case USER_EMAIL_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};
