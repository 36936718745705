import { AdminUser, Collection, FacilityAdminOwner, Shift, UserType, WithId, Worker } from 'src/types';
import { db } from '../firebase';

const userCollection = db.collection(Collection.USER);

export const getWorkerData = async (id: string): Promise<Worker> => {
  const worker = await getWorker(id);
  return worker.data() as Worker;
};

export const getWorker = (id: string) => {
  return userCollection.doc(id).get();
};

export const getAllWorkers = () => {
  return userCollection.where('userType', '==', UserType.WORKER).get();
};

export const getWorkerRef = (userId: string) => {
  if (userId) {
    return userCollection.doc(userId);
  }
  return userCollection;
};

export const updateWorker = ({ userId, data }: { userId: string; data: Partial<Worker> }) => {
  return userCollection.doc(userId).update(data);
};

export const getUserData = async (id: string) => {
  const facility = await getWorker(id);
  return facility.data() as FacilityAdminOwner | Worker | AdminUser;
};

export const getWorkersFromShifts = async (shifts: Shift[]) => {
  const nurseIds = new Set<string>();
  shifts.forEach((shift) => {
    if (shift.nurseId) {
      nurseIds.add(shift.nurseId);
    }
  });
  const nursePromises = [...nurseIds].map((id: string) => {
    return new Promise((resolve) => {
      const getNurseData = async () => {
        const nurse = await getWorker(id);
        return {
          ...nurse.data(),
          id: nurse.id,
        };
      };
      getNurseData().then((nurseData) => resolve(nurseData));
    });
  });
  const nurseArr = (await Promise.all(nursePromises)) as WithId<Worker>[];
  return nurseArr.reduce((acc: { [nurseId: string]: WithId<Worker> }, nurse) => ({ ...acc, [nurse.id]: nurse }), {});
};

export const getTimesheetActionUsersFromShifts = async (shifts: Shift[]) => {
  const userIds = new Set<string>();
  shifts.forEach((shift) => {
    if (shift.timesheetActionMadeBy) {
      userIds.add(shift.timesheetActionMadeBy);
    }
  });
  const userPromises = [...userIds].map((id: string) => {
    return new Promise((resolve) => {
      const getuserData = async () => {
        const user = await getWorker(id);
        return {
          ...user.data(),
          id: user.id,
        };
      };
      getuserData().then((userData) => resolve(userData));
    });
  });
  const userArr = (await Promise.all(userPromises)) as WithId<Worker>[];
  return userArr.reduce((acc: { [userId: string]: WithId<Worker> }, user) => ({ ...acc, [user.id]: user }), {});
};
