import { useEffect } from 'react';
import PasswordResetForm from '../PasswordResetForm';
import withAuthSplit from '../../../hoc/withAuthSplit';

import bgImg from '../../../assets/img/generic/20.jpg';

interface PasswordResetProps {
  setBgProps: (props: { image: string }) => void;
}

const PasswordReset = ({ setBgProps }: PasswordResetProps) => {
  useEffect(() => setBgProps({ image: bgImg }), [setBgProps]);

  return (
    <div className="text-center">
      <h3>Reset password</h3>
      <PasswordResetForm layout="split" hasLabel />
    </div>
  );
};

export default withAuthSplit(PasswordReset);
