import { Dispatch } from 'redux';
import { Collection, CompanyProfile } from 'src/types';
import { db } from '../../firebase';

import {
  FETCH_COMPANY_PROFILE_REQUEST,
  FETCH_COMPANY_PROFILE_SUCCESS,
  FETCH_COMPANY_PROFILE_FAIL,
} from '../constants/userConstants';

export const fetchCompanyProfile = (uid: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: FETCH_COMPANY_PROFILE_REQUEST,
  });

  try {
    const snap = await db.collection(Collection.COMPANIES).where('ownerId', '==', uid).get();
    const {
      companyName,
      companyAvatar,
      companyAddress,
      companyCity,
      companyState,
      companyPhoneNumber,
      companyPostalCode,
      companyIntro,
      status,
    } = snap.docs[0].data();

    const companyProfile = {
      companyName,
      companyAvatar,
      companyPhoneNumber,
      companyAddress,
      companyCity,
      companyState,
      companyPostalCode,
      companyIntro,
      status,
      id: snap.docs[0].id,
    } as CompanyProfile;

    dispatch({
      type: FETCH_COMPANY_PROFILE_SUCCESS,
      payload: companyProfile,
    });

    return companyProfile;
  } catch (error: any) {
    dispatch({
      type: FETCH_COMPANY_PROFILE_FAIL,
      error: error.message,
    });
    return;
  }
};
