import firebase from 'firebase';

import { Shift } from './shifts';

interface UserLocation {
  geohash: string;
  coordinates: firebase.firestore.GeoPoint;
  updatedAt: firebase.firestore.Timestamp;
}

export enum DocumentStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}

export enum UserStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface UserDocument {
  fileUrl: string;
  status: DocumentStatus;
}

export interface EmploymentInfo {
  name: string;
  socialSecurityNumber: string;
  address: string;
}

export enum NurseType {
  CAREGIVER = 'Caregiver',
  CNA = 'CNA',
  LPN = 'LPN',
  RN = 'RN',
}

export enum AdditionalCapabilities {
  MED_PASS = 'MeD Pass',
}

export enum UserType {
  FACILITY = 'facility',
  WORKER = 'worker',
  ADMIN = 'admin',
}

export enum AppUserType {
  FACILITY = 'facility',
  COMPANY = 'company',
}

export interface Worker {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  streetAddress: string;
  city: string;
  state: string;
  blocklistedBy?: string[];
  shifts: firebase.firestore.DocumentReference<Shift>[] | firebase.firestore.FieldValue;
  idImage?: UserDocument;
  nurseType?: NurseType;
  licenseImage?: UserDocument;
  tuberculosisTestImage?: UserDocument;
  cprCertificationImage?: UserDocument;
  employmentInfo?: EmploymentInfo;
  suspended?: boolean;
  additionalCapabilities?: AdditionalCapabilities[];
  status?: UserStatus;
  location?: UserLocation;
  token?: string;
  userType: UserType.WORKER;
}

export interface CompanyOwner {
  contactOptions: string;
  createdAt: firebase.firestore.Timestamp;
  companyOwnerOf: string;
  firstName: string;
  lastName: string;
  notifications: string;
  token: string;
  userType: UserType.FACILITY;
}
export interface FacilityAdminOwner {
  contactOptions: string;
  createdAt: firebase.firestore.Timestamp;
  facilityAdminOf: string;
  firstName: string;
  lastName: string;
  notifications: string;
  token: string;
  userType: UserType.FACILITY;
}

export interface AdminUser {
  createdAt: firebase.firestore.Timestamp;
  firstName: string;
  lastName: string;
  isAdmin: true;
  userType: UserType.ADMIN;
}
