import { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import ButtonIcon from '../common/ButtonIcon';
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useHistory } from 'react-router-dom';
import Flex from '../common/Flex';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../common/Loader';
import Badge from 'reactstrap/es/Badge';
import { getShifts, getShiftsAndEmpty } from '../../redux/actions/shiftActions';
import AddScheduleModal from '../calendar/AddScheduleModal';
import ExportButton from '../common/ExportButton';
import moment from 'moment-timezone';
import { AppUserType, Shift, ShiftStatus, WithId } from 'src/types';
import { firebase } from '../../firebase';
import { IconName } from '@fortawesome/fontawesome-common-types';

interface ShiftsTableActionsProps {
  shiftId: string;
  status: ShiftStatus | undefined;
}

const ShiftsTableActions = ({ shiftId, status }: ShiftsTableActionsProps) => {
  const history = useHistory();
  return (
    <UncontrolledDropdown>
      <DropdownToggle color="link" size="sm" className="text-600 btn-reveal">
        <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
      </DropdownToggle>
      {status !== 'cancelled' && (
        <DropdownMenu right className="border py-2">
          <DropdownItem onClick={() => history.push(`/shift-details/${shiftId}/edit`)}>Edit</DropdownItem>
          <DropdownItem className="text-danger" onClick={() => history.push(`/shift-details/${shiftId}/delete`)}>
            Cancel
          </DropdownItem>
        </DropdownMenu>
      )}
    </UncontrolledDropdown>
  );
};

const actionFormatter = (shiftId: string, row: Shift) => <ShiftsTableActions shiftId={shiftId} status={row.status} />;

const nameFormatter = (dataField: string, { id, description }: WithId<Shift>) => {
  return (
    <Link to={`/shift-details/${id}`}>
      <Media tag={Flex} align="center">
        <Media body className="ml-0">
          <h5 className="mb-0 fs--1">{description}</h5>
        </Media>
      </Media>
    </Link>
  );
};

const badgeFormatter = (isFree: boolean, row: Shift) => {
  let color = '';
  let icon: IconName | undefined;
  let text = 'Is free';
  switch (isFree) {
    case true:
      color = 'secondary';
      text = 'Unclaimed';
      break;
    case false:
      color = 'success';
      icon = 'check';
      text = 'Claimed';
      break;
    default:
      color = 'warning';
      icon = 'ban';
      text = 'cancelled';
  }
  if (row.status === 'cancelled') {
    color = 'warning';
    icon = 'ban';
    text = 'Cancelled';
  }
  return (
    <Badge color={`soft-${color}`} className="rounded-capsule">
      {text}
      {icon && <FontAwesomeIcon icon={icon} transform="shrink-2" className="ml-1" />}
    </Badge>
  );
};

const sNoFormatter = (field: any, shift: Shift, index: number) => <span>{index + 1}</span>;

const ShiftsTable = ({ showLoadMore = true }) => {
  const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(false);
  // @ts-ignore
  const { shifts, loading, hasMore } = useSelector((state) => state.shift);
  // @ts-ignore
  const { facilities } = useSelector((state) => state.facility);
  // @ts-ignore
  const { facilityProfile } = useSelector((state) => state.facilityprofile);
  // @ts-ignore
  const { companyProfile } = useSelector((state) => state.companyprofile);
  // @ts-ignore
  const { userType } = useSelector((state) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getShiftsAndEmpty());
  }, [dispatch, facilities, userType, facilityProfile, companyProfile]);

  const tz = !userType ? null : userType === AppUserType.FACILITY ? facilityProfile?.tz || null : null;

  const timeFormatter = (time: firebase.firestore.Timestamp | undefined) => (
    <span>{tz ? moment(time?.toDate()).tz(tz).format('MM/DD/YYYY h:mm A') : time?.toDate().toLocaleString()}</span>
  );

  const columns = [
    {
      dataField: '',
      text: '#',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      formatter: sNoFormatter,
      sort: true,
    },
    {
      dataField: 'description',

      text: 'Description',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      formatter: nameFormatter,
      sort: true,
    },
    {
      dataField: 'start',
      headerClasses: 'border-0',
      text: 'Start',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      align: 'right',
      formatter: timeFormatter,
      headerAlign: 'right',
    },
    {
      dataField: 'end',
      headerClasses: 'border-0',
      text: 'End',
      classes: 'border-0 py-2 align-middle',
      formatter: timeFormatter,
      sort: true,
      align: 'right',
      headerAlign: 'right',
    },
    {
      dataField: 'isFree',

      text: 'Status',
      formatter: badgeFormatter,
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      align: 'center',
      headerAlign: 'center',
    },
    {
      dataField: 'numberOfNurse',

      text: 'Number of nurse',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      align: 'center',
    },
    {
      dataField: 'additionalnotes',

      text: 'Additional notes',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true,
    },
    {
      dataField: 'id',
      text: '',
      headerClasses: 'border-0',
      formatter: actionFormatter,
      classes: 'border-0 py-2 align-right',
      align: 'right',
    },
  ];

  return (
    <>
      <Card className="mb-3">
        <FalconCardHeader title="Shifts" light={false}>
          <>
            <>
              <div style={{ marginLeft: '5px', marginRight: '5px', display: 'inline-block' }}>
                <ButtonIcon
                  onClick={() => setIsOpenScheduleModal(true)}
                  icon="plus"
                  transform="shrink-3 down-2"
                  color="falcon-default"
                  size="sm"
                >
                  New
                </ButtonIcon>
              </div>
              <div style={{ marginLeft: '5px', display: 'inline-block' }}>
                <ExportButton />
              </div>
            </>
          </>
        </FalconCardHeader>

        {shifts?.length > 0 ? (
          <CardBody className="p-0">
            <>
              <div className="table-responsive">
                {loading ? (
                  <Loader />
                ) : (
                  <BootstrapTable
                    bootstrap4
                    keyField="id"
                    data={showLoadMore ? shifts : shifts.slice(0, 10)}
                    columns={columns}
                    bordered={false}
                    classes="table-dashboard table-striped table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                    rowClasses="btn-reveal-trigger border-top border-200"
                    headerClasses="bg-200 text-900 border-y border-200"
                  />
                )}
              </div>
              <Row noGutters className="px-1 py-3  d-flex justify-content-center">
                <Col xs="auto" className="pr-3">
                  {showLoadMore ? (
                    <Button
                      size="sm"
                      onClick={() => {
                        dispatch(getShifts());
                      }}
                      className="px-4 ml-2 btn-light"
                      disabled={!hasMore || loading}
                    >
                      {loading ? 'Loading...' : hasMore ? 'Load More' : 'No more items'}
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      onClick={() => {
                        history.push('/shifts');
                      }}
                      className="px-4 ml-2 btn-light"
                    >
                      View All
                    </Button>
                  )}
                </Col>
              </Row>
            </>
          </CardBody>
        ) : loading ? (
          <Loader />
        ) : null}
      </Card>
      <AddScheduleModal
        userType={userType}
        facilities={facilities}
        isOpenScheduleModal={isOpenScheduleModal}
        setIsOpenScheduleModal={setIsOpenScheduleModal}
        initialFacilityId={facilities.length > 0 ? facilities[0].id : null}
      />
    </>
  );
};

export default ShiftsTable;
