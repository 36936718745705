import firebase from 'firebase';
import { Collection, Facility, PastReview, Review, ReviewShift, Shift, WithId, WithNurseId, Worker } from 'src/types';
import { getShift } from './shifts';
import { getWorker } from './worker';

const reviewsCollection = firebase.firestore().collection(Collection.REVIEWS);
const shiftsCollection = firebase.firestore().collection(Collection.SHIFTS);

export const getReviewByShiftId = async (shiftId: string) => {
  const snap = await reviewsCollection.doc(shiftId).get();
  if (!snap.data()) {
    return null;
  }
  return { ...snap.data(), id: snap.id };
};

export const updateReview = async (review: Partial<Review>, shiftId: string) => {
  await reviewsCollection.doc(shiftId).update(review);
};

export const createReview = async (review: Review, shiftId: string) => {
  await reviewsCollection.doc(shiftId).set({ isTest: false, ...review });
};

export const getReviewsByFacilityIdentifier = async (facilityIdentifier: string): Promise<WithId<Review>[]> => {
  const snap = await reviewsCollection.where('facilityIdentifier', '==', facilityIdentifier).get();
  return snap.docs.map((doc) => {
    const review = doc.data();
    return { id: doc.id, ...(review as Review) };
  });
};

export const getReviewsToFillOut = async (facilityIdentifier: string): Promise<Array<ReviewShift>> => {
  const threeDaysAgo = new Date(Date.now() - 3 * 24 * 60 * 60 * 1000);
  const shiftsSnap = await shiftsCollection
    .where('facilityIdentifier', '==', facilityIdentifier)
    .where('clockOut', '>', threeDaysAgo)
    .where('clockOut', '<', new Date())
    .orderBy('clockOut', 'desc') // Get reviews for most recently finished shifts first
    .get();
  const reviews = await getReviewsByFacilityIdentifier(facilityIdentifier);
  const submittedReviewMap = reviews.reduce<{
    [reviewId: string]: WithId<Review>;
  }>((acc, review) => {
    if (review.responses) {
      acc[review.id] = review;
    }
    return acc;
  }, {});
  const eligibleShifts: WithNurseId<WithId<Shift>>[] = [];
  const nurseIds: string[] = [];
  shiftsSnap.docs.forEach((shiftSnap) => {
    const data = shiftSnap.data() as Shift;
    if (!data.nurseId || data.status === 'cancelled') {
      return; // skip if the shift wasn't filled
    }
    const dataWithNurseId = data as WithNurseId<Shift>;
    const id = shiftSnap.id;
    const shift = { id, ...dataWithNurseId };
    const review = submittedReviewMap[id];
    if (!review) {
      eligibleShifts.push(shift);
      if (shift.nurseId) {
        nurseIds.push(shift.nurseId);
      }
    }
  });
  const nursePromises = nurseIds.map((id) => {
    return new Promise((resolve) => {
      const getNurseData = async () => {
        const nurse = await getWorker(id);
        return {
          ...nurse.data(),
          id: nurse.id,
        };
      };
      getNurseData().then((nurseData) => resolve(nurseData));
    });
  });
  const nurseArr = (await Promise.all(nursePromises)) as WithId<Worker>[];
  const nurses = nurseArr.reduce(
    (acc: { [nurseId: string]: WithId<Worker> }, nurse) => ({ ...acc, [nurse.id]: nurse }),
    {}
  );
  return eligibleShifts.map((shift) => ({
    ...shift,
    nurseFullName: `${nurses[shift.nurseId].firstName.trim()} ${nurses[shift.nurseId].lastName.trim()}`,
  }));
};

export const getReviewsToFillOutByCompany = async (facilities: WithId<Facility>[]): Promise<Array<ReviewShift>> => {
  const reviewsToFillOut = await Promise.all(
    facilities.map(
      (facility): Promise<Array<ReviewShift>> =>
        new Promise((resolve) => {
          getReviewsToFillOut(facility.id).then((eligibleReviews) => resolve(eligibleReviews));
        })
    )
  );
  return reviewsToFillOut.flat();
};

export const getPastReviews = async (facilityIdentifier: string): Promise<WithId<PastReview>[]> => {
  const snap = await reviewsCollection
    .where('facilityIdentifier', '==', facilityIdentifier)
    .orderBy('submittedAt', 'desc')
    .get();
  const nurseIds = new Set<string>();
  const shiftIds = new Set<string>();
  const reviews = snap.docs.map((doc) => {
    const review = doc.data();
    nurseIds.add(review.nurseId);
    shiftIds.add(doc.id);
    return { id: doc.id, ...(review as Review) };
  });
  const nursePromises = [...nurseIds].map((id) => {
    return new Promise((resolve) => {
      const getNurseData = async () => {
        const nurse = await getWorker(id);
        return {
          ...nurse.data(),
          id: nurse.id,
        };
      };
      getNurseData().then((nurseData) => resolve(nurseData));
    });
  });
  const nurseArr = (await Promise.all(nursePromises)) as WithId<Worker>[];
  const nurses = nurseArr.reduce(
    (acc: { [nurseId: string]: WithId<Worker> }, nurse) => ({ ...acc, [nurse.id]: nurse }),
    {}
  );

  const shiftPromises = [...shiftIds].map((id) => {
    return new Promise((resolve) => {
      const getShiftData = async () => {
        const shift = await getShift(id);
        return {
          ...shift.data(),
          id: shift.id,
        };
      };
      getShiftData().then((shiftData) => resolve(shiftData));
    });
  });
  const shiftArr = (await Promise.all(shiftPromises)) as WithId<Shift>[];
  const shifts = shiftArr.reduce(
    (acc: { [shiftId: string]: WithId<Shift> }, shift) => ({ ...acc, [shift.id]: shift }),
    {}
  );

  return reviews
    .map((review) => ({
      ...review,
      nurseFullName: review.nurseId
        ? `${nurses[review.nurseId].firstName.trim()} ${nurses[review.nurseId].lastName.trim()}`
        : '',
      description: shifts[review.id].description,
      start: shifts[review.id].start,
      end: shifts[review.id].end,
    }))
    .sort((a, b) => (a.start.toDate() < b.start.toDate() ? 1 : -1));
};

export const getPastReviewsByCompany = async (facilities: WithId<Facility>[]): Promise<Array<WithId<PastReview>>> => {
  const pastReviews = await Promise.all(
    facilities.map(
      (facility): Promise<Array<WithId<PastReview>>> =>
        new Promise((resolve) => {
          getPastReviews(facility.id).then((eligibleReviews) => resolve(eligibleReviews));
        })
    )
  );
  return pastReviews.flat();
};
