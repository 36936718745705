import React from 'react';
import classNames from 'classnames';
import { Button, ButtonProps } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-common-types';

type ButtonIconProps = {
  icon: IconName;
  iconAlign?: string;
  iconClassName?: string;
  transform: string;
  children: React.ReactNode;
  color: string;
} & ButtonProps;

const ButtonIcon = ({ icon, iconAlign = 'left', iconClassName, transform, children, ...rest }: ButtonIconProps) => (
  <Button {...rest}>
    {iconAlign === 'right' && children}
    <FontAwesomeIcon
      icon={icon}
      className={classNames(iconClassName, {
        'mr-1': children && iconAlign === 'left',
        'ml-1': children && iconAlign === 'right',
      })}
      transform={transform}
    />
    {iconAlign === 'left' && children}
  </Button>
);

export default ButtonIcon;
