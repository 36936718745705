import { Parser } from 'json2csv';
import { useState } from 'react';
import { db } from '../../firebase';
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ButtonIcon from './ButtonIcon';
import Datetime from 'react-datetime';
import { AppUserType, Collection, Facility, Shift, Worker } from 'src/types';
import { getAllWorkers } from '../../api/worker';
import { getAllFacilities } from '../../api/facilities';
import useUserId from 'src/hooks/useUserId';
import useAppSelector from 'src/hooks/useAppSelector';
import { Moment } from 'moment';

type DecoratedShift = Shift & {
  id: string;
  startTime: string;
  endTime: string;
  nurseName?: string;
  facilityName?: string;
};

interface DateSelectModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

function DateSelectModal({ open, setOpen }: DateSelectModalProps) {
  const toggle = () => setOpen(!open);

  const [endDate, setEndDate] = useState<Moment | undefined>();
  const [startDate, setStartDate] = useState<Moment | undefined>();
  const { userType } = useAppSelector((state) => state.user);
  const { facilities } = useAppSelector((state) => state.facility);
  const facilityIds = facilities.map((facility) => facility.id);
  const userId = useUserId();

  async function downloadReport() {
    let shiftsRef = db.collection(Collection.SHIFTS).where(`facilityIdentifier`, `==`, userId);
    if (userType === AppUserType.COMPANY) {
      shiftsRef = db.collection(Collection.SHIFTS).where(`facilityIdentifier`, `in`, facilityIds);
    }
    const snaps = await shiftsRef
      .where('start', '>=', startDate?.toDate())
      .where('start', '<=', endDate?.toDate())
      .get();
    const workerSnaps = await getAllWorkers();
    const workerData: { [id: string]: Worker } = {};
    workerSnaps.docs.forEach((doc) => {
      workerData[doc.id as string] = doc.data() as Worker;
    });
    const facilitySnaps = await getAllFacilities();
    const facilityData: { [id: string]: Facility } = {};
    facilitySnaps.docs.forEach((doc) => {
      facilityData[doc.id as string] = doc.data() as Facility;
    });
    const data: DecoratedShift[] = [];
    snaps.docs.forEach((doc) => {
      const d = doc.data() as Shift;
      const newData: DecoratedShift = {
        ...d,
        id: doc.id,
        startTime: d.start?.toDate().toLocaleString(),
        endTime: d.end?.toDate().toLocaleString(),
      };
      if (d.nurseId) {
        const user = workerData[d.nurseId];
        newData.nurseName = `${user.firstName} ${user.lastName}`;
      }
      if (d.facilityIdentifier) {
        newData.facilityName = facilityData[d.facilityIdentifier].facilityName;
      }
      data.push(newData);
    });
    if (data.length > 0) {
      const fields = [
        { label: 'Shift ID', value: 'id' },
        { label: 'Description', value: 'description' },
        { label: 'Is Free', value: (row: Shift) => (row.isFree ? 'Yes' : 'No') },
        { label: 'Start Time', value: 'startTime' },
        { label: 'End Time', value: 'endTime' },
        { label: 'Nurse Type', value: 'nurseType' },
        { label: 'Nurse Name', value: 'nurseName' },
        { label: 'Notes', value: 'additionalnotes' },
      ];
      if (userType === AppUserType.COMPANY) {
        fields.push({ label: 'Facility Name', value: 'facilityName' });
      }
      const parser = new Parser({
        fields,
      });
      save('report.csv', parser.parse(data));
    }
    toggle();
  }
  function save(filename: string, data: string) {
    const blob = new Blob([data], { type: 'text/csv' });
    // @ts-ignore
    if (typeof window.navigator.msSaveOrOpenBlob !== 'undefined') {
      // @ts-ignore
      window.navigator.msSaveBlob(blob, filename);
    } else {
      const elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  }

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>Filter Start Date between</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label className="fs-0" for="eventStart">
            From Date
          </Label>
          <Datetime
            timeFormat={true}
            value={startDate}
            onChange={(dateTime) => {
              if (typeof dateTime !== 'string' && dateTime.isValid()) {
                setStartDate(dateTime);
              }
            }}
            dateFormat="MM-DD-YYYY"
            inputProps={{ required: true, placeholder: 'MM-DD-YYYY H:M', id: 'eventStart' }}
          />
        </FormGroup>
        <FormGroup>
          <Label className="fs-0" for="eventStart">
            End Date
          </Label>
          <Datetime
            timeFormat={true}
            value={endDate}
            onChange={(dateTime) => {
              if (typeof dateTime !== 'string' && dateTime.isValid()) {
                setEndDate(dateTime);
              }
            }}
            dateFormat="MM-DD-YYYY"
            inputProps={{ required: true, placeholder: 'MM-DD-YYYY H:M', id: 'eventEnd' }}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => downloadReport()}>
          Download
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default function ExportButton() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <ButtonIcon
        onClick={() => {
          setIsOpen(true);
        }}
        icon="external-link-alt"
        transform="shrink-3 down-2"
        color="falcon-default"
        size="sm"
      >
        Export
      </ButtonIcon>
      <DateSelectModal open={isOpen} setOpen={setIsOpen} />
    </>
  );
}
