import firebase from 'firebase';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, CardBody, Form, FormGroup, Input, Label } from 'reactstrap';
import _ from 'lodash';
import Datetime from 'react-datetime';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';

import FalconCardHeader from './FalconCardHeader';
import { fetchFacilityProfile } from '../../redux/actions/fetchFacilityProfileAction';
import { updateFacilityById } from '../../api/facilities';
import useUserId from 'src/hooks/useUserId';
import { FacilityProfile, ShiftTemplate } from 'src/types';

interface MomentShiftTemplate {
  startTime: moment.Moment;
  endTime: moment.Moment;
  name: string;
}

interface EmptyShiftTemplate {
  startTime: undefined;
  endTime: undefined;
  name: undefined;
}

interface AddTemplateProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const AddTemplate = ({ onClick }: AddTemplateProps) => {
  return (
    <Button style={{ marginRight: '10px' }} onClick={onClick} className="px-4">
      Add new shift template
    </Button>
  );
};

interface FacilityShiftTemplateCardProps {
  facility: FacilityProfile;
}

const FacilityShiftTemplateCard = ({ facility }: FacilityShiftTemplateCardProps) => {
  const [shiftTemplates, setShiftTemplates] = useState<(MomentShiftTemplate | EmptyShiftTemplate)[]>([]);
  const userId = useUserId();
  const dispatch = useDispatch();

  const convertDBTemplates = useCallback(
    (dbTemplate: ShiftTemplate[]) => {
      return convertTz(dbTemplate || [], facility.tz, true);
    },
    [facility.tz]
  );

  useEffect(() => {
    if (facility.shiftTemplates) {
      const formattedShiftTemplates = convertDBTemplates(facility.shiftTemplates);
      setShiftTemplates(formattedShiftTemplates);
    }
  }, [convertDBTemplates, facility.shiftTemplates]);

  const convertToTimestamp = (shiftTemplates: MomentShiftTemplate[]): ShiftTemplate[] => {
    return shiftTemplates.map((template) => ({
      startTime: firebase.firestore.Timestamp.fromDate(template.startTime.toDate()),
      endTime: firebase.firestore.Timestamp.fromDate(template.endTime.toDate()),
      name: template.name,
    }));
  };

  const convertTz = (
    shiftTemplates: ShiftTemplate[] | MomentShiftTemplate[],
    tz: string | undefined,
    changeTime: boolean
  ): MomentShiftTemplate[] => {
    return shiftTemplates.map((template) => {
      const startTime = moment(template.startTime.toDate()).tz(tz || 'America/Chicago', !changeTime);
      const endTime = moment(template.endTime.toDate()).tz(tz || 'America/Chicago', !changeTime);
      return {
        startTime,
        endTime,
        name: template.name,
      };
    });
  };

  const handleSubmit = async () => {
    try {
      const isEmptyShiftTemplate = shiftTemplates.some((template) => template.name === undefined);
      if (isEmptyShiftTemplate) {
        throw new Error("Can't save empty shift template");
      }
      const nonEmptyShiftTemplates = shiftTemplates as MomentShiftTemplate[];
      await updateFacilityById(
        { shiftTemplates: convertToTimestamp(convertTz(nonEmptyShiftTemplates, facility.tz, false)) },
        facility.id
      );
      toast.success('Successfully updated shift templates');
      dispatch(fetchFacilityProfile({ userId }));
    } catch (e) {
      console.error(e);
      toast.error('Failed to save shift template changes');
    }
  };

  interface UpdateShiftTemplateStateInput {
    value: any;
    field: keyof MomentShiftTemplate;
    index: number;
  }

  const updateShiftTemplateState = ({ value, field, index }: UpdateShiftTemplateStateInput) => {
    const newShiftTemplates = _.cloneDeep(shiftTemplates);
    newShiftTemplates[index][field] = value;
    setShiftTemplates(newShiftTemplates);
  };

  const removeItem = (index: number) => {
    const newShiftTemplates = _.cloneDeep(shiftTemplates);
    newShiftTemplates.splice(index, 1);
    setShiftTemplates(newShiftTemplates);
  };

  const onAddTemplateClick = () => {
    const newShiftTemplates = _.cloneDeep(shiftTemplates);
    newShiftTemplates.push({
      name: undefined,
      endTime: undefined,
      startTime: undefined,
    });
    setShiftTemplates(newShiftTemplates);
  };

  const touched =
    !_.isEqual(shiftTemplates, convertDBTemplates(facility.shiftTemplates || [])) &&
    shiftTemplates.every((template) => {
      return template.name && template.endTime && template.startTime;
    });

  return (
    <Card className="mt-3">
      <FalconCardHeader title="Shift Templates">
        <div className="d-flex">
          <AddTemplate onClick={onAddTemplateClick} />
          <Button disabled={!touched} onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </FalconCardHeader>
      <CardBody>
        {!shiftTemplates.length && <p>{`Add a shift template in order to save time when creating shifts!`}</p>}
        {!!shiftTemplates.length && <p>{`All times are in facility timezone (${facility.tz})`}</p>}
        <Form onSubmit={handleSubmit}>
          {shiftTemplates.map(({ name, startTime, endTime }, index) => {
            return (
              <div
                key={index}
                style={{
                  borderRadius: '5px',
                  border: '1px solid #00000014',
                  padding: '10px',
                  marginTop: '10px',
                  marginBottom: '10px',
                  position: 'relative',
                  boxShadow: '0 7px 14px 0 rgb(59 65 94 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%)',
                }}
                className="bg-light"
              >
                <button
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    border: 'none',
                    background: 'none',
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    removeItem(index);
                  }}
                >
                  <FontAwesomeIcon icon="times" transform="shrink-4 down-1" className="mr-1" />
                </button>
                <FormGroup>
                  <Label>Name</Label>
                  <Input
                    value={name}
                    onChange={({ target }) => updateShiftTemplateState({ value: target.value, field: 'name', index })}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Start Time</Label>
                  <Datetime
                    timeFormat={true}
                    value={startTime}
                    onChange={(startTime) => {
                      if (typeof startTime !== 'string' && startTime.isValid()) {
                        updateShiftTemplateState({
                          value: startTime,
                          field: 'startTime',
                          index,
                        });
                      }
                    }}
                    dateFormat={false}
                    inputProps={{ placeholder: 'H:mm', id: 'startTime' }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>End Time</Label>
                  <Datetime
                    timeFormat={true}
                    value={endTime}
                    onChange={(endTime) => {
                      if (typeof endTime !== 'string' && endTime.isValid()) {
                        updateShiftTemplateState({
                          value: endTime,
                          field: 'endTime',
                          index,
                        });
                      }
                    }}
                    dateFormat={false}
                    inputProps={{ placeholder: 'H:mm', id: 'endTime' }}
                  />
                </FormGroup>
              </div>
            );
          })}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FacilityShiftTemplateCard;
