import { Card, CardBody, Media, Button } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import Flex from '../common/Flex';
import { firebase } from 'src/firebase';
import { AppUserType, PastReview, WithId } from 'src/types';
import useAppSelector from 'src/hooks/useAppSelector';
import { useEffect, useState } from 'react';
import { getPastReviews, getPastReviewsByCompany } from 'src/api/reviews';

interface ReviewsTableActionsProps {
  shiftId: string;
}

const ReviewsTableActions = ({ shiftId }: ReviewsTableActionsProps) => {
  return (
    <Media tag={Flex} align="right" style={{ marginLeft: '40px' }}>
      <Link target="_blank" rel="noopener noreferrer" to={`/shift-details/${shiftId}/review`}>
        <Button>View</Button>
      </Link>
    </Media>
  );
};

const actionFormatter = (shiftId: string) => <ReviewsTableActions shiftId={shiftId} />;

const nameFormatter = (dataField: string) => {
  return (
    <Media tag={Flex} align="center">
      <Media body className="ml-0">
        <h5 className="mb-0 fs--1">{dataField}</h5>
      </Media>
    </Media>
  );
};

const timeFormatter = (time: firebase.firestore.Timestamp | undefined) => (
  <span>{time?.toDate().toLocaleString()}</span>
);

const columns = [
  {
    dataField: 'nurseFullName',
    text: 'Nurse Name',
    headerClasses: 'border-0',
    classes: 'border-0 py-2 align-middle',
    formatter: nameFormatter,
  },
  {
    dataField: 'rating',
    text: 'Rating',
    headerClasses: 'border-0',
    classes: 'border-0 py-2 align-middle',
  },
  {
    dataField: 'description',
    text: 'Description',
    headerClasses: 'border-0',
    classes: 'border-0 py-2 align-middle',
    formatter: (description: string) => (
      <div
        className="text-truncate"
        style={{ width: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {description}
      </div>
    ),
  },
  {
    dataField: 'start',
    headerClasses: 'border-0',
    text: 'Start',
    classes: 'border-0 py-2 align-middle',
    align: 'right',
    formatter: timeFormatter,
    headerAlign: 'right',
  },
  {
    dataField: 'end',
    headerClasses: 'border-0',
    text: 'End',
    classes: 'border-0 py-2 align-middle',
    formatter: timeFormatter,
    align: 'right',
    headerAlign: 'right',
  },
  {
    dataField: 'id',
    text: '',
    headerClasses: 'border-0',
    formatter: actionFormatter,
    classes: 'border-0 py-2 align-middle',
    align: 'right',
  },
];

interface PastReviewsCardProps {
  pastReviews: WithId<PastReview>[];
}

const PastReviewsCard = ({ pastReviews }: PastReviewsCardProps) => {
  const formattedPastReviews = pastReviews.map(({ rating }) => ({
    rating,
  }));
  return (
    <>
      <Card className="mb-3">
        <FalconCardHeader title="Past Reviews" light={false} />
        <CardBody className="p-0">
          <>
            <div className="table-responsive">
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={pastReviews}
                columns={columns}
                bordered={false}
                classes="table-dashboard table-striped table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap past-review-table"
                rowClasses="btn-reveal-trigger border-top border-200"
                headerClasses="bg-200 text-900 border-y border-200"
              />
            </div>
          </>
        </CardBody>
      </Card>
    </>
  );
};

const PastReviewsCardWrapper = () => {
  const { userType } = useAppSelector((state) => state.user);
  const { facilities } = useAppSelector((state) => state.facility);
  const { facilityProfile } = useAppSelector((state) => state.facilityprofile);
  const [pastReviews, setPastReviews] = useState<WithId<PastReview>[]>([]);
  useEffect(() => {
    const getEligibleReviews = async () => {
      if (userType === AppUserType.COMPANY && facilities?.length) {
        const reviews = await getPastReviewsByCompany(facilities);
        setPastReviews(reviews);
      } else if (userType === AppUserType.FACILITY && facilityProfile?.id) {
        const reviews = await getPastReviews(facilityProfile?.id);
        setPastReviews(reviews);
      }
    };
    getEligibleReviews();
  }, [userType, facilities, facilityProfile?.id]);

  return <PastReviewsCard pastReviews={pastReviews} />;
};

export default PastReviewsCardWrapper;
