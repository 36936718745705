import { Facility, FacilityProfile } from 'src/types';
import {
  FETCH_FACILITY_PROFILE_REQUEST,
  FETCH_FACILITY_PROFILE_SUCCESS,
  FETCH_FACILITY_PROFILE_FAIL,
  CHANGE_TO_BLOCKLIST_SUCCESS,
} from '../constants/userConstants';

export interface UserFetchFacilityProfileActionReducerState {
  facilityProfile?: FacilityProfile;
  loading?: boolean;
  error?: string;
}

export interface UserFetchFacilityProfileActionReducerAction {
  type:
    | typeof FETCH_FACILITY_PROFILE_REQUEST
    | typeof FETCH_FACILITY_PROFILE_SUCCESS
    | typeof FETCH_FACILITY_PROFILE_FAIL
    | typeof CHANGE_TO_BLOCKLIST_SUCCESS;
  payload: FacilityProfile | Facility['blocklist'];
}

export const userFetchFacilityProfileActionReducer = (
  state: UserFetchFacilityProfileActionReducerState = {},
  action: UserFetchFacilityProfileActionReducerAction
): UserFetchFacilityProfileActionReducerState => {
  switch (action.type) {
    case FETCH_FACILITY_PROFILE_REQUEST:
      return { loading: true };
    case FETCH_FACILITY_PROFILE_SUCCESS:
      return {
        loading: false,
        facilityProfile: action.payload as FacilityProfile,
      };
    case FETCH_FACILITY_PROFILE_FAIL:
      return { ...state, loading: false, error: 'An error happened' };
    case CHANGE_TO_BLOCKLIST_SUCCESS:
      return {
        facilityProfile: {
          ...(state.facilityProfile as FacilityProfile),
          facilityBlockList: action.payload as Facility['blocklist'],
        },
      };
    default:
      return state;
  }
};
