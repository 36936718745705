import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { auth } from '../../firebase';
import { USER_LOGOUT } from '../constants/userConstants';
import { AppDispatch, RootState } from '../store/store';

// The logout action
export const startLogout = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch: AppDispatch) => {
  await auth.signOut();

  localStorage.removeItem('secretUserId');
  dispatch(logout());
};

export const logout = () => ({
  type: USER_LOGOUT,
});
