import {
  USER_FACEBOOK_LOGIN_FAIL,
  USER_FACEBOOK_LOGIN_REQUEST,
  USER_FACEBOOK_LOGIN_SUCCESS,
  USER_LOGOUT,
} from '../constants/userConstants';

export interface UserFacebookLoginReducerState {
  loading?: boolean;
  uid?: string;
  email?: string;
  name?: string;
  error?: any;
}

export interface UserFacebookLoginReducerActionPayload {
  uid: string;
  email: string;
  displayName: string;
  error?: any;
}
export interface UserFacebookLoginReducerAction {
  type:
    | typeof USER_FACEBOOK_LOGIN_SUCCESS
    | typeof USER_LOGOUT
    | typeof USER_FACEBOOK_LOGIN_FAIL
    | typeof USER_FACEBOOK_LOGIN_REQUEST;
  payload: UserFacebookLoginReducerActionPayload;
}

export const userFacebookLoginReducer = (
  state: UserFacebookLoginReducerState = {},
  action: UserFacebookLoginReducerAction
): UserFacebookLoginReducerState => {
  switch (action.type) {
    case USER_FACEBOOK_LOGIN_REQUEST:
      return { loading: true };
    case USER_FACEBOOK_LOGIN_SUCCESS:
      return {
        loading: false,
        uid: action.payload.uid,
        email: action.payload.email,
        name: action.payload.displayName,
      };
    case USER_FACEBOOK_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};
