import React from 'react';
import classNames from 'classnames';
import { Container } from 'reactstrap';
import Background from './Background';

interface SectionProps {
  fluid?: boolean;
  bg?: string;
  image?: string | undefined;
  overlay?: boolean | string;
  position?:
    | string
    | {
        x: string;
        y: string;
      };
  video?: any[];
  bgClassName?: string;
  className?: string;
  children?: React.ReactNode;
}

const Section = ({
  fluid = false,
  bg,
  image,
  overlay,
  position,
  video,
  bgClassName,
  className,
  children,
  ...rest
}: SectionProps) => {
  const bgProps = { image, overlay, position, video, className: '' };
  bgClassName && (bgProps.className = bgClassName);

  return (
    <section className={classNames({ [`bg-${bg}`]: bg }, className)} {...rest}>
      {image && <Background {...bgProps} image={image} />}
      <Container fluid={fluid}>{children}</Container>
    </section>
  );
};

export default Section;
