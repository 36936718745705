import React, { useState } from 'react';
import { Card, CardBody, Button } from 'reactstrap';
import infoBackgroundImage from '../../assets/img/illustrations/corner-2.png';

interface AddFirstCardProps {
  buttonText: string;
  children: (
    isOpenCreateFacilityModal: boolean,
    setIsOpenCreateFacilityModal: (isOpenCreateFacilityModal: boolean) => void
  ) => React.ReactNode;
}

const AddFirstCard = ({ buttonText, children }: AddFirstCardProps) => {
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  return (
    <Card
      className="mb-3"
      style={{
        minWidth: '12rem',
        height: '300px',
      }}
    >
      <CardBody
        className="bg-holder"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: `url(${infoBackgroundImage})`,
        }}
      >
        <Button
          onClick={() => setIsOpenCreateModal(true)}
          transform="shrink-3 down-2"
          color="primary"
          size="lg"
          style={{ cursor: 'pointer' }}
        >
          {buttonText}
        </Button>
      </CardBody>
      {children(isOpenCreateModal, setIsOpenCreateModal)}
    </Card>
  );
};

export default AddFirstCard;
