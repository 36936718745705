import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Flex from '../../common/Flex';
import RegistrationForm from '../RegistrationForm';
import withAuthSplit from '../../../hoc/withAuthSplit';

import bgImg from '../../../assets/img/generic/15.jpg';

interface RegistrationProps {
  setBgProps: (props: { image: string }) => void;
}

const Registration = ({ setBgProps }: RegistrationProps) => {
  useEffect(() => setBgProps({ image: bgImg }), [setBgProps]);

  return (
    <>
      <Flex align="center" justify="between">
        <h3>Register</h3>
        <p className="mb-0 fs--1">
          <span className="font-weight-semi-bold">Already User? </span>
          <Link to="/authentication/split/login">Login</Link>
        </p>
      </Flex>
      <RegistrationForm hasLabel />
    </>
  );
};

export default withAuthSplit(Registration);
