import { BlocklistEvent, FacilityStatus, PremierWorker, ShiftTemplate } from 'src/types';
import { AdditionalRequirements } from 'src/types/shifts';
import { createFacility } from '../../api/facilities';
import { firebase } from '../../firebase';
import { AppDispatch } from '../store/store';
import { getFacilities } from './facilityActions';

interface StartAddFacilityInput {
  additionalRequirements?: AdditionalRequirements[];
  blocklist?: string[];
  premierlist?: PremierWorker[];
  blocklistEvents?: BlocklistEvent[];
  companyId?: string;
  facilityAddress: string;
  facilityCity: string;
  facilityName: string;
  facilityPhoneNumber: string;
  facilityPostalCode: string;
  facilityState: string;
  notificationEmails?: string[];
  ownerId?: string;
  isTest?: boolean;
  shiftTemplates?: Array<ShiftTemplate>;
}

export const startAddFacility = (newFacility: StartAddFacilityInput) => async (dispatch: AppDispatch) => {
  try {
    await createFacility({
      isTest: false,
      ...newFacility,
      facilityIntro: 'About this Facility',
      notifications: 'all-notifications',
      contactOptions: 'email-option',
      contactInfoVisibility: true,
      allowedPositions: [],
      createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
      status: FacilityStatus.ACTIVE,
    });
    const { companyId } = newFacility;
    if (companyId) {
      await dispatch<any>(getFacilities({ companyId }));
    }
  } catch (e) {
    console.error(e);
  }
  return;
};
