import { Card, CardBody, Media, Button } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import Flex from '../common/Flex';
import { firebase } from 'src/firebase';
import { ReviewShift } from 'src/types';

interface ReviewsTableActionsProps {
  shiftId: string;
}

const ReviewsTableActions = ({ shiftId }: ReviewsTableActionsProps) => {
  return (
    <Media tag={Flex} align="right" style={{ marginLeft: '40px' }}>
      <Link target="_blank" rel="noopener noreferrer" to={`/shift-details/${shiftId}/review`}>
        <Button>Review</Button>
      </Link>
    </Media>
  );
};

const actionFormatter = (shiftId: string) => <ReviewsTableActions shiftId={shiftId} />;

const nameFormatter = (dataField: string) => {
  return (
    <Media tag={Flex} align="center">
      <Media body className="ml-0">
        <h5 className="mb-0 fs--1">{dataField}</h5>
      </Media>
    </Media>
  );
};

const timeFormatter = (time: firebase.firestore.Timestamp | undefined) => (
  <span>{time?.toDate().toLocaleString()}</span>
);

const columns = [
  {
    dataField: 'nurseFullName',
    text: 'Nurse Name',
    headerClasses: 'border-0',
    classes: 'border-0 py-2 align-middle',
    formatter: nameFormatter,
  },
  {
    dataField: 'description',
    text: 'Description',
    headerClasses: 'border-0',
    classes: 'border-0 py-2 align-middle',
  },
  {
    dataField: 'start',
    headerClasses: 'border-0',
    text: 'Start',
    classes: 'border-0 py-2 align-middle',
    align: 'right',
    formatter: timeFormatter,
    headerAlign: 'right',
  },
  {
    dataField: 'end',
    headerClasses: 'border-0',
    text: 'End',
    classes: 'border-0 py-2 align-middle',
    formatter: timeFormatter,
    align: 'right',
    headerAlign: 'right',
  },
  {
    dataField: 'id',
    text: '',
    headerClasses: 'border-0',
    formatter: actionFormatter,
    classes: 'border-0 py-2 align-middle',
    align: 'right',
  },
];

interface ReviewsToFillOutCardProps {
  reviewsToFillOut: ReviewShift[];
}

const ReviewsToFillOutCard = ({ reviewsToFillOut }: ReviewsToFillOutCardProps) => {
  const plural = reviewsToFillOut.length === 1 ? '' : 's';
  return (
    <>
      <Card className="mb-3">
        <FalconCardHeader title="Reviews to complete" light={false} />
        <p style={{ paddingLeft: '20px', fontSize: '14px' }}>
          {`You have ${reviewsToFillOut.length} review${plural} left to complete`}
        </p>
        <CardBody className="p-0">
          <>
            <div className="table-responsive">
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={reviewsToFillOut}
                columns={columns}
                bordered={false}
                classes="table-dashboard table-striped table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                rowClasses="btn-reveal-trigger border-top border-200"
                headerClasses="bg-200 text-900 border-y border-200"
              />
            </div>
          </>
        </CardBody>
      </Card>
    </>
  );
};

export default ReviewsToFillOutCard;
