import { useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import Badge from 'reactstrap/es/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from 'reactstrap';
import { getInvoices } from '../../redux/actions/invoiceActions';
import moment from 'moment';
import PurchasesTableActions from './PurchasesTableActions';
import { useHistory } from 'react-router';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useUserId from 'src/hooks/useUserId';
import useAppSelector from 'src/hooks/useAppSelector';
import useAppDispatch from 'src/hooks/useAppDispatch';

const actionFormatter = (_: any, invoice: any) => <PurchasesTableActions invoice={invoice} />;

const badgeFormatter = (status: string) => {
  let color = '';
  let icon: IconProp | undefined;
  switch (status) {
    case 'paid':
      color = 'success';
      icon = 'check';
      break;
    case 'open':
      color = 'secondary';
      icon = 'ban';
      break;
    default:
      color = 'warning';
      icon = 'stream';
  }
  return (
    <Badge color={`soft-${color}`} className="rounded-capsule text-capitalize">
      {status}
      {icon && <FontAwesomeIcon icon={icon} transform="shrink-2" className="ml-1" />}
    </Badge>
  );
};

const amountFormatter = (amount: number) => <>${amount / 100}</>;

const dateFormatter = (date: number) => <>{moment.unix(date).toLocaleString()}</>;

const columns = [
  {
    dataField: 'invoice.number',
    text: 'Invoice No',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
  },
  {
    dataField: 'shiftId',
    text: 'Shift',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
  },
  {
    dataField: 'invoice.created',
    formatter: dateFormatter,
    text: 'Invoice Date',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
  },
  {
    dataField: 'invoice.due_date',
    text: 'Due Date',
    classes: 'border-0 align-middle',
    formatter: dateFormatter,
    headerClasses: 'border-0',
  },
  {
    dataField: 'invoice.status',
    text: 'Status',
    formatter: badgeFormatter,
    classes: 'border-0 align-middle fs-0',
    headerClasses: 'border-0',
  },
  {
    dataField: 'invoice.amount_due',
    text: 'Amount',
    formatter: amountFormatter,
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    align: 'right',
    headerAlign: 'right',
  },
  {
    dataField: 'actions',
    isDummyField: true,
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    text: '',
    formatter: actionFormatter,
  },
];

interface PurchasesTableProps {
  showLoadMore?: boolean;
}

const PurchasesTable = ({ showLoadMore = true }: PurchasesTableProps) => {
  const { invoices, loading, hasMore } = useAppSelector((state) => state.invoice);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const userId = useUserId();
  useEffect(() => {
    dispatch<any>(getInvoices(userId));
  }, [dispatch]);

  return (
    <>
      <div className="table-responsive">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={showLoadMore ? invoices : invoices.slice(0, 10)}
          columns={columns}
          bordered={false}
          classes="table-dashboard table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
          rowClasses="btn-reveal-trigger border-top border-200"
          headerClasses="bg-200 text-900 border-y border-200"
        />
      </div>
      <Row noGutters className="px-1 py-3  d-flex justify-content-center">
        <Col xs="auto" className="pr-3">
          {showLoadMore ? (
            <Button
              size="sm"
              onClick={() => {
                dispatch<any>(getInvoices(userId));
              }}
              className="px-4 ml-2 btn-light"
              disabled={!hasMore || loading}
            >
              {loading ? 'Loading...' : hasMore ? 'Load More' : 'No more items'}
            </Button>
          ) : (
            <Button
              size="sm"
              onClick={() => {
                history.push('/invoices');
              }}
              className="px-4 ml-2 btn-light"
            >
              View All
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};

export default PurchasesTable;
