import { EDIT_SHIFT_REQUEST, EDIT_SHIFT_SUCCESS, EDIT_SHIFT_FAIL } from '../constants/eventConstants';
import { USER_LOGOUT } from '../constants/userConstants';

export interface ShiftEditReducerState {
  loading: boolean;
  error?: any;
}
export interface ShiftEditReducerAction {
  type: typeof EDIT_SHIFT_REQUEST | typeof EDIT_SHIFT_SUCCESS | typeof EDIT_SHIFT_FAIL | typeof USER_LOGOUT;
  payload: any;
}

export const shiftEditReducer = (
  state: ShiftEditReducerState = {
    loading: false,
  },
  action: ShiftEditReducerAction
): ShiftEditReducerState => {
  switch (action.type) {
    case EDIT_SHIFT_REQUEST:
      return {
        loading: true,
      };
    case EDIT_SHIFT_SUCCESS:
      return {
        loading: false,
      };
    case EDIT_SHIFT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_LOGOUT:
      return { loading: false };
    default:
      return state;
  }
};
