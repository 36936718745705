import { sleep } from 'src/helpers/utils';
import { AdditionalRequirements, Collection, NurseType } from 'src/types';
import { db, auth } from '../../firebase';
import { getShiftsAndEmpty } from './shiftActions';

// The Add Shift action
export const startAddShift = (newShift) => async (dispatch) => {
  // eslint-disable-next-line
  const batch = db.batch();
  const noOfNurse = Number(newShift.numberOfNurse);
  if ([NurseType.RN, NurseType.LPN].includes(newShift.nurseType)) {
    newShift.additionalRequirements = [AdditionalRequirements.MED_PASS];
  }
  for (let i = 0; i < noOfNurse; i++) {
    const shiftsRef = db.collection(Collection.SHIFTS).doc();
    batch.set(shiftsRef, {
      isTest: process.env.NODE_ENV === 'development',
      ...newShift,
      numberOfNurse: 1,
      createdAt: new Date().valueOf(),
      createdBy: auth.currentUser?.uid,
    });
  }
  try {
    await batch.commit();
    await sleep(1000);
    dispatch(getShiftsAndEmpty());
  } catch (e) {
    console.error(e);
    throw e;
  }
  return;
};
