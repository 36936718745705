import { useState, useEffect, CSSProperties } from 'react';
import { Button, Form, FormGroup, Input, Label, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { AppUserType } from 'src/types';

const containerStyle = { padding: 0 };
const rowStyle = { marginTop: '12px', marginBottom: '12px' };
const inputStyle: CSSProperties = {
  verticalAlign: '-2.5px',
  position: 'relative',
  marginRight: '7px',
  marginLeft: '0px',
};
const labelStyle = { fontSize: '16px' };

const ChooseForm = () => {
  const [isCompany, setIsCompany] = useState(false);
  const [isFacility, setIsFacility] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    setIsDisabled(!isCompany && !isFacility);
  }, [isCompany, isFacility]);
  let userType = AppUserType.FACILITY;
  if (isCompany) {
    userType = AppUserType.COMPANY;
  }

  return (
    <Container style={containerStyle}>
      <Form>
        <Row style={rowStyle}>
          <FormGroup check>
            <Label style={labelStyle} check>
              <Input
                style={inputStyle}
                checked={isFacility}
                type="radio"
                name="facility"
                onChange={({ target }) => {
                  setIsFacility(target.checked);
                  setIsCompany(!target.checked);
                }}
              />
              Owner or scheduler of a facility
            </Label>
          </FormGroup>
        </Row>
        <Row style={rowStyle}>
          <FormGroup check>
            <Label style={labelStyle} check>
              <Input
                type="radio"
                style={inputStyle}
                name="company"
                checked={isCompany}
                onChange={({ target }) => {
                  setIsCompany(target.checked);
                  setIsFacility(!target.checked);
                }}
              />
              Owner or scheduler of a company with multiple facilities
            </Label>
          </FormGroup>
        </Row>

        <FormGroup>
          <Link
            to={{
              pathname: '/authentication/basic/login',
              search: `?userType=${userType}`,
            }}
          >
            <Button color="primary" block className="mt-3" disabled={isDisabled}>
              Next
            </Button>
          </Link>
        </FormGroup>
      </Form>
    </Container>
  );
};

export default ChooseForm;
