import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardTitle, Form, FormGroup, Input, Label } from 'reactstrap';
import { Invite } from 'src/types';
import { getInviteData } from '../../api/invites';
import { startFacilityAdminSignUp } from '../../redux/actions/registerWithEmailAction';
import Loader from '../common/Loader';
import Logo from '../navbar/Logo';

const Required = () => <span style={{ color: 'red' }}>&nbsp;*</span>;

const InvitePage = () => {
  const [inviteData, setInviteData] = useState<Invite | undefined>();
  const [firstName, setFirstName] = useState('');
  const [password, setPassword] = useState('');
  const [userId, setUserId] = useState<string | undefined>();
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [lastName, setLastName] = useState('');
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [error, setError] = useState('');
  const { inviteId } = useParams<{ inviteId: string }>();
  const query = new URLSearchParams(useLocation().search);
  const facilityId = query.get('facilityId');
  const history = useHistory();
  const dispatch = useDispatch();

  const setState = useCallback(async () => {
    setPageLoading(true);
    if (!inviteId || !facilityId) {
      setError('Internal Error occurred. Please contact support or try again later');
    } else {
      const inviteData = await getInviteData(inviteId);
      if (!inviteData) {
        setError('Internal Error occurred. Please contact support or try again later');
      } else if (inviteData.userId) {
        setUserId(inviteData.userId);
      } else {
        setInviteData(inviteData);
        const { firstName, lastName } = inviteData;
        setFirstName(firstName);
        setLastName(lastName);
      }
    }
    setPageLoading(false);
  }, [facilityId, inviteId]);

  useEffect(() => {
    setState();
  }, [setState]);

  const createAccount = async () => {
    const errorMessages = [];
    if (password.length < 6) {
      errorMessages.push('Password must be at least 6 characters');
    }
    if (password !== passwordConfirmation) {
      errorMessages.push('Password confirmation does not match password');
    }
    if (errorMessages.length > 0) {
      toast.error(errorMessages.join('\n'));
      return;
    }
    setLoading(true);
    try {
      await dispatch(
        startFacilityAdminSignUp({
          facilityId,
          inviteId,
          userData: {
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            inviteId,
          },
          authUserData: {
            email: inviteData?.email,
            password,
          },
        })
      );
      history.replace('/');
    } catch (e) {
      console.error(e);
      toast.error('Failed to create account. Reach out to customer support or try again later');
    }
    setLoading(false);
  };

  if (error) {
    return <p>{error}</p>;
  }
  if (pageLoading) {
    return <Loader />;
  }
  if (userId) {
    // User was already created for this invite
    history.replace('/authentication/basic/login?userType=facility');
  }
  const formIsValid = firstName && lastName && password && passwordConfirmation;
  return inviteData ? (
    <div style={{ width: '750px', margin: 'auto', marginTop: '30px' }}>
      <Logo at="navbar-top" width={40} id="topLogo" />
      <div style={{ marginTop: '15px' }}>
        <Card>
          <CardBody>
            <CardTitle>{`Hello ${inviteData.firstName}! Welcome to PT Nurse!`}</CardTitle>
            <p>{`Complete the form below to become an admin for ${inviteData.facilityName}`}</p>
            <Form>
              <FormGroup>
                <Label>Email</Label>
                <Input disabled value={inviteData.email} />
              </FormGroup>
              <FormGroup>
                <Label>
                  First Name <Required />
                </Label>
                <Input value={firstName} onChange={(e) => setFirstName(e.target.value)} />
              </FormGroup>
              <FormGroup>
                <Label>
                  Last Name <Required />
                </Label>
                <Input value={lastName} onChange={(e) => setLastName(e.target.value)} />
              </FormGroup>
              <FormGroup>
                <Label>
                  Password <Required />
                </Label>
                <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
              </FormGroup>
              <FormGroup>
                <Label>
                  Confirm Password <Required />
                </Label>
                <Input
                  type="password"
                  value={passwordConfirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                />
              </FormGroup>
            </Form>
            <Button color="primary" onClick={createAccount} disabled={!formIsValid || loading}>
              Create Account
            </Button>
          </CardBody>
        </Card>
      </div>
    </div>
  ) : null;
};

export default InvitePage;
