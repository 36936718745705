import firebase from 'firebase';
import { NurseType } from './user';

export interface DashboardShift {
  additionalnotes?: Shift['additionalnotes'];
  additionalRequirements?: AdditionalRequirements[] | undefined;
  clockInTime?: string;
  clockOutTime?: string;
  description: string;
  end: firebase.firestore.Timestamp;
  endTime: string;
  facilityIdentifier: string;
  facilityName: string;
  hasShiftDiscontent: boolean;
  id: string;
  isFree: boolean;
  nurseId: string | undefined;
  startTime: string;
  status?: Shift['status'];
  numberOfNurse: number;
  nurseType: NurseType;
  start: firebase.firestore.Timestamp;
}

export enum AdditionalRequirements {
  MED_PASS = 'MeD Pass',
}

export enum ShiftStatus {
  CANCELLED = 'cancelled',
}

export interface Shift {
  start: firebase.firestore.Timestamp;
  end: firebase.firestore.Timestamp;
  description: string;
  isFree: boolean;
  nurseType: NurseType;
  numberOfNurse: number | string;
  status?: ShiftStatus;
  nurseId?: string;
  clockIn?: firebase.firestore.Timestamp;
  clockOut?: firebase.firestore.Timestamp;
  facilityIdentifier: string;
  additionalRequirements?: AdditionalRequirements[];
  coordinates?: firebase.firestore.GeoPoint;
  additionalnotes?: string;
  tz?: string;
  timesheetActionMadeBy?: string;
}

export type ReviewShift = Shift & { nurseFullName: string; nurseId: string; id: string };
