import { CompanyProfile } from 'src/types';
import {
  FETCH_COMPANY_PROFILE_REQUEST,
  FETCH_COMPANY_PROFILE_SUCCESS,
  FETCH_COMPANY_PROFILE_FAIL,
} from '../constants/userConstants';

export interface UserFetchCompanyProfileActionReducerState {
  companyProfile: CompanyProfile | Record<string, never>;
  loading?: boolean;
  error?: string;
}
export interface UserFetchCompanyProfileActionReducerAction {
  type: typeof FETCH_COMPANY_PROFILE_REQUEST | typeof FETCH_COMPANY_PROFILE_SUCCESS | typeof FETCH_COMPANY_PROFILE_FAIL;
  payload: CompanyProfile;
}

export const userFetchCompanyProfileActionReducer = (
  state: UserFetchCompanyProfileActionReducerState = { companyProfile: {} },
  action: UserFetchCompanyProfileActionReducerAction
): UserFetchCompanyProfileActionReducerState => {
  switch (action.type) {
    case FETCH_COMPANY_PROFILE_REQUEST:
      return { loading: true, companyProfile: {} };
    case FETCH_COMPANY_PROFILE_SUCCESS:
      return {
        loading: false,
        companyProfile: action.payload,
      };
    case FETCH_COMPANY_PROFILE_FAIL:
      return { loading: false, error: 'An error occurred fetching company profile', companyProfile: {} };
    default:
      return state;
  }
};
