import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import { useEffect } from 'react';
import firebase from 'firebase';
import { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import Datetime from 'react-datetime';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody } from 'reactstrap';
import { getShiftsByDate, signOffShifts, timesheetActionForShift } from 'src/api/shifts';
import useUserId from 'src/hooks/useUserId';
import { AppUserType, Shift, TimesheetAction, WithId } from 'src/types';
import { fetchFacilityProfile } from '../../redux/actions/fetchFacilityProfileAction';
import FalconCardHeader from '../common/FalconCardHeader';
import Loader from '../common/Loader';
import { useHistory, useLocation } from 'react-router-dom';
import WelcomeCard from '../common/WelcomeCard';

const dateFormatter = (date: firebase.firestore.Timestamp) => <>{moment(date?.toDate()).format(`M/D/YYYY h:mm A`)}</>;

const Formatter = ({ id, update }: { id: string; update: any }) => {
  const { userId } = useUserId();
  if (!userId) {
    return null;
  }
  return (
    <div>
      <div style={{ display: 'inline-block' }}>
        <button
          style={{
            position: 'relative',
            border: 'none',
            background: 'none',
          }}
          onClick={async () => {
            await timesheetActionForShift(userId, id, TimesheetAction.APPROVED);
            update();
          }}
        >
          <FontAwesomeIcon size="2x" color="green" icon="check" transform="down-1" className="mr-1" />
        </button>
      </div>
      <div style={{ display: 'inline-block', marginLeft: '7px' }}>
        <button
          style={{
            position: 'relative',
            border: 'none',
            background: 'none',
          }}
          onClick={async () => {
            await timesheetActionForShift(userId, id, TimesheetAction.REJECTED);
            update();
          }}
        >
          <FontAwesomeIcon size="2x" color="red" icon="times" transform="down-1" className="mr-1" />
        </button>
      </div>
    </div>
  );
};

const timesheetActionFormatter = (dataField: any, { timesheetActionMadeByFullName, timesheetAction }: any) => {
  if (timesheetAction === TimesheetAction.APPROVED) {
    return (
      <div>
        <FontAwesomeIcon icon="check" color="green" />
        <span style={{ marginLeft: '5px' }}>{timesheetActionMadeByFullName}</span>
      </div>
    );
  }
  if (timesheetAction === TimesheetAction.REJECTED) {
    return (
      <div>
        <FontAwesomeIcon icon="times" color="red" />
        <span style={{ marginLeft: '5px' }}>{timesheetActionMadeByFullName}</span>
      </div>
    );
  }
  return (
    <div>
      <span>{timesheetActionMadeByFullName}</span>
    </div>
  );
};

export default function Timesheet() {
  const [date, setDate] = useState(moment());
  const [timesheets, setTimesheets] = useState<WithId<Shift>[]>([]);
  const [loading, setLoading] = useState(false);
  const { userId } = useUserId();
  const dispatch = useDispatch();
  // @ts-ignore
  const { userType } = useSelector((state) => state.user);
  // @ts-ignore
  const { facilities } = useSelector((state) => state.facility);
  // @ts-ignore
  const { facilityProfile } = useSelector((state) => state.facilityprofile);

  let facilityIds = facilityProfile ? [facilityProfile.id] : [];
  if (userType === AppUserType.COMPANY && facilities) {
    facilityIds = facilities.map((fac: any) => fac.id);
  }
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const queryDate = query.get('date');

  const actionFormatter = (dataField: any, { id }: { id: string }) => {
    return <Formatter id={id} update={getTimesheets} />;
  };

  const history = useHistory();

  const columns = [
    {
      dataField: '',
      headerClasses: 'border-0',
      text: 'Approved?',
      classes: 'border-0 py-2',
      formatter: timesheetActionFormatter,
    },
    {
      dataField: 'workerFullName',
      text: 'Worker',
      headerClasses: 'border-0',
      classes: 'border-0 py-2',
      sort: true,
    },
    {
      dataField: 'nurseType',
      text: 'Position',
      headerClasses: 'border-0',
      classes: 'border-0 py-2',
      sort: true,
    },
    {
      dataField: 'clockIn',
      text: 'Clock In',
      headerClasses: 'border-0',
      classes: 'border-0 py-2',
      formatter: dateFormatter,
      sort: true,
    },
    {
      dataField: 'clockOut',
      text: 'Clock Out',
      headerClasses: 'border-0',
      classes: 'border-0 py-2',
      formatter: dateFormatter,
      sort: true,
    },
    {
      dataField: '',
      headerClasses: 'border-0',
      text: '',
      classes: 'border-0 py-2',
      formatter: actionFormatter,
      align: 'right',
    },
  ];

  useEffect(() => {
    if (queryDate) {
      const momentQueryDate = moment(queryDate).tz('America/Chicago', true);
      if (momentQueryDate.day() !== 0) {
        const newMomentQueryDate = moment(queryDate).tz('America/Chicago', true);
        newMomentQueryDate.day(0);
        const newQueryDate = newMomentQueryDate.format('YYYY-MM-DD');
        history.replace({
          pathname: '/timesheet',
          search: `?date=${newQueryDate}`,
        });
      } else {
        setDate(momentQueryDate);
      }
    } else {
      const newMomentQueryDate = moment(new Date()).tz('America/Chicago', true);
      newMomentQueryDate.day(0);
      const newQueryDate = newMomentQueryDate.format('YYYY-MM-DD');
      history.replace({
        pathname: '/timesheet',
        search: `?date=${newQueryDate}`,
      });
    }
  }, [queryDate]);

  const getTimesheets = async () => {
    if (!facilityIds.length) {
      return;
    }
    setLoading(true);
    const shifts = await getShiftsByDate(facilityIds, date.toDate());
    setTimesheets(shifts);
    setLoading(false);
  };

  useEffect(() => {
    getTimesheets();
  }, [date, facilityIds.join(',')]);

  useEffect(() => {
    if (userId) {
      dispatch(fetchFacilityProfile({ userId }));
    }
  }, [dispatch, userId]);

  if (!userId) {
    return <Loader />;
  }

  return (
    <>
      <WelcomeCard />
      <Card>
        <FalconCardHeader title="Timesheet">
          <div className="d-flex flex-row" style={{ gap: 8 }}>
            <Datetime
              timeFormat={false}
              value={date}
              onChange={(dateTime) => {
                // @ts-ignore
                if (typeof dateTime !== 'string' && dateTime._isValid) {
                  dateTime.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                  history.push({
                    pathname: '/timesheet',
                    search: `?date=${dateTime.format('YYYY-MM-DD')}`,
                  });
                }
              }}
              dateFormat="MM-DD-YYYY"
              isValidDate={(current) => current.day() === 0}
            />
            <Button
              onClick={async () => {
                await signOffShifts(
                  userId,
                  timesheets.map((ts) => ts.id)
                );
                await getTimesheets();
              }}
              size="sm"
              color="primary"
            >
              Approve All
            </Button>
          </div>
        </FalconCardHeader>
        <CardBody>
          <div className="table-responsive">
            {loading ? (
              <Loader />
            ) : (
              <BootstrapTable
                data={timesheets || []}
                keyField="id"
                bordered={false}
                classes="table-striped table-sm fs--1 border-bottom border-200"
                rowClasses="btn-reveal-trigger border-top border-200"
                headerClasses="bg-200 text-900 border-y border-200"
                columns={columns}
              />
            )}
          </div>
        </CardBody>
      </Card>
    </>
  );
}
