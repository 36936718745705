import { ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, CustomInput, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { db } from '../../firebase';
import classNames from 'classnames';
import { CancellationReasonType, Collection, Shift } from 'src/types';
import Loader from '../common/Loader';
import FalconCardHeader from '../common/FalconCardHeader';
import { cancelShift } from 'src/api/shifts';
import moment from 'moment-timezone';

interface ShiftDetailRowProps {
  title: React.ReactNode;
  children?: React.ReactNode;
  isLastItem?: boolean;
}

const ShiftDetailRow = ({ title, isLastItem, children }: ShiftDetailRowProps) => (
  <Row>
    <Col xs={5} sm={4}>
      <p
        className={classNames('font-weight-semi-bold', {
          'mb-0': isLastItem,
          'mb-1': !isLastItem,
        })}
      >
        {title}
      </p>
    </Col>
    <Col>{children}</Col>
  </Row>
);

ShiftDetailRow.defaultProps = { last: false };

interface ShiftDetailsDeleteProps {
  shift: Shift;
  cancelShiftLoading: boolean;
  onCancel: (reasonRadio: CancellationReasonType | null, otherReason: string) => void;
  shiftId: string;
}

export const ShiftDetailsDelete = ({ shift, cancelShiftLoading, onCancel, shiftId }: ShiftDetailsDeleteProps) => {
  const [reasonRadio, setReasonRadio] = useState<CancellationReasonType | null>(null);
  const [otherReason, setOtherReason] = useState('');

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (Object.values<string>(CancellationReasonType).includes(e.target.id)) {
      setReasonRadio(e.target.id as CancellationReasonType);
    }
  };

  const formValid = Boolean(reasonRadio && (reasonRadio !== 'other' || otherReason));

  const { description, isFree, additionalnotes, start, end, nurseType, tz } = shift;
  const timezone = tz || 'America/Chicago';
  const startTime = moment(start.toDate()).tz(timezone).format('M/D/YY h:mm A zz');
  const endTime = moment(end.toDate()).tz(timezone).format('M/D/YY h:mm A zz');

  return (
    <>
      <Card className="mb-3">
        <CardBody>
          <Row className="justify-content-between align-items-center">
            <Col md>
              <h5 className="mb-2 mb-md-0">Select cancellation reason</h5>
            </Col>
          </Row>
          <Row>
            <Col md style={{ marginTop: '20px', marginBottom: '20px' }}>
              <FormGroup tag="fieldset">
                <div>
                  <CustomInput
                    checked={reasonRadio === 'filledExternally'}
                    onChange={onChange}
                    type="radio"
                    label="Shift filled externally (other agency, etc)"
                    id="filledExternally"
                    name="reason"
                  />
                  <CustomInput
                    checked={reasonRadio === 'filledInternally'}
                    onChange={onChange}
                    type="radio"
                    label="Shift filled internally"
                    id="filledInternally"
                    name="reason"
                  />
                  <CustomInput
                    checked={reasonRadio === 'noLongerNeeded'}
                    onChange={onChange}
                    type="radio"
                    label="Shift no longer needed"
                    id="noLongerNeeded"
                    name="reason"
                  />
                  <CustomInput
                    checked={reasonRadio === 'mistake'}
                    onChange={onChange}
                    type="radio"
                    label="Created by mistake or incorrect shift information"
                    id="mistake"
                    name="reason"
                  />
                  <CustomInput
                    checked={reasonRadio === 'other'}
                    onChange={onChange}
                    type="radio"
                    label="Other"
                    id="other"
                    name="reason"
                  />
                  <Input style={{ display: 'none' }} />
                  <FormFeedback>This question is required</FormFeedback>
                </div>
              </FormGroup>
              {reasonRadio === 'other' && (
                <FormGroup tag="fieldset">
                  <Label for="otherDetails">Please provide additional details</Label>
                  <Input id="otherDetails" onChange={(e) => setOtherReason(e.target.value)} />
                </FormGroup>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs="auto">
              <Button
                disabled={!formValid || cancelShiftLoading}
                onClick={() => onCancel(reasonRadio, otherReason)}
                color="falcon-warning"
                size="sm"
                className="mr-2"
              >
                Cancel Shift
              </Button>
              <Button tag={Link} color="falcon-default" size="sm" to={`/shift-details/${shiftId}`}>
                Back
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="mb-3">
        <FalconCardHeader title="Shift Details" light={false} />
        <CardBody className="bg-light border-top">
          <Row>
            <Col lg className="col-xxl-5">
              <h6 className="font-weight-semi-bold ls mb-3 text-uppercase">Shift main information</h6>
              <ShiftDetailRow title="Description">
                {description ? description : <p className="font-italic text-400 mb-1">No information</p>}
              </ShiftDetailRow>
              <ShiftDetailRow title="Is free">
                {isFree ? `Unclaimed` : `Claimed`}
                {isFree ? isFree : <p className="font-italic text-400 mb-1">No information</p>}
              </ShiftDetailRow>
              <ShiftDetailRow title="Nurse type">
                {nurseType ? nurseType : <p className="font-italic text-400 mb-1">No information</p>}
              </ShiftDetailRow>
              <ShiftDetailRow title="Additional notes" isLastItem>
                {additionalnotes ? additionalnotes : <p className="font-italic text-400 mb-0">No information</p>}
              </ShiftDetailRow>
            </Col>
            <Col lg className="col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
              <h6 className="font-weight-semi-bold ls mb-3 text-uppercase">Shift time information</h6>
              <ShiftDetailRow title="Start">
                {startTime ? startTime : <p className="font-italic text-400 mb-1">No information</p>}
              </ShiftDetailRow>
              <ShiftDetailRow title="End" isLastItem>
                {' '}
                {endTime ? endTime : <p className="font-italic text-400 mb-1">No information</p>}{' '}
              </ShiftDetailRow>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

const ShiftDetailsDeleteWrapper = () => {
  const [shift, setShift] = useState<Shift | undefined>();
  const history = useHistory();
  const [shiftLoading, setShiftLoading] = useState(false);
  const [cancelShiftLoading, setCancelShiftLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const { id } = useParams<{ id: string }>();
  const getShift = useCallback(async () => {
    setShiftLoading(true);
    const data = await db.collection(Collection.SHIFTS).doc(id).get();
    if (!data.data()) {
      setNotFound(true);
    } else {
      const shift = data.data() as Shift;
      setShift(shift);
    }
    setShiftLoading(false);
  }, []);

  const deleteShift = async (reasonRadio: CancellationReasonType | null, otherReason: string) => {
    setCancelShiftLoading(true);
    try {
      if (!reasonRadio) {
        toast.error('Reason is a required field');
      } else {
        const { error, message } = await cancelShift(id, reasonRadio, otherReason);
        if (error) {
          toast.error(message);
        } else {
          toast.success('Shift was cancelled successfully');
          history.replace('/');
        }
      }
    } catch (error) {
      toast.error('Failed to cancel shift. Please try again later or contact support.');
    }
    setCancelShiftLoading(false);
  };

  useEffect(() => {
    getShift();
  }, [id]);

  if (shiftLoading) {
    return <Loader />;
  }
  if (notFound || !shift) {
    return <p>Shift not found</p>;
  }

  return (
    <ShiftDetailsDelete shift={shift} onCancel={deleteShift} cancelShiftLoading={cancelShiftLoading} shiftId={id} />
  );
};

export default ShiftDetailsDeleteWrapper;
