import { Col, Row } from 'reactstrap';
import ChooseForm from '../ChooseForm';

const Choose = () => (
  <>
    <Row className="text-left justify-content-between">
      <Col xs="auto">
        <h4>I am a...</h4>
      </Col>
    </Row>
    <ChooseForm />
  </>
);

export default Choose;
