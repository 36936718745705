import { Shift, WithId } from 'src/types';
import { EVENT_LIST_FAIL, EVENT_LIST_REQUEST, EVENT_LIST_SUCCESS } from '../constants/eventConstants';
import { USER_LOGOUT } from '../constants/userConstants';

export interface EventListReducerState {
  events: WithId<Shift>[];
  error?: any;
  loading?: boolean;
}

export interface EventListReducerAction {
  payload: WithId<Shift>[];
  type: typeof USER_LOGOUT | typeof EVENT_LIST_FAIL | typeof EVENT_LIST_SUCCESS | typeof EVENT_LIST_REQUEST;
}

export const eventListReducer = (
  state: EventListReducerState = { events: [] },
  action: EventListReducerAction
): EventListReducerState => {
  switch (action.type) {
    case EVENT_LIST_REQUEST:
      return { loading: true, events: [] };
    case EVENT_LIST_SUCCESS:
      return {
        loading: false,
        events: action.payload,
      };
    case EVENT_LIST_FAIL:
      return { events: [], loading: false, error: action.payload };
    case USER_LOGOUT:
      return { events: [] };
    default:
      return state;
  }
};
