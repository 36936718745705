import classNames from 'classnames';

interface FlexProps {
  children: React.ReactNode;
  className?: string;
  justify?: 'start' | 'center' | 'end' | 'between' | 'around';
  inline?: boolean;
  align?: 'start' | 'center' | 'end';
  column?: boolean;
  wrap?: boolean;
  tag?: React.FunctionComponent<any>;
}

const TagDefault: React.FunctionComponent<any> = (props: any) => <div {...props} />;

const Flex = ({
  justify,
  align,
  inline,
  column,
  wrap,
  className,
  tag: Tag = TagDefault,
  children,
  ...rest
}: FlexProps) => {
  return (
    <Tag
      className={classNames(
        {
          'd-flex': !inline,
          'd-inline-flex': inline,
          [`justify-content-${justify}`]: justify,
          [`align-items-${align}`]: align,
          'flex-column': column,
          'flex-wrap': wrap,
        },
        className
      )}
      {...rest}
    >
      {children}
    </Tag>
  );
};

export default Flex;
