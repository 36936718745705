import { useEffect } from 'react';
import LogoutContent from '../LogoutContent';
import withAuthSplit from '../../../hoc/withAuthSplit';

import bgImg from '../../../assets/img/generic/19.jpg';

interface LogoutProps {
  setBgProps: (props: { image: string }) => void;
}

const Logout = ({ setBgProps }: LogoutProps) => {
  useEffect(() => setBgProps({ image: bgImg }), [setBgProps]);

  return (
    <div className="text-center">
      <LogoutContent layout="split" titleTag={(props) => <h3 {...props} />} />
    </div>
  );
};

export default withAuthSplit(Logout);
