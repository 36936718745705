import { Shift, WithId } from 'src/types';
import { SHIFT_LIST_FAIL, SHIFT_LIST_REQUEST, SHIFT_LIST_SUCCESS, SHIFT_HAS_MORE } from '../constants/shiftConstants';

export interface ShiftReducerState {
  shifts: WithId<Shift>[];
  loading: boolean;
  error?: boolean;
  hasMore?: boolean;
}
export interface ShiftReducerAction {
  type: typeof SHIFT_LIST_FAIL | typeof SHIFT_LIST_REQUEST | typeof SHIFT_LIST_SUCCESS | typeof SHIFT_HAS_MORE;
  payload: { shifts: WithId<Shift>[]; empty: boolean; hasMore?: boolean };
  error: boolean;
}

const initialState = {
  shifts: [],
  loading: false,
  error: false,
  hasMore: true,
};

export const shiftReducer = (
  state: ShiftReducerState = initialState,
  { type, payload, error }: ShiftReducerAction
): ShiftReducerState => {
  switch (type) {
    case SHIFT_LIST_REQUEST:
      return { ...state, loading: true, error: false };
    case SHIFT_LIST_SUCCESS:
      let shifts = [];
      if (payload.empty) {
        shifts = [...payload.shifts];
      } else {
        shifts = [...state.shifts, ...payload.shifts];
      }
      return { shifts, loading: false };
    case SHIFT_LIST_FAIL:
      console.error(error);
      return { ...state, loading: false, error: true };
    case SHIFT_HAS_MORE:
      return { ...state, hasMore: Boolean(payload.hasMore) };
    default:
      return state;
  }
};
