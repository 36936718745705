import { ReactNode } from 'react';
import classNames from 'classnames';

interface DividerProps {
  children: ReactNode;
  className: string;
}

const Divider = ({ className, children }: DividerProps) => (
  <div className={classNames('w-100 position-relative text-center', className)}>
    <hr className="text-300" />
    <div className="position-absolute absolute-centered t-0 px-3 bg-white text-sans-serif fs--1 text-500 text-nowrap">
      {children}
    </div>
  </div>
);

export default Divider;
