import React, { FormEvent, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  CardBody,
  Col,
  CustomInput,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import Loader from '../common/Loader';
import FalconCardHeader from '../common/FalconCardHeader';
import { useDispatch, useSelector } from 'react-redux';
import { fetchShiftsOnTheDashboard } from '../../redux/actions/fetchShiftsOnTheDashboardAction';
import { useState } from 'react';
import { startAddShiftDiscontent } from '../../redux/actions/addShiftDiscontentAction';
import { analytics } from '../../firebase';
import {
  addToBlockList,
  fetchFacilityProfile,
  removeFromBlockList,
} from '../../redux/actions/fetchFacilityProfileAction';
import { getFacilities } from '../../redux/actions/facilityActions';
import { checkShiftRequiresMedpass } from '../../helpers/shifts';
import { toast } from 'react-toastify';
import { capitalize } from 'lodash';
import { getWorkerData } from '../../api/worker';
import { AppUserType, DashboardShift, FacilityProfile, NurseType, ShiftStatus, Worker } from 'src/types';
import useUserId from 'src/hooks/useUserId';

interface ShiftDetailRowProps {
  title: string;
  isLastItem?: boolean;
  children: React.ReactNode;
}

const ShiftDetailRow = ({ title, isLastItem = false, children }: ShiftDetailRowProps) => (
  <Row>
    <Col xs={5} sm={4}>
      <p
        className={classNames('font-weight-semi-bold', {
          'mb-0': isLastItem,
          'mb-1': !isLastItem,
        })}
      >
        {title}
      </p>
    </Col>
    <Col>{children}</Col>
  </Row>
);

ShiftDetailRow.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isLastItem: PropTypes.bool,
};

ShiftDetailRow.defaultProps = { last: false };

interface BlocklistItemProps {
  nurseId: string;
  nurseFullName: string;
}

const None = () => <p className="font-italic text-400 mb-1">None</p>;

const BlocklistItem = ({ nurseId, nurseFullName }: BlocklistItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const userId = useUserId();
  const facilityProfile: FacilityProfile = useSelector((state: any) => state.facilityprofile.facilityProfile);
  const blocklist = facilityProfile?.facilityBlockList || [];
  const dispatch = useDispatch();
  const alreadyBlocked = blocklist.find((a) => a === nurseId);
  const blockUserAction = async (reason: string) => {
    if (alreadyBlocked) {
      await dispatch(removeFromBlockList(nurseId, reason));
      toast.success(`${nurseFullName} has been unblocked and is eligible to pick future shifts`);
    } else {
      await dispatch(addToBlockList(nurseId, reason));
      toast.success(`${nurseFullName} has been blocked and removed from all future shifts.`);
    }
  };
  const actionText = alreadyBlocked ? 'unblock' : 'block';

  const [reason, setReason] = useState('');

  const closeBtn = (
    <button className="close font-weight-normal" onClick={() => setIsOpen(false)}>
      &times;
    </button>
  );

  return (
    <>
      <button className="btn btn-sm btn-link text-primary" onClick={() => setIsOpen(true)}>
        {`${capitalize(actionText)} worker`}
      </button>
      <Modal isOpen={isOpen}>
        <ModalHeader close={closeBtn}>{`${capitalize(actionText)} worker`}</ModalHeader>
        <ModalBody>
          <p>{`Why do you want to ${alreadyBlocked ? 'unblock' : 'block'} ${nurseFullName}?`}</p>
          <Input onChange={(e) => setReason(e.target.value)} value={reason} />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={async () => {
              if (!reason) {
                toast.error('Reason is required');
                return;
              }
              await blockUserAction(reason);
              setIsOpen(false);
              dispatch(fetchFacilityProfile({ userId }));
            }}
            disabled={!reason}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

interface ShiftDetailProps {
  isLoading: boolean;
  shiftListSummary: DashboardShift[];
}

interface FormObj {
  shiftIdentifier: string;
  facilityIdentifier: string;
}

const ShiftDetail = ({ shiftListSummary: itemData, isLoading }: ShiftDetailProps) => {
  const { id }: { id: string } = useParams();

  const userType: AppUserType | undefined = useSelector((state: any) => state.user.userType);
  const [description, setDescription] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [clockInTime, setClockInTime] = useState<string | undefined>('');
  const [clockOutTime, setClockOutTime] = useState<string | undefined>('');
  const [nurseType, setNurseType] = useState<NurseType>();
  const [nurse, setNurse] = useState<Worker>();
  const [status, setStatus] = useState<undefined | ShiftStatus>();
  const [nurseId, setNurseId] = useState<string | undefined>();
  const [additionalnotes, setAdditionalnotes] = useState<string | undefined>('');
  const [facilityName, setFacilityName] = useState('');
  const [formObj, setFormObj] = useState<FormObj | undefined>();
  const nurseFullName = `${nurse?.firstName} ${nurse?.lastName}`;
  // This is the value that comes from the database
  const [hasShiftDiscontentDb, setHasShiftDiscontentDb] = useState<boolean | undefined>();

  const item = (itemData || []).find(function (item) {
    return item.id === id;
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const source = params.get('source');
    analytics.logEvent('shift_details_view', { shiftId: id, source });
  }, [id]);
  useEffect(() => {
    const loadNurse = async (nId: string | undefined) => {
      if (nId) {
        const data = await getWorkerData(nId);
        setNurse(data);
      }
    };

    if (item) {
      setDescription(item.description);
      setStartTime(item.startTime);
      setEndTime(item.endTime);
      setClockInTime(item.clockInTime);
      setClockOutTime(item.clockOutTime);
      setNurseType(item.nurseType);
      setNurseId(item.nurseId);
      setStatus(item.status);
      setAdditionalnotes(item.additionalnotes);
      setHasShiftDiscontentDb(item.hasShiftDiscontent);
      loadNurse(item.nurseId);
      setFacilityName(item.facilityName);
    }
  }, [itemData]);

  const { loading: submittingToDb } = useSelector((state: any) => state.shiftdiscontent);

  // Fetching the user uid to add it as identifier
  const { uid } = useSelector((state: any) => state.email);

  useEffect(() => {
    if (submittingToDb === false) {
      window.location.reload();
    }
  }, [hasShiftDiscontentDb, submittingToDb]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormObj({ ...(formObj || {}), shiftIdentifier: id, facilityIdentifier: uid, [name]: value });
  };

  const dispatch = useDispatch();

  const addDiscontentDetailsAndRefresh = (e: FormEvent) => {
    e.preventDefault();

    dispatch(startAddShiftDiscontent(formObj, id));
  };

  const requiresMedPass = checkShiftRequiresMedpass(item);

  return (
    <div style={{ position: 'relative' }}>
      {status === 'cancelled' && (
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1,
            background: '#e05',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 'xxx-large',
            fontWeight: 'bold',
            opacity: 0.15,
          }}
        >
          Cancelled
        </div>
      )}
      <Card className="mb-3">
        <FalconCardHeader title="Shift Details">
          <UncontrolledDropdown className="d-inline-block ml-2">
            <DropdownToggle color="falcon-default" size="sm">
              <FontAwesomeIcon icon="pencil-alt" className="mr-1" />
              Options
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>
                <Link to={`/shift-details/${id}/edit`}>
                  <h5 className="fs--1">Edit</h5>
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link to={`/shift-details/${id}/delete`}>
                  <h5 className="fs--1 text-danger">Cancel shift</h5>
                </Link>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </FalconCardHeader>
        <CardBody className="bg-light border-top">
          {isLoading ? (
            <Loader />
          ) : (
            <Fragment>
              <Row>
                <Col lg className="col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
                  <ShiftDetailRow title="Description">{description ? description : <None />}</ShiftDetailRow>
                  <ShiftDetailRow title="Position">{nurseType ? nurseType : <None />}</ShiftDetailRow>
                  <ShiftDetailRow title="Start">{startTime ? startTime : <None />}</ShiftDetailRow>
                  <ShiftDetailRow title="End"> {endTime ? endTime : <None />} </ShiftDetailRow>

                  {userType === AppUserType.COMPANY && (
                    <ShiftDetailRow title="Facility Name" isLastItem>
                      {facilityName ? facilityName : <p className="font-italic text-400 mb-0">No information</p>}
                    </ShiftDetailRow>
                  )}
                </Col>
                <Col lg className="col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
                  <ShiftDetailRow title="Worker">
                    {nurse && nurseId ? (
                      <>
                        <span>{nurseFullName}</span>
                        <BlocklistItem nurseId={nurseId} nurseFullName={nurseFullName} />
                      </>
                    ) : (
                      <None />
                    )}
                  </ShiftDetailRow>
                  <ShiftDetailRow isLastItem title="Med Pass">
                    {requiresMedPass ? 'Yes' : 'No'}
                  </ShiftDetailRow>
                  <ShiftDetailRow title="Clock In"> {clockInTime ? clockInTime : <None />} </ShiftDetailRow>
                  <ShiftDetailRow title="Clock Out"> {clockOutTime ? clockOutTime : <None />} </ShiftDetailRow>
                  <ShiftDetailRow title="Additional notes" isLastItem>
                    {additionalnotes ? additionalnotes : <p className="font-italic text-400 mb-0">None</p>}
                  </ShiftDetailRow>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <p>{hasShiftDiscontentDb === true && 'There are discontent details filled out for this shift.'}</p>
                </Col>
              </Row>
            </Fragment>
          )}
        </CardBody>
      </Card>

      {isLoading ? (
        <Loader />
      ) : hasShiftDiscontentDb === true ? null : (
        <Form onSubmit={addDiscontentDetailsAndRefresh}>
          <Card className="mb-3">
            <FalconCardHeader title={`Disciplinary Form`} light={false} />

            <CardBody className="bg-light border-top">
              <Row>
                <Col className="col-6">
                  <FormGroup>
                    <Label for="shiftDiscontentReason">Shift discontent reason</Label>
                    <CustomInput
                      required
                      type="select"
                      id="shiftDiscontentReason"
                      name="shiftDiscontentReason"
                      onChange={handleChange}
                    >
                      <option value="">Select an option</option>
                      <option value="lateToWork">Showed up late to work</option>
                      <option value="poorWorkQuality">Poor work quality</option>
                      <option value="anotherReason">Another reason</option>
                    </CustomInput>
                  </FormGroup>
                </Col>

                <Col className="col-6">
                  <FormGroup>
                    <Label for="applyDiscontentCommentTo">Apply discontent comment to</Label>
                    <Input
                      placeholder={'Type the name(s) of the nurse(s) here'}
                      required
                      type="textarea"
                      id="applyDiscontentCommentTo"
                      name="applyDiscontentCommentTo"
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                <Label>Shift discontent reason details</Label>
                <Input
                  placeholder={'Type here the reason why there is discontent with the shift/nurse'}
                  required
                  type="textarea"
                  id="shiftDiscontentReasonDetails"
                  name="shiftDiscontentReasonDetails"
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Button color="primary" className="mt-3">
                  {submittingToDb === true ? 'Submitting' : 'Submit discontent'}
                </Button>
              </FormGroup>
            </CardBody>
          </Card>
        </Form>
      )}
    </div>
  );
};

const ShiftDetails = () => {
  // Fetching the dashboard shift list from Redux state
  const dashboardShiftListFromRedux = useSelector((state: any) => state.dashboardshiftlist.dashboardshifts);
  // Checking if the data is loading
  const { loading } = useSelector((state: any) => state.dashboardshiftlist);
  const { facilityProfile } = useSelector((state: any) => state.facilityprofile);
  const dispatch = useDispatch();
  const { companyProfile } = useSelector((state: any) => state.companyprofile);
  const companyId = companyProfile?.id;

  useEffect(() => {
    const getCompanyShifts = async () => {
      if (companyId) {
        await dispatch(getFacilities({ companyId }));
        dispatch(fetchShiftsOnTheDashboard());
      }
    };
    getCompanyShifts();
  }, [dispatch, companyId]);

  useEffect(() => {
    if (facilityProfile?.id) {
      dispatch(fetchShiftsOnTheDashboard(facilityProfile?.id));
    }
  }, [dispatch, facilityProfile?.id]);

  return <ShiftDetail shiftListSummary={dashboardShiftListFromRedux} isLoading={loading} />;
};

export default ShiftDetails;
