import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
} from 'reactstrap';
import Flex from '../common/Flex';
import { useDispatch } from 'react-redux';
import { startAddFacility } from '../../redux/actions/addFacilityAction';
import { Controller, useForm } from 'react-hook-form';
import WizardError from '../auth/wizard/WizardError';
import { states } from '../../constants';

type AddFacilityModalProps = {
  setIsOpenCreateFacilityModal: (isOpenCreateFacilityModal: boolean) => void;
  isOpenCreateFacilityModal: boolean;
  companyId: string;
};

type FormData = {
  facilityName: string;
  facilityAddress: string;
  facilityCity: string;
  facilityState: string;
  facilityPostalCode: string;
  facilityPhoneNumber: string;
};

const AddFacilityModal = ({
  setIsOpenCreateFacilityModal,
  isOpenCreateFacilityModal,
  companyId,
}: AddFacilityModalProps) => {
  const toggle = () => setIsOpenCreateFacilityModal(!isOpenCreateFacilityModal);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormData>();

  const closeBtn = (
    <button className="close font-weight-normal" onClick={toggle}>
      &times;
    </button>
  );

  return (
    <Modal isOpen={isOpenCreateFacilityModal} toggle={toggle} modalClassName="theme-modal" contentClassName="border">
      <Form
        onSubmit={handleSubmit(async (data) => {
          const {
            facilityName,
            facilityAddress,
            facilityCity,
            facilityState,
            facilityPostalCode,
            facilityPhoneNumber,
          } = data;
          setIsOpenCreateFacilityModal(false);
          dispatch(
            startAddFacility({
              facilityName: facilityName.trim(),
              facilityAddress: facilityAddress.trim(),
              facilityCity: facilityCity.trim(),
              facilityState,
              facilityPostalCode: facilityPostalCode.trim(),
              facilityPhoneNumber: facilityPhoneNumber.trim(),
              companyId,
            })
          );
        })}
      >
        <ModalHeader toggle={toggle} className="bg-light d-flex flex-between-center border-bottom-0" close={closeBtn}>
          Add a new facility
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label className="fs-0" for="facilityName">
              Facility Name
            </Label>
            <Controller
              rules={{ required: 'Facility Name is required' }}
              render={({ field }) => (
                <Input
                  {...(errors.facilityName?.message ? { className: 'border-danger' } : {})}
                  {...field}
                  name="facilityName"
                  id="facilityName"
                />
              )}
              name="facilityName"
              control={control}
            />

            <WizardError error={errors.facilityName?.message} className="mt-1" style={{ fontSize: '12px' }} />
          </FormGroup>
          <FormGroup>
            <Label className="fs-0" for="facilityAddress">
              Facility Address
            </Label>
            <Controller
              rules={{ required: 'Facility Address is required' }}
              render={({ field }) => (
                <Input
                  {...(errors.facilityAddress?.message ? { className: 'border-danger' } : {})}
                  {...field}
                  name="facilityAddress"
                  id="facilityAddress"
                />
              )}
              name="facilityAddress"
              control={control}
            />
            <WizardError error={errors.facilityAddress?.message} className="mt-1" style={{ fontSize: '12px' }} />
          </FormGroup>
          <FormGroup>
            <Label className="fs-0" for="facilityCity">
              Facility City
            </Label>
            <Controller
              rules={{ required: 'Facility City is required' }}
              render={({ field }) => (
                <Input
                  {...(errors.facilityCity?.message ? { className: 'border-danger' } : {})}
                  {...field}
                  name="facilityCity"
                  id="facilityCity"
                />
              )}
              name="facilityCity"
              control={control}
            />
            <WizardError error={errors.facilityCity?.message} className="mt-1" style={{ fontSize: '12px' }} />
          </FormGroup>
          <FormGroup>
            <Label className="fs-0" for="facilityState">
              Facility State
            </Label>
            <Controller
              rules={{ required: 'Facility State is required' }}
              render={({ field }) => (
                <CustomInput
                  type="select"
                  bsSize="lg"
                  defaultValue="1"
                  {...field}
                  {...(errors.facilityState?.message ? { className: 'border-danger' } : {})}
                  id="facilityState"
                >
                  <option value="" />
                  {states.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </CustomInput>
              )}
              name="facilityState"
              control={control}
            />

            <WizardError error={errors.facilityState?.message} className="mt-1" style={{ fontSize: '12px' }} />
          </FormGroup>
          <FormGroup>
            <Label className="fs-0" for="facilityPostalCode">
              Facility Postal Code
            </Label>
            <Controller
              rules={{
                required: 'Facility Postal Code is required',
                pattern: { value: /(^\d{5}$)|(^\d{5}-\d{4}$)/, message: 'Must be a valid postal code' },
              }}
              render={({ field }) => (
                <Input
                  {...(errors.facilityPostalCode?.message ? { className: 'border-danger' } : {})}
                  {...field}
                  name="facilityPostalCode"
                  id="facilityPostalCode"
                />
              )}
              name="facilityPostalCode"
              control={control}
            />
            <WizardError error={errors.facilityPostalCode?.message} className="mt-1" style={{ fontSize: '12px' }} />
          </FormGroup>
          <FormGroup>
            <Label className="fs-0" for="facilityPhoneNumber">
              Facility Phone Number
            </Label>
            <Controller
              rules={{
                required: 'Facility Phone Number is required',
                pattern: {
                  value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                  message: 'Must be a valid phone number',
                },
              }}
              render={({ field }) => (
                <Input
                  {...(errors.facilityCity?.message ? { className: 'border-danger' } : {})}
                  {...field}
                  name="facilityPhoneNumber"
                  id="facilityPhoneNumber"
                />
              )}
              name="facilityPhoneNumber"
              control={control}
            />
            <WizardError error={errors.facilityPhoneNumber?.message} className="mt-1" style={{ fontSize: '12px' }} />
          </FormGroup>
        </ModalBody>
        <ModalFooter tag={Flex} justify="end" align="center" className="bg-light border-top-0">
          <Button color="primary" type="submit" className="px-4">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddFacilityModal;
