import React, { useState } from 'react';
import { AuthWizardUser, HandleInputChangeInput } from 'src/types';
import { AuthWizardContext } from '../../../context/Context';

interface AuthWizardProviderProps {
  children: React.ReactNode;
}

const AuthWizardProvider = ({ children }: AuthWizardProviderProps) => {
  const [user, setUser] = useState<AuthWizardUser>({} as AuthWizardUser);
  const [step, setStep] = useState(1);

  const handleInputChange = ({ value, name }: HandleInputChangeInput) => setUser({ ...user, [name]: value });

  const value = { user, setUser, step, setStep, handleInputChange };
  return <AuthWizardContext.Provider value={value}>{children}</AuthWizardContext.Provider>;
};

export default AuthWizardProvider;
