import WizardInput from './WizardInput';
import { Col, Row } from 'reactstrap';
import { capitalize } from '../../../helpers/utils';
import { states } from '../../../constants';
import { AppUserType } from 'src/types';
import { Controller, FieldErrors, UseFormWatch, Control } from 'react-hook-form';

export interface UserFormValues {
  firstName: string;
  lastName: string;
  entityName: string;
  entityAddress: string;
  entityCity: string;
  entityState: string;
  confirmPassword: string;
  password: string;
  entityEmail: string;
  entityPhoneNumber: string;
  entityPostalCode: string;
  agreeToTerms: boolean;
}

interface BasicUserFormProps {
  errors: FieldErrors;
  userType: AppUserType;
  watch: UseFormWatch<UserFormValues>;
  control: Control<UserFormValues>;
}

const BasicUserForm = ({ errors, watch, userType, control }: BasicUserFormProps) => {
  const entityState = watch('entityState', '');
  return (
    <>
      <Row form>
        <Col>
          <Controller
            rules={{
              required: 'First name is required',
            }}
            render={({ field }) => <WizardInput placeholder="First Name" id="firstName" errors={errors} {...field} />}
            name="firstName"
            control={control}
          />
        </Col>
        <Col>
          <Controller
            rules={{
              required: 'Last name is required',
            }}
            render={({ field }) => <WizardInput placeholder="Last Name" id="lastName" errors={errors} {...field} />}
            name="lastName"
            control={control}
          />
        </Col>
      </Row>
      <Controller
        rules={{
          required: `${capitalize(userType)} Name is required`,
        }}
        render={({ field }) => (
          <WizardInput placeholder={`${capitalize(userType)} Name`} id="entityName" {...field} errors={errors} />
        )}
        name="entityName"
        control={control}
      />

      <Controller
        rules={{
          required: `${capitalize(userType)} Address is required`,
        }}
        render={({ field }) => (
          <WizardInput placeholder={`${capitalize(userType)} Address`} id="entityAddress" {...field} errors={errors} />
        )}
        name="entityAddress"
        control={control}
      />

      <Row form>
        <Col>
          <Controller
            rules={{
              required: `${capitalize(userType)} City is required`,
              minLength: {
                value: 2,
                message: `Min length 2 for ${capitalize(userType)} City`,
              },
            }}
            render={({ field }) => (
              <WizardInput placeholder={`${capitalize(userType)} City`} id="entityCity" errors={errors} {...field} />
            )}
            name="entityCity"
            control={control}
          />
        </Col>
        <Col>
          <Controller
            rules={{
              required: `${capitalize(userType)} State is required`,
            }}
            render={({ field }) => (
              <WizardInput
                placeholder={`${capitalize(userType)} State`}
                id="entityState"
                type="select"
                options={states}
                {...field}
                errors={errors}
                isUsingPlaceholder={entityState === ''}
              />
            )}
            name="entityState"
            control={control}
          />
        </Col>
        <Col>
          <Controller
            rules={{
              required: `${capitalize(userType)} Postal Code is required`,
              pattern: { value: /(^\d{5}$)|(^\d{5}-\d{4}$)/, message: 'Must be a valid postal code' },
            }}
            render={({ field }) => (
              <WizardInput
                placeholder={`${capitalize(userType)} Zip`}
                id="entityPostalCode"
                {...field}
                errors={errors}
              />
            )}
            name="entityPostalCode"
            control={control}
          />
        </Col>
      </Row>

      <Controller
        rules={{
          required: `${capitalize(userType)} Phone is required`,
          pattern: {
            value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
            message: 'Must be a valid phone number',
          },
        }}
        render={({ field }) => (
          <WizardInput
            placeholder={`${capitalize(userType)} Phone`}
            id="entityPhoneNumber"
            {...field}
            errors={errors}
          />
        )}
        name="entityPhoneNumber"
        control={control}
      />

      <Controller
        rules={{
          required: `${capitalize(userType)} Email is required`,
          pattern: {
            value: /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
            message: `Must be a valid email address`,
          },
        }}
        render={({ field }) => (
          <WizardInput placeholder={`${capitalize(userType)} Email`} id="entityEmail" {...field} errors={errors} />
        )}
        name="entityEmail"
        control={control}
      />

      <Row form>
        <Col>
          <Controller
            rules={{
              required: 'You must specify a password',
              minLength: {
                value: 6,
                message: 'Password must have at least 6 characters',
              },
            }}
            render={({ field }) => (
              <WizardInput type="password" placeholder="Password" id="password" {...field} errors={errors} />
            )}
            name="password"
            control={control}
          />
        </Col>
        <Col>
          <Controller
            rules={{
              validate: (value) => value === watch('password') || 'Password does not match',
            }}
            render={({ field }) => (
              <WizardInput
                type="password"
                placeholder="Confirm Password"
                id="confirmPassword"
                {...field}
                errors={errors}
              />
            )}
            name="confirmPassword"
            control={control}
          />
        </Col>
      </Row>
    </>
  );
};

export default BasicUserForm;
