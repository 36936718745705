import { getWorkerRef } from '../../api/worker';
import { Collection } from 'src/types';
import { db } from '../../firebase';
import {
  SHIFT_ADD_DISCONTENT_REQUEST,
  SHIFT_ADD_DISCONTENT_SUCCESS,
  SHIFT_ADD_DISCONTENT_SUCCESS_FINISH,
  SHIFT_ADD_DISCONTENT_FAIL,
} from '../constants/eventConstants';

// The Add Shift Discontent action
export const startAddShiftDiscontent = (shiftDiscontent, shift) => async (dispatch) => {
  dispatch({
    type: SHIFT_ADD_DISCONTENT_REQUEST,
  });

  try {
    const shiftDocRef = db.collection(Collection.SHIFTS).doc(shift);

    await shiftDocRef.update({ hasShiftDiscontent: true });

    const nurse = await getWorkerRef().where('shifts', 'array-contains', shiftDocRef).get();
    await db.collection(Collection.SHIFT_DISCONTENTS).add({ ...shiftDiscontent, nurseId: nurse.docs[0].id });

    dispatch({
      type: SHIFT_ADD_DISCONTENT_SUCCESS,
    });

    dispatch({
      type: SHIFT_ADD_DISCONTENT_SUCCESS_FINISH,
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: SHIFT_ADD_DISCONTENT_FAIL,
      payload: { error },
    });
  }
};
