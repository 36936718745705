import { isIterableArray } from '../../helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from './Loader';
import Flex from './Flex';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface AvatarProps {
  size?: 's' | 'm' | 'l' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl';
  rounded?: string;
  src: string[] | string;
  name?: string;
  className: string;
  mediaClass?: string;
  isExact?: boolean;
  icon?: IconProp;
}

const Avatar = ({
  size = 'xl',
  rounded = 'circle',
  src,
  name,
  className,
  mediaClass,
  isExact = false,
  icon,
}: AvatarProps) => {
  const classNames = ['avatar', `avatar-${size}`, className].join(' ');
  const mediaClasses = [rounded ? `rounded-${rounded}` : 'rounded', mediaClass].join(' ');

  const getAvatar = () => {
    if (src) {
      if (isIterableArray(src)) {
        const arrSrc = src as string[];
        return (
          <div className={`${mediaClasses} overflow-hidden h-100 d-flex`}>
            <div className="w-50 border-right">
              <img src={arrSrc[0]} alt="" />
            </div>
            <div className="w-50 d-flex flex-column">
              <img src={arrSrc[1]} alt="" className="h-50 border-bottom" />
              <img src={arrSrc[2]} alt="" className="h-50" />
            </div>
          </div>
        );
      } else {
        const elSrc = src as string;
        return <img className={mediaClasses} src={elSrc} alt="" />;
      }
    }

    if (name) {
      return (
        <div className={`avatar-name ${mediaClasses}`}>
          <span>{isExact ? name : (name.match(/\b\w/g) || []).join('')}</span>
        </div>
      );
    }

    if (icon) {
      return (
        <Flex className={`avatar-name ${mediaClasses} flex-center`}>
          <FontAwesomeIcon icon={icon} />
        </Flex>
      );
    }

    return (
      <div className={`avatar-emoji ${mediaClasses}`}>
        <span role="img" aria-label="Emoji">
          <Loader className="align-baseline pb-3" size="sm" />
        </span>
      </div>
    );
  };

  return <div className={classNames}>{getAvatar()}</div>;
};

export default Avatar;
