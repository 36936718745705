import { useContext, useEffect, useState } from 'react';
import { UserContext } from 'src/context/Context';
import { auth } from 'src/firebase';

const useUserId = () => {
  const { isAdminUser, secretUserId } = useContext(UserContext);
  const [userId, setUserId] = useState<string | undefined>();
  useEffect(() => {
    let newUserId = auth.currentUser?.uid;
    if (isAdminUser) {
      newUserId = secretUserId || undefined;
    }
    setUserId(newUserId);
  }, [isAdminUser, secretUserId]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      let newUserId = user?.uid;
      if (isAdminUser) {
        newUserId = localStorage.getItem('secretUserId') || undefined;
      }
      setUserId(newUserId);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return { userId };
};

export default useUserId;
