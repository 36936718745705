import { createContext } from 'react';
import { AuthWizardUser, HandleInputChangeInput } from 'src/types';
import { settings } from '../config';

const AppContext = createContext(settings);

export const EmailContext = createContext({ emails: [] });

export const ProductContext = createContext({ products: [] });

export const FeedContext = createContext({ feeds: [] });

export const AuthWizardContext = createContext({
  user: {} as AuthWizardUser,
  setUser: (user: AuthWizardUser) => {
    return;
  },
  step: 1,
  setStep: (step: number) => {
    return;
  },
  handleInputChange: ({ value, name }: HandleInputChangeInput) => {
    return;
  },
});

export default AppContext;

export const UserContext = createContext({
  isAdminUser: false,
  setIsAdminUser: (isAdminUser: boolean) => {
    return;
  },
  secretUserId: undefined as undefined | string,
  setSecretUserId: (secretUserId: string | undefined) => {
    return;
  },
});
