import classNames from 'classnames';
import { CSSProperties } from 'react';

interface WizardErrorProps {
  error?: string;
  className?: string;
  style?: CSSProperties;
}

const WizardError = ({ error, className, style, ...rest }: WizardErrorProps) =>
  Boolean(error) ? (
    <span style={style} className={classNames('text-danger d-inline-block', className)} {...rest}>
      {error}
    </span>
  ) : null;

export default WizardError;
