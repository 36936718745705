import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import Background from '../common/Background';
import corner1 from '../../assets/img/illustrations/corner-1.png';
import corner2 from '../../assets/img/illustrations/corner-2.png';
import corner3 from '../../assets/img/illustrations/corner-3.png';
import { ReactNode } from 'react';

const getImage = (color: string) => {
  switch (color) {
    case 'warning':
      return corner1;
    case 'info':
      return corner2;
    case 'success':
      return corner3;
    default:
      return corner1;
  }
};

const getContentClassNames = (color: string) => {
  const contentClassNames = 'display-4 fs-4 mb-2 font-weight-normal text-sans-serif';
  if (color === 'success') return contentClassNames;
  return `${contentClassNames} text-${color}`;
};

interface CardSummaryProps {
  title: ReactNode;
  rate?: string;
  linkText?: string;
  to?: string;
  color?: string;
  showLinkTest?: boolean;
  children?: ReactNode;
  bottomText?: ReactNode;
}

const CardSummary = ({
  title,
  rate,
  linkText = 'See all',
  to = '#!',
  color = 'primary',
  showLinkTest = true,
  children,
  bottomText,
}: CardSummaryProps) => {
  return (
    <Card className="mb-3 overflow-hidden" style={{ minWidth: '12rem' }}>
      <Background image={getImage(color)} className="bg-card" />
      <CardBody className="position-relative">
        <h6>
          {title}
          {rate && <span className={`badge badge-soft-${color} rounded-capsule ml-2`}>{rate}</span>}
        </h6>
        <div className={getContentClassNames(color)}>{children}</div>
        {showLinkTest && (
          <Link className="font-weight-semi-bold fs--1 text-nowrap" to={to}>
            {linkText}
            <FontAwesomeIcon icon="angle-right" transform="down-1.5" className="ml-1" />
          </Link>
        )}
        {bottomText && <div style={{ marginTop: '15px', fontSize: '14px' }}>{bottomText}</div>}
      </CardBody>
    </Card>
  );
};

export default CardSummary;
