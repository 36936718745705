import { Route, Redirect, RouteProps } from 'react-router-dom';

interface PrivateRouteProps {
  isAuthenticated: boolean;
  component: React.FunctionComponent<RouteProps>;
}

export const PrivateRoute = ({ isAuthenticated, component: Component, ...rest }: PrivateRouteProps) => {
  return (
    <Route
      {...rest}
      component={(props: RouteProps) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/authentication/basic/choose" />
      }
    />
  );
};
