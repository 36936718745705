import React from 'react';
import { Card, CardBody } from 'reactstrap';
import infoBackgroundImage from '../../assets/img/illustrations/corner-2.png';

interface PendingCardProps {
  children: React.ReactNode;
}

const PendingCard = ({ children }: PendingCardProps) => {
  return (
    <Card
      className="mb-3"
      style={{
        minWidth: '12rem',
        height: '300px',
      }}
    >
      <CardBody
        className="bg-holder"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: `url(${infoBackgroundImage})`,
        }}
      >
        {children}
      </CardBody>
    </Card>
  );
};

export default PendingCard;
