import { Facility, WithId } from 'src/types';
import { FACILITY_LIST_FAIL, FACILITY_LIST_REQUEST, FACILITY_LIST_SUCCESS } from '../constants/facilityConstants';

export interface FacilityReducerState {
  facilities: WithId<Facility>[];
  loading: boolean;
  error: boolean;
}

export interface FacilityReducerAction {
  type: typeof FACILITY_LIST_REQUEST | typeof FACILITY_LIST_SUCCESS | typeof FACILITY_LIST_FAIL;
  payload: {
    facilities: WithId<Facility>[];
  };
}

const initialState: FacilityReducerState = {
  facilities: [],
  loading: false,
  error: false,
};

export const facilityReducer = (
  state: FacilityReducerState = initialState,
  { type, payload }: FacilityReducerAction
) => {
  switch (type) {
    case FACILITY_LIST_REQUEST:
      return { ...state, loading: true, error: false };
    case FACILITY_LIST_SUCCESS:
      const facilities = [...payload.facilities];
      return { facilities, loading: false };
    case FACILITY_LIST_FAIL:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
};
