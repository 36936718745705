export const NurseType = { CAREGIVER: 'Caregiver', LPN: 'LPN', RN: 'RN', CNA: 'CNA' };
export const AdditionalRequirements = { MED_PASS: 'MeD Pass' };

export const shiftReviewQuestions = {
  rating: {
    text: 'How would you rate your PT Nurse healthcare professional for this shift?',
    type: 'numericScale',
    bounds: [1, 5],
    required: true,
  },
  onTime: {
    text: 'Was your PT Nurse healthcare professional on time?',
    required: true,
    type: 'boolean',
  },
  additionalComments: {
    text: 'Is there anything else you want to share about this shift?',
    required: false,
    type: 'textarea',
  },
};

export const shiftReviewQuestionsOrder = ['rating', 'onTime', 'additionalComments'];

export const ReviewerType = {
  FACILITY: 'facility',
};

export const RevieweeType = {
  WORKER: 'worker',
};

export const UserType = {
  FACILITY: 'facility',
  WORKER: 'worker',
  ADMIN: 'admin',
};

export const FacilityUserType = {
  OWNER: 'owner',
  ADMIN: 'admin',
};

export const states = [
  'Alabama',
  'Alaska',
  'American Samoa',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Federated States of Micronesia',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Marshall Islands',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Northern Mariana Islands',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Palau',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virgin Island',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];
