import React, { useContext, useState, Fragment } from 'react';
import { Card, CardBody, CardFooter, CardHeader, Form, Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import BasicUserForm, { UserFormValues } from './BasicUserForm';
import AdvanceUserForm from './AdvanceUserForm';
import Success from './Success';
import AppContext, { AuthWizardContext } from '../../../context/Context';

import WizardModal from './WizardModal';
import ButtonIcon from '../../common/ButtonIcon';

import { startSignUp } from '../../../redux/actions/registerWithEmailAction';
import { Link } from 'react-router-dom';
import { capitalize } from '../../../helpers/utils';
import { toast } from 'react-toastify';
import { AppUserType } from 'src/types';
import useAppDispatch from 'src/hooks/useAppDispatch';

interface UserFormProps {
  userType: AppUserType;
}

const UserForm = ({ userType }: UserFormProps) => {
  const { isRTL } = useContext(AppContext);
  const { user, setUser, step, setStep } = useContext(AuthWizardContext);
  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm<UserFormValues>();
  const {
    entityName,
    entityAddress,
    entityCity,
    entityState,
    entityPhoneNumber,
    entityPostalCode,
    entityEmail,
    firstName,
    lastName,
    password,
  } = user;

  const dispatch = useAppDispatch();

  const onSubmitData = async (data: UserFormValues) => {
    setUser({ ...user, ...data });

    // Getting on to step 3
    if (step === 2) {
      const result = await dispatch(
        // @ts-ignore
        startSignUp(
          userType,
          entityName,
          entityAddress,
          entityCity,
          entityState,
          entityPostalCode,
          entityPhoneNumber,
          entityEmail,
          firstName,
          lastName,
          password
        )
      );
      const error = result?.error;
      if (error?.code === 'auth/email-already-in-use') {
        toast.error('Failed to create account. Email address is already in use by another account.');
      } else if (error?.code === 'auth/invalid-email') {
        toast.error('Failed to create account. Email address is invalid.');
      } else if (error?.code === 'auth/weak-password') {
        toast.error('Failed to create account. Password should be at least 6 characters');
      } else if (error) {
        toast.error('Failed to create account due to unexpected error.');
      }
      if (error) {
        setStep(step - 1);
      } else {
        setStep(step + 1);
      }
    } else {
      setStep(step + 1);
    }
  };

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const handleBackStep = (targetStep: number) => {
    if (step !== 3) {
      if (targetStep < step) {
        setStep(targetStep);
      }
    } else {
      toggle();
    }
  };

  return (
    <Fragment>
      <WizardModal toggle={toggle} modal={modal} setModal={setModal} />
      <Card tag={Form} onSubmit={handleSubmit(onSubmitData)} className="theme-wizard">
        <CardHeader className="bg-light">
          <Nav className="justify-content-center">
            <NavItem>
              <NavLink
                className={classNames('font-weight-semi-bold', {
                  'done cursor-pointer': step > 1,
                  active: step === 1,
                })}
                onClick={() => handleBackStep(1)}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">
                    <FontAwesomeIcon icon="info" />
                  </span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">{`${capitalize(userType)} Details`}</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames('font-weight-semi-bold', {
                  'done  cursor-pointer': step > 2,
                  active: step === 2,
                })}
                onClick={() => handleBackStep(2)}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">
                    <FontAwesomeIcon icon="file-signature" />
                  </span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">{`${capitalize(userType)} Contract`}</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames('font-weight-semi-bold', {
                  'done  cursor-pointer': step > 2,
                })}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">
                    <FontAwesomeIcon icon="thumbs-up" />
                  </span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">Done</span>
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody className="fs--1 font-weight-normal px-md-6 pt-4 pb-3">
          {step === 1 && <BasicUserForm control={control} userType={userType} errors={errors} watch={watch} />}
          {step === 2 && <AdvanceUserForm control={control} errors={errors} />}
          {step === 3 && <Success />}
        </CardBody>
        <CardFooter className={classNames('px-md-6 bg-light', { 'd-none': step === 4, ' d-flex': step < 4 })}>
          <ButtonIcon
            color="link"
            icon={isRTL ? 'chevron-right' : 'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-0 font-weight-semi-bold', { 'd-none': step === 1 || step === 3 })}
            onClick={() => {
              setStep(step - 1);
            }}
          >
            Prev
          </ButtonIcon>

          {step === 3 ? (
            <ButtonIcon
              tag={Link}
              to={`/`}
              color="primary"
              className={` mx-auto px-5 `}
              type="submit"
              icon={isRTL ? 'chevron-left' : 'chevron-right'}
              iconAlign="right"
              transform="down-1 shrink-4"
            >
              {step === 3 && 'Go to Dashboard'}
            </ButtonIcon>
          ) : (
            <ButtonIcon
              color="primary"
              className=" ml-auto px-5 "
              type="submit"
              icon={isRTL ? 'chevron-left' : 'chevron-right'}
              iconAlign="right"
              transform="down-1 shrink-4"
            >
              {step === 1 && 'Next'}
              {step === 2 && 'Create account'}
            </ButtonIcon>
          )}
        </CardFooter>
      </Card>
    </Fragment>
  );
};

export default UserForm;
