import { facebookAuthProvider, auth } from '../../firebase';
import {
  USER_FACEBOOK_LOGIN_FAIL,
  USER_FACEBOOK_LOGIN_REQUEST,
  USER_FACEBOOK_LOGIN_SUCCESS,
} from '../constants/userConstants';

// The Facebook login action
export const startFacebookLogin = () => async (dispatch) => {
  dispatch({
    type: USER_FACEBOOK_LOGIN_REQUEST,
  });
  try {
    auth.signInWithPopup(facebookAuthProvider).then(({ user }) => {
      dispatch(facebookLogin(user.uid, user.displayName));
      // const userEmail= user.email
      // localStorage.setItem('userInfo', JSON.stringify(userEmail))
      // window.location.reload()
    });
  } catch (error) {
    dispatch({
      type: USER_FACEBOOK_LOGIN_FAIL,
      payload: error.message,
    });
  }
};

export const facebookLogin = (uid, displayName) => ({
  type: USER_FACEBOOK_LOGIN_SUCCESS,
  payload: {
    uid,
    displayName,
  },
});
