import React, { ReactNode, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Media, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Flex from '../common/Flex';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { IconProp, Transform } from '@fortawesome/fontawesome-svg-core';
import { getWorkerData } from 'src/api/worker';

const getCircleStackIcon = (icon: IconProp, transform?: string | Transform) => (
  <span className="fa-stack ml-n1 mr-3">
    <FontAwesomeIcon icon="circle" className="text-200 fa-stack-2x" />
    <FontAwesomeIcon icon={icon} transform={transform ?? ''} className="text-primary fa-stack-1x" inverse />
  </span>
);

interface EventModalMediaContentProps {
  icon: IconProp;
  heading: string;
  content?: string;
  children?: ReactNode;
}

const EventModalMediaContent = ({ icon, heading, content, children }: EventModalMediaContentProps) => (
  <Media className="mt-3">
    {getCircleStackIcon(icon)}
    <Media body>
      <>
        <h6>{heading}</h6>
        {children || <p className="mb-0 text-justify">{content}</p>}
      </>
    </Media>
  </Media>
);

interface CalendarEventModalProps {
  isOpenModal: boolean;
  setIsOpenModal: (isOpenModal: boolean) => void;
  modalEventContent: any;
}

const CalendarEventModal = ({ isOpenModal, setIsOpenModal, modalEventContent }: CalendarEventModalProps) => {
  const [name, setName] = useState('');
  const nurseId = modalEventContent.event?.extendedProps?.nurseId;
  useEffect(() => {
    if (nurseId) {
      getWorkerData(nurseId).then(({ firstName, lastName }) => {
        if (firstName && lastName) {
          setName(`${firstName.trim()} ${lastName.trim()}`);
        }
      });
    }
  }, [nurseId]);
  const toggle = () => setIsOpenModal(!isOpenModal);

  const { id, title, end, start } = isOpenModal && modalEventContent.event;
  const { description, location, organizer, schedules, status } = isOpenModal && modalEventContent.event.extendedProps;

  const closeBtn = (
    <button className="close font-weight-normal" onClick={toggle}>
      &times;
    </button>
  );

  return (
    <Modal isOpen={isOpenModal} toggle={toggle} modalClassName="theme-modal" contentClassName="border" centered>
      <ModalHeader
        toggle={toggle}
        tag="div"
        className={`px-card ${status === 'cancelled' ? 'bg-danger' : 'bg-light'} border-0 flex-between-center`}
        close={closeBtn}
      >
        <h5 className="mb-0">
          {title} {status === 'cancelled' ? '(Cancelled)' : ''}
        </h5>
        {organizer && (
          <p className="mb-0 fs--1 mt-1">
            by <a href="#!">{organizer}</a>
          </p>
        )}
      </ModalHeader>
      <ModalBody className="px-card pb-card pt-1 fs--1">
        {description && <EventModalMediaContent icon="align-left" heading="Description" content={description} />}
        {(end || start) && (
          <EventModalMediaContent icon="calendar-check" heading="Date and Time">
            <span>{moment(start).format('LLLL')}</span>
            {end && (
              <>
                {' '}
                – <br /> <span>{moment(end).format('LLLL')}</span>
              </>
            )}
          </EventModalMediaContent>
        )}
        {location && (
          <EventModalMediaContent icon="map-marker-alt" heading="Location">
            <div className="mb-1" dangerouslySetInnerHTML={{ __html: location }} />
          </EventModalMediaContent>
        )}
        {name && (
          <EventModalMediaContent icon="user-nurse" heading="Healthcare Professional">
            <div>{name}</div>
          </EventModalMediaContent>
        )}
        {schedules && (
          <EventModalMediaContent icon="clock" heading="Schedule">
            <ul className="list-unstyled timeline mb-0">
              {schedules.map((schedule: any, index: number) => (
                <li key={index}>{schedule.title}</li>
              ))}
            </ul>
          </EventModalMediaContent>
        )}
      </ModalBody>
      <ModalFooter tag={Flex} justify="end" className="bg-light px-card border-top-0">
        <Button tag={Link} to={`/shift-details/${id}`} color="falcon-primary" size="sm">
          <span>See more details</span>
          <FontAwesomeIcon icon="angle-right" className="fs--2 ml-1" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CalendarEventModal;
