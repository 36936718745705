import {
  SHIFT_ADD_DISCONTENT_REQUEST,
  SHIFT_ADD_DISCONTENT_SUCCESS,
  SHIFT_ADD_DISCONTENT_SUCCESS_FINISH,
  SHIFT_ADD_DISCONTENT_FAIL,
} from '../constants/eventConstants';
import { USER_LOGOUT } from '../constants/userConstants';

export interface ShiftAddDiscontentReducerAction {
  type:
    | typeof SHIFT_ADD_DISCONTENT_REQUEST
    | typeof SHIFT_ADD_DISCONTENT_SUCCESS
    | typeof SHIFT_ADD_DISCONTENT_SUCCESS_FINISH
    | typeof SHIFT_ADD_DISCONTENT_FAIL
    | typeof USER_LOGOUT;
  payload: { error: any };
}

export interface ShiftAddDiscontentReducerState {
  loading?: boolean;
  storedInDb?: boolean;
  error?: any;
}

export const shiftAddDiscontentReducer = (
  state: ShiftAddDiscontentReducerState = {},
  action: ShiftAddDiscontentReducerAction
): ShiftAddDiscontentReducerState => {
  switch (action.type) {
    case SHIFT_ADD_DISCONTENT_REQUEST:
      return {
        loading: true,
        storedInDb: false,
      };
    case SHIFT_ADD_DISCONTENT_SUCCESS:
      return {
        loading: false,
        storedInDb: true,
      };
    case SHIFT_ADD_DISCONTENT_SUCCESS_FINISH:
      return {
        loading: false,
        storedInDb: true,
      };
    case SHIFT_ADD_DISCONTENT_FAIL:
      return {
        loading: false,
        storedInDb: false,
        error: action.payload,
      };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};
