import React, { Fragment, useEffect, useState } from 'react';

import ReviewsToFillOutCard from '../dashboard/ReviewsToFillOutCard';
import { getReviewsToFillOut, getReviewsToFillOutByCompany } from '../../api/reviews';
import { AppUserType, ReviewShift } from 'src/types';
import useAppSelector from 'src/hooks/useAppSelector';
import WelcomeCard from '../common/WelcomeCard';
import PastReviewsCard from '../common/PastReviewsCard';

const Reviews = () => {
  const { userType } = useAppSelector((state) => state.user);
  const { facilities } = useAppSelector((state) => state.facility);
  const { companyProfile } = useAppSelector((state) => state.companyprofile);
  const { facilityProfile } = useAppSelector((state) => state.facilityprofile);
  const [reviewsToFillOut, setReviewsToFillOut] = useState<ReviewShift[]>([]);
  let entityName = facilityProfile?.facilityName;
  if (userType === AppUserType.COMPANY) {
    entityName = companyProfile?.companyName;
  }
  useEffect(() => {
    const getEligibleReviews = async () => {
      if (userType === AppUserType.COMPANY && facilities?.length) {
        const reviews = await getReviewsToFillOutByCompany(facilities);
        setReviewsToFillOut(reviews);
      } else if (userType === AppUserType.FACILITY && facilityProfile?.id) {
        const reviews = await getReviewsToFillOut(facilityProfile?.id);
        setReviewsToFillOut(reviews);
      }
    };
    getEligibleReviews();
  }, [userType, facilities, facilityProfile?.id]);
  if (!entityName) {
    return null;
  }
  return (
    <Fragment>
      <WelcomeCard />
      <ReviewsToFillOutCard reviewsToFillOut={reviewsToFillOut.slice(0, 5)} />
      <PastReviewsCard />
    </Fragment>
  );
};

export default Reviews;
