import { auth, firebase } from '../../firebase';
import {
  USER_LOGIN_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
} from '../constants/userConstants';
import { fetchFacilityProfile } from './fetchFacilityProfileAction';
import { fetchCompanyProfile } from './fetchCompanyProfileAction';
import { UserType } from '../../constants';
import { createFacilityAdmin, createFacilityAndOwner } from '../../api/facilities';
import { createCompanyAndOwner } from '../../api/companies';
import { AppUserType } from 'src/types';

// The register action
export const startSignUp =
  (
    userType,
    entityName,
    entityAddress,
    entityCity,
    entityState,
    entityPostalCode,
    entityPhoneNumber,
    entityEmail,
    firstName,
    lastName,
    password
  ) =>
  async (dispatch) => {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });
    return new Promise((resolve) => {
      try {
        auth
          .createUserWithEmailAndPassword(entityEmail, password)
          .then(async ({ user }) => {
            await user.updateProfile({ displayName: entityName });
            if (userType === 'facility') {
              await createFacilityAndOwner({
                facilityData: {
                  facilityName: entityName.trim(),
                  facilityAddress: entityAddress.trim(),
                  facilityCity: entityCity.trim(),
                  facilityState: entityState,
                  facilityPostalCode: entityPostalCode.trim(),
                  facilityPhoneNumber: entityPhoneNumber.trim(),
                },
                ownerId: user.uid,
                ownerData: {
                  firstName: firstName.trim(),
                  lastName: lastName.trim(),
                },
              });
              await dispatch(fetchFacilityProfile({ userId: user.uid }));
            }
            if (userType === AppUserType.COMPANY) {
              await createCompanyAndOwner({
                companyData: {
                  companyName: entityName.trim(),
                  companyAddress: entityAddress.trim(),
                  companyCity: entityCity.trim(),
                  companyState: entityState,
                  companyPostalCode: entityPostalCode.trim(),
                  companyPhoneNumber: entityPhoneNumber.trim(),
                  notifications: 'all-notifications',
                  contactOptions: 'email-option',
                  contactInfoVisibility: true,
                  ownerId: user.uid,
                  createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                  status: 'pending',
                },
                ownerData: {
                  firstName: firstName.trim(),
                  lastName: lastName.trim(),
                  notifications: 'all-notifications',
                  contactOptions: 'email-option',
                  contactInfoVisibility: true,
                  createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                  userType: UserType.FACILITY,
                },
                ownerId: user.uid,
              });
              await dispatch(fetchCompanyProfile(user.uid));
            }

            dispatch({
              type: USER_REGISTER_SUCCESS,
              payload: user,
            });

            await dispatch(loginAfterSignUp(user.uid, user.displayName));
            resolve({ user });
          })
          .catch((e) => {
            dispatch({
              type: USER_REGISTER_FAIL,
              payload: { error: e.message },
            });
            resolve({ error: e });
          });
      } catch (error) {
        dispatch({
          type: USER_REGISTER_FAIL,
          payload: { error: error.message },
        });
      }
    });
  };

export const loginAfterSignUp = (uid, displayName) => ({
  type: USER_LOGIN_SUCCESS,
  payload: {
    uid,
    displayName,
  },
});

export const startFacilityAdminSignUp =
  ({ facilityId, userData, authUserData, inviteId }) =>
  async (dispatch) => {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });
    try {
      const { email, password } = authUserData;
      const { firstName, lastName } = userData;
      const { user } = await auth.createUserWithEmailAndPassword(email, password);
      await user.updateProfile({ displayName: `${firstName} ${lastName}` });
      await createFacilityAdmin({ userData, facilityId, userId: user.uid, inviteId });
      dispatch(fetchFacilityProfile({ userId: user.uid }));

      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: user,
      });

      dispatch(loginAfterSignUp(user.uid, user.displayName));
      return user;
    } catch (error) {
      console.log(error);
      dispatch({
        type: USER_REGISTER_FAIL,
        payload: { error: error.message },
      });
    }
  };
