export const EVENT_LIST_REQUEST = 'EVENT_LIST_REQUEST';
export const EVENT_LIST_SUCCESS = 'EVENT_LIST_SUCCESS';
export const EVENT_LIST_FAIL = 'EVENT_LIST_FAIL';

export const DASHBOARD_SHIFT_LIST_REQUEST = 'DASHBOARD_SHIFT_LIST_REQUEST';
export const DASHBOARD_SHIFT_LIST_SUCCESS = 'DASHBOARD_SHIFT_LIST_SUCCESS';
export const DASHBOARD_SHIFT_LIST_FAIL = 'DASHBOARD_SHIFT_LIST_FAIL';

export const EDIT_SHIFT_REQUEST = 'EDIT_SHIFT_REQUEST';
export const EDIT_SHIFT_SUCCESS = 'EDIT_SHIFT_SUCCESS';
export const EDIT_SHIFT_SUCCESS_FINISH = 'EDIT_SHIFT_SUCCESS_FINISH';
export const EDIT_SHIFT_FAIL = 'EDIT_SHIFT_FAIL';

export const SHIFT_ADD_DISCONTENT_REQUEST = 'SHIFT_ADD_DISCONTENT_REQUEST';
export const SHIFT_ADD_DISCONTENT_SUCCESS = 'SHIFT_ADD_DISCONTENT_SUCCESS';
export const SHIFT_ADD_DISCONTENT_SUCCESS_FINISH = 'SHIFT_ADD_DISCONTENT_SUCCESS_FINISH';
export const SHIFT_ADD_DISCONTENT_FAIL = 'SHIFT_ADD_DISCONTENT_FAIL';
