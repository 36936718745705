import { db } from '../firebase';
import { Collection, Company, CompanyOwner } from 'src/types';

const companiesCollection = db.collection(Collection.COMPANIES);
const userCollection = db.collection(Collection.USER);

export const getCompanyData = async (id: string) => {
  const company = await getCompany(id);
  return company.data() as Company;
};

export const getCompany = (id: string) => {
  return companiesCollection.doc(id).get();
};

export const getAllCompanies = () => {
  return companiesCollection.get();
};

export const getCompanyRef = (companyId: string) => {
  if (companyId) {
    return companiesCollection.doc(companyId);
  }
  return companiesCollection;
};

export const updateCompany = ({ companyId, data }: { companyId: string; data: Partial<Company> }) => {
  return companiesCollection.doc(companyId).update(data);
};

export const createCompanyAndOwner = async ({
  companyData,
  ownerId,
  ownerData,
}: {
  companyData: Company;
  ownerId: string;
  ownerData: CompanyOwner;
}) => {
  await db.runTransaction(async (transaction) => {
    const ownerRef = userCollection.doc(ownerId);
    const companyRef = companiesCollection.doc();

    transaction.set(ownerRef, { isTest: false, ...ownerData, companyOwnerOf: companyRef.id });
    transaction.set(companyRef, { isTest: false, ...companyData });
  });
};
