import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import './helpers/initFA';
import { Provider } from 'react-redux';
import store from './redux/store/store';
import { ChakraProvider } from '@chakra-ui/react';

ReactDOM.render(
  <Provider store={store}>
    <ChakraProvider>
      <Main>
        <App />
      </Main>
    </ChakraProvider>
  </Provider>,
  document.getElementById('main')
);
