import React from 'react';
import { Card, CardBody } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';

import loadable from '@loadable/component';
import WelcomeCard from '../common/WelcomeCard';
const PurchasesTable = loadable(() => import('../dashboard/PurchasesTable'));

const Invoices = () => {
  return (
    <>
      <WelcomeCard />
      <Card className="mb-3">
        <FalconCardHeader title="Invoices" light={false} />
        <CardBody className="p-0">
          <PurchasesTable />
        </CardBody>
      </Card>
    </>
  );
};

export default Invoices;
