import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  UncontrolledTooltip,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from 'reactstrap';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import timeGridPlugin from '@fullcalendar/timegrid';
import { EventClickArg, DateSelectArg, CalendarApi, EventSourceInput, FormatterInput } from '@fullcalendar/common';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownFilter from '../email/inbox/DropdownFilter';
import CalendarEventModal from './CalendarEventModal';
import AddScheduleModal from './AddScheduleModal';
import Flex from '../common/Flex';
import { loadEvents } from '../../redux/actions/fetchShiftsAction';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getWorkerData } from '../../api/worker';
import { AppUserType, Facility, Shift, WithId } from 'src/types';

interface EventViewProps {
  nurseId: string;
  start: moment.Moment;
  allDay: boolean;
  status: string;
}

const EventView = ({ nurseId, start, allDay, status }: EventViewProps) => {
  const [name, setName] = useState('');
  useEffect(() => {
    if (nurseId) {
      getWorkerData(nurseId).then(({ firstName, lastName }) => {
        if (firstName && lastName) {
          setName(`${firstName.trim()} ${lastName.trim()}`);
        }
      });
    }
  }, [nurseId]);
  return (
    <div style={{ overflow: 'hidden' }}>
      {!allDay && (
        <span
          style={{
            height: 8,
            width: 8,
            backgroundColor: status === 'cancelled' ? '#f30' : nurseId ? '#00d27a' : '#ddd',
            display: 'inline-block',
            marginRight: 4,
            borderRadius: '50%',
          }}
        />
      )}
      <span>
        {start.format('h:mm A')} {status === 'cancelled' ? ' Cancelled' : name ? ' - ' + name : ''}
      </span>
    </div>
  );
};

type FacilityFilter = {
  value: string;
  label: string;
};

const Calendar = () => {
  const calendarRef = useRef() as React.MutableRefObject<FullCalendar>;
  // @ts-ignore
  const { userType } = useSelector((state) => state.user);
  // @ts-ignore
  const { facilities } = useSelector((state) => state.facility);
  // @ts-ignore
  const { facilityProfile } = useSelector((state) => state.facilityprofile);
  const [calendarApi, setCalendarApi] = useState<CalendarApi>();
  const [title, setTitle] = useState<string>();
  const [currentFilter, setCurrentFilter] = useState('Month View');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(false);
  const [modalEventContent, setModalEventContent] = useState<EventClickArg | boolean>(false);
  const [addScheduleStartDate, setAddScheduleStartDate] = useState<moment.Moment>();
  const [facilityFilter, setFacilityFilter] = useState<FacilityFilter | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropDown = () => setDropdownOpen(!dropdownOpen);
  const facilityFilterOptions: FacilityFilter[] = facilities
    ? facilities.map((facility: WithId<Facility>) => ({ value: facility.id, label: facility.facilityName }))
    : [];

  const buttonText = {
    today: 'Today',
    month: 'Month view',
    week: 'week',
    day: 'day',
    listWeek: 'list view',
    listYear: 'year',
  };

  const eventTimeFormat: FormatterInput = {
    hour: 'numeric',
    minute: '2-digit',
    omitZeroMinute: true,
    meridiem: true,
  };

  const viewName = ['Month View', 'Week View', 'Day View', 'List View', 'Year View'];

  const views = {
    week: {
      eventLimit: 3,
    },
  };

  // @ts-ignore
  const eventListFromRedux: Array<WithId<Shift>> = useSelector((state) => state.events.events);

  let modifiedEventList: EventSourceInput = [];
  if (eventListFromRedux?.length > 0) {
    modifiedEventList = eventListFromRedux.map((eventList) => ({
      ...eventList,
      end: moment.unix(eventList.end.seconds).toDate(),
      start: moment.unix(eventList.start.seconds).toDate(),
      color: eventList.nurseId ? '#00d27a' : '#ddd',
      title: eventList.description,
    }));
  }
  const dispatch = useDispatch();

  // @ts-ignore
  const { uid } = useSelector((state) => state.email);

  useEffect(() => {
    if (userType === AppUserType.COMPANY && facilities && facilities.length > 0) {
      setFacilityFilter({ value: facilities[0].id, label: facilities[0].facilityName });
    }
  }, [userType, facilities]);

  const updateCalendar = () => {
    if (calendarRef.current) {
      setCalendarApi((calendarRef.current as InstanceType<typeof FullCalendar>).getApi());
    }
    if (userType === AppUserType.FACILITY) {
      dispatch(loadEvents(facilityProfile.id));
    } else if (userType === AppUserType.COMPANY && facilityFilter?.value) {
      dispatch(loadEvents(facilityFilter.value));
    }
  };

  useEffect(() => {
    setTitle(calendarApi?.getCurrentData().viewTitle);
  }, [calendarApi?.getCurrentData().viewTitle]);

  useEffect(() => {
    updateCalendar();
  }, [dispatch, uid, facilities, userType, facilityFilter]);

  const handleFilter = (filter: string) => {
    setCurrentFilter(filter);
    switch (filter) {
      case 'Month View':
        calendarApi?.changeView('dayGridMonth');
        break;
      case 'Week View':
        calendarApi?.changeView('timeGridWeek');
        break;
      case 'Day View':
        calendarApi?.changeView('timeGridDay');
        break;
      case 'List View':
        calendarApi?.changeView('listWeek');
        break;
      default:
        calendarApi?.changeView('listYear');
    }
  };

  const handleEventClick = (info: EventClickArg) => {
    if (info.event.url) {
      window.open(info.event.url);
      info.jsEvent.preventDefault();
    } else {
      setModalEventContent(info);
      setIsOpenModal(true);
    }
  };

  const shouldShowFacilityNameDropdown = userType === AppUserType.COMPANY && facilities && facilities.length > 0;
  return (
    <>
      <Card className="mb-3">
        <CardHeader>
          <Row noGutters className="align-items-center">
            <Col xs="auto" className="d-flex justify-content-end order-md-1">
              <UncontrolledTooltip placement="bottom" target="previous">
                Previous
              </UncontrolledTooltip>
              <UncontrolledTooltip placement="bottom" target="next">
                Next
              </UncontrolledTooltip>
              <Button
                onClick={() => {
                  calendarApi?.prev();
                  setTitle(calendarApi?.getCurrentData().viewTitle);
                }}
                color="link"
                className="icon-item icon-item-sm icon-item-hover shadow-none p-0 mr-1 ml-md-2"
                id="previous"
              >
                <FontAwesomeIcon icon="arrow-left" />
              </Button>
              <Button
                onClick={() => {
                  calendarApi?.next();
                  setTitle(calendarApi?.getCurrentData().viewTitle);
                }}
                color="link"
                className="icon-item icon-item-sm icon-item-hover shadow-none p-0 mr-1"
                id="next"
              >
                <FontAwesomeIcon icon="arrow-right" />
              </Button>
            </Col>
            <Col xs="auto" md="auto" className="order-md-2">
              <h4 className="mb-0 fs-0 fs-sm-1 fs-lg-2 calendar-title">{title || ''}</h4>
            </Col>
            {shouldShowFacilityNameDropdown && (
              <Col className="d-flex justify-content-end order-md-4" style={{ marginLeft: '8px' }}>
                <InputGroupButtonDropdown addonType="append" isOpen={dropdownOpen} toggle={toggleDropDown}>
                  <DropdownToggle className="btn-sm" color="secondary">
                    <div
                      style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <span
                        style={{
                          maxWidth: '150px',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          display: 'block',
                        }}
                      >
                        {facilityFilter?.label || 'Facility Name'}
                      </span>
                      <FontAwesomeIcon style={{ marginLeft: '5px' }} icon="caret-down" />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu>
                    {facilityFilterOptions.flatMap((option, index) => (
                      <DropdownItem
                        key={index}
                        active={facilityFilter?.value === option?.value}
                        onClick={() => {
                          setFacilityFilter(option);
                        }}
                      >
                        {option.label}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </InputGroupButtonDropdown>
              </Col>
            )}
            <Col xs md="auto" tag={Flex} justify="end" className="order-md-3">
              <Button
                size="sm"
                color="falcon-primary"
                onClick={() => {
                  calendarApi?.today();
                  setTitle(calendarApi?.getCurrentData().viewTitle);
                }}
              >
                Today
              </Button>
            </Col>
            <Col md="auto" className="d-md-none">
              <hr />
            </Col>
            <Col xs="auto" className="d-flex order-md-0">
              <Button color="primary" size="sm" onClick={() => setIsOpenScheduleModal(true)}>
                <FontAwesomeIcon icon="plus" className="mr-1" /> Add Shift
              </Button>
            </Col>
            <Col className="d-flex justify-content-end order-md-2">
              <DropdownFilter
                className="mr-2"
                filters={viewName}
                currentFilter={currentFilter}
                handleFilter={handleFilter}
                icon="sort"
                right
              />
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="p-0">
          <FullCalendar
            ref={calendarRef}
            headerToolbar={false}
            plugins={[dayGridPlugin, bootstrapPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
            initialView="dayGridMonth"
            themeSystem="bootstrap"
            dayMaxEvents={2}
            height={800}
            stickyHeaderDates={false}
            editable
            selectable
            eventContent={(eventInfo) => {
              return (
                <EventView
                  status={eventInfo?.event.extendedProps.status}
                  allDay={eventInfo.event.allDay}
                  nurseId={eventInfo.event.extendedProps.nurseId}
                  start={moment(eventInfo.event.start)}
                />
              );
            }}
            selectMirror
            select={(info: DateSelectArg) => {
              setIsOpenScheduleModal(true);
              setAddScheduleStartDate(moment(info.start));
            }}
            views={views}
            eventTimeFormat={eventTimeFormat}
            eventClick={handleEventClick}
            events={modifiedEventList}
            buttonText={buttonText}
          />
        </CardBody>
      </Card>

      <AddScheduleModal
        isOpenScheduleModal={isOpenScheduleModal}
        setIsOpenScheduleModal={setIsOpenScheduleModal}
        userType={userType}
        initialStartDate={addScheduleStartDate}
        facilities={facilities}
        initialFacilityId={facilityFilter?.value}
        onSuccess={updateCalendar}
      />

      <CalendarEventModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        modalEventContent={modalEventContent}
      />
    </>
  );
};

export default Calendar;
