import {
  USER_GOOGLE_LOGIN_FAIL,
  USER_GOOGLE_LOGIN_REQUEST,
  USER_GOOGLE_LOGIN_SUCCESS,
  USER_LOGOUT,
} from '../constants/userConstants';

export interface UserGoogleLoginReducerState {
  loading?: boolean;
  uid?: string;
  email?: string;
  name?: string;
  error?: any;
}

export interface UserGoogleLoginReducerActionPayload {
  uid: string;
  email: string;
  displayName: string;
  error?: any;
}
export interface UserGoogleLoginReducerAction {
  type:
    | typeof USER_GOOGLE_LOGIN_SUCCESS
    | typeof USER_LOGOUT
    | typeof USER_GOOGLE_LOGIN_FAIL
    | typeof USER_GOOGLE_LOGIN_REQUEST;
  payload: UserGoogleLoginReducerActionPayload;
}

export const userGoogleLoginReducer = (
  state: UserGoogleLoginReducerState = {},
  action: UserGoogleLoginReducerAction
): UserGoogleLoginReducerState => {
  switch (action.type) {
    case USER_GOOGLE_LOGIN_REQUEST:
      return { loading: true };
    case USER_GOOGLE_LOGIN_SUCCESS:
      return {
        loading: false,
        uid: action.payload.uid,
        email: action.payload.email,
        name: action.payload.displayName,
      };
    case USER_GOOGLE_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};
