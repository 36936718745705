import { merge } from 'lodash';
import { toast } from 'react-toastify';
import { Collection } from 'src/types';
import { db } from '../../firebase';
import { EDIT_SHIFT_REQUEST, EDIT_SHIFT_SUCCESS, EDIT_SHIFT_FAIL } from '../constants/eventConstants';

// The Edit Shift action
export const startEditShift = (shift, changes) => async (dispatch) => {
  dispatch({
    type: EDIT_SHIFT_REQUEST,
  });

  try {
    await db.collection(Collection.SHIFTS).doc(shift).set(changes, { merge: merge });

    dispatch({
      type: EDIT_SHIFT_SUCCESS,
    });
    toast.success('The shift was edited successfully');
  } catch (error) {
    dispatch({
      type: EDIT_SHIFT_FAIL,
      error,
    });
    throw error;
  }
};
