import { AppUserType } from 'src/types';
import { FETCH_COMPANY_PROFILE_SUCCESS, FETCH_FACILITY_PROFILE_SUCCESS } from '../constants/userConstants';

export interface UserReducerState {
  userType?: AppUserType;
}

export interface UserReducerAction {
  payload: {
    uid?: string;
    email?: string;
    name?: string;
    displayName?: string;
  };
  type: typeof FETCH_COMPANY_PROFILE_SUCCESS | typeof FETCH_FACILITY_PROFILE_SUCCESS;
}

export const userReducer = (state: UserReducerState = {}, action: UserReducerAction): UserReducerState => {
  switch (action.type) {
    case FETCH_COMPANY_PROFILE_SUCCESS:
      return {
        userType: AppUserType.COMPANY,
      };
    case FETCH_FACILITY_PROFILE_SUCCESS:
      return {
        userType: AppUserType.FACILITY,
      };
    default:
      return state;
  }
};
