import { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { CloseButton, Fade } from '../components/common/Toast';
import DashboardLayout from './DashboardLayout';
import ErrorLayout from './ErrorLayout';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { analytics, auth } from '../firebase';
import { emailLogin } from '../redux/actions/loginWithEmailAction';
import Loader from '../components/common/Loader';
import { fetchFacilityProfile } from '../redux/actions/fetchFacilityProfileAction';
import { fetchCompanyProfile } from '../redux/actions/fetchCompanyProfileAction';
import { getFacilities } from '../redux/actions/facilityActions';
import InvitePage from '../components/invite/InvitePage';
import { UserType } from 'src/types';
import { UserContext } from 'src/context/Context';
import AuthBasicLayout from './AuthBasicLayout';
import WizardLayout from '../components/auth/wizard/WizardLayout';
import AuthCardRoutes from '../components/auth/card/AuthCardRoutes';
import AuthSplitRoutes from '../components/auth/split/AuthSplitRoutes';
import useAppDispatch from 'src/hooks/useAppDispatch';
import { getUserData } from 'src/api/worker';
import useUserId from 'src/hooks/useUserId';

const Layout = () => {
  const dispatch = useAppDispatch();

  const [checking, setChecking] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { setIsAdminUser } = useContext(UserContext);
  const { userId } = useUserId();

  useEffect(() => {
    const getProfile = async () => {
      if (userId) {
        dispatch<any>(fetchFacilityProfile({ userId }));
        const companyProfile = await dispatch<any>(fetchCompanyProfile(userId));
        // @ts-ignore
        if (companyProfile?.id) {
          // @ts-ignore
          dispatch(getFacilities({ companyId: companyProfile.id }));
        }
      }
    };
    getProfile();
  }, [userId]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      const userId = user?.uid;
      if (userId) {
        analytics.setUserId(userId);
        Sentry.setUser({ id: userId });
        dispatch(emailLogin(userId, user?.displayName));
        const userData = await getUserData(userId);
        if (userData?.userType !== UserType.ADMIN || !('isAdmin' in userData) || !userData.isAdmin) {
          dispatch<any>(fetchFacilityProfile({ userId }));
          const companyProfile = await dispatch<any>(fetchCompanyProfile(userId));
          // @ts-ignore
          if (companyProfile?.id) {
            // @ts-ignore
            dispatch(getFacilities({ companyId: companyProfile.id }));
          }
        }
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
      setChecking(false);
    });
    return () => {
      unsubscribe();
    };
  }, [dispatch, setChecking, setIsLoggedIn, setIsAdminUser]);

  if (checking) {
    return <Loader />;
  }

  return (
    <Router>
      <Switch>
        <Route path="/authentication/card" component={AuthCardRoutes} />
        <Route path="/authentication/split" component={AuthSplitRoutes} />
        <Route path="/authentication/wizard" component={WizardLayout} />
        <Route path="/errors" component={ErrorLayout} />
        <Route path="/invite/:inviteId" component={InvitePage} />
        <PublicRoute isAuthenticated={isLoggedIn} path="/authentication/basic" component={AuthBasicLayout} />
        <PrivateRoute isAuthenticated={isLoggedIn} component={DashboardLayout} />
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
    </Router>
  );
};

export default Layout;
