import React, { Fragment, useState, useEffect, FormEventHandler } from 'react';
import { Button, CustomInput, Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import { startSignUp } from '../../redux/actions/registerWithEmailAction';
import { startGoogleLogin } from '../../redux/actions/googleLoginAction';
import { startFacebookLogin } from '../../redux/actions/facebookLoginAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Divider from '../common/Divider';
import { useDispatch } from 'react-redux';

interface RegistrationFormProps {
  hasLabel?: boolean;
}

const RegistrationForm = ({ hasLabel = false }: RegistrationFormProps) => {
  // State
  const [facilityName, setFacilityName] = useState('');
  const [facilityEmail, setFacilityEmail] = useState('');
  const [password, setPassword] = useState('');
  const [facilityPhoneNumber, setFacilityPhoneNumber] = useState('');
  const [facilityAddress, setFacilityAddress] = useState('');
  const [facilityCity, setFacilityCity] = useState('');
  const [facilityState, setFacilityState] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isAccepted, setIsAccepted] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const dispatch = useDispatch();

  // Handler
  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    dispatch(
      startSignUp(
        facilityName,
        facilityAddress,
        facilityCity,
        facilityState,
        facilityPhoneNumber,
        facilityEmail,
        password
      )
    );
    toast.success(`Successfully registered as ${facilityName}`);
  };
  const handleGoogleLogin = () => {
    dispatch(startGoogleLogin());
  };
  const handleFacebookLogin = () => {
    dispatch(startFacebookLogin());
  };

  const customTostifyId = 'custom-id';

  useEffect(() => {
    setIsDisabled(
      !facilityName ||
        !facilityPhoneNumber ||
        !facilityAddress ||
        !facilityCity ||
        !facilityState ||
        !facilityEmail ||
        !password ||
        !confirmPassword ||
        !isAccepted ||
        password !== confirmPassword
    );

    if (password !== confirmPassword && confirmPassword !== '') {
      toast.error(`The passwords must match, please keep typing until they are the same`, { toastId: customTostifyId });
    }
  }, [
    facilityName,
    facilityPhoneNumber,
    facilityAddress,
    facilityCity,
    facilityState,
    facilityEmail,
    password,
    confirmPassword,
    isAccepted,
  ]);

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        {hasLabel && <Label>Facility Name</Label>}
        <Input
          placeholder={!hasLabel ? 'Facility Name' : ''}
          value={facilityName}
          onChange={({ target }) => setFacilityName(target.value)}
        />
      </FormGroup>
      <FormGroup>
        {hasLabel && <Label>Facility Address</Label>}
        <Input
          placeholder={!hasLabel ? 'Facility Address' : ''}
          value={facilityAddress}
          onChange={({ target }) => setFacilityAddress(target.value)}
        />
      </FormGroup>
      <div className="form-row">
        <FormGroup className="col-6">
          {hasLabel && <Label>Facility City</Label>}
          <Input
            placeholder={!hasLabel ? 'Facility City' : ''}
            value={facilityCity}
            onChange={({ target }) => setFacilityCity(target.value)}
          />
        </FormGroup>
        <FormGroup className="col-6">
          {hasLabel && <Label>Facility State</Label>}
          <Input
            placeholder={!hasLabel ? 'Facility State' : ''}
            value={facilityState}
            onChange={({ target }) => setFacilityState(target.value)}
          />
        </FormGroup>
      </div>
      <FormGroup>
        {hasLabel && <Label>Facility Phone</Label>}
        <Input
          type="tel"
          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
          placeholder={!hasLabel ? 'Facility Phone xxx-xxx-xxxx' : ''}
          value={facilityPhoneNumber}
          onChange={({ target }) => setFacilityPhoneNumber(target.value)}
        />
      </FormGroup>
      <FormGroup>
        {hasLabel && <Label>Facility Email address</Label>}
        <Input
          placeholder={!hasLabel ? 'Facility Email address' : ''}
          value={facilityEmail}
          onChange={({ target }) => setFacilityEmail(target.value)}
          type="email"
        />
      </FormGroup>
      <div className="form-row">
        <FormGroup className="col-6">
          {hasLabel && <Label>Password</Label>}
          <Input
            placeholder={!hasLabel ? 'Password' : ''}
            value={password}
            onChange={({ target }) => setPassword(target.value)}
            type="password"
          />
        </FormGroup>
        <FormGroup className="col-6">
          {hasLabel && <Label>Confirm Password</Label>}
          <Input
            placeholder={!hasLabel ? 'Confirm Password' : ''}
            value={confirmPassword}
            onChange={({ target }) => setConfirmPassword(target.value)}
            type="password"
          />
        </FormGroup>
      </div>

      <CustomInput
        id="customCheckTerms"
        label={
          <Fragment>
            I accept the <Link to="#!">terms</Link> and <Link to="#!">privacy policy</Link>
          </Fragment>
        }
        checked={isAccepted}
        onChange={({ target }) => setIsAccepted(target.checked)}
        type="checkbox"
      />
      <FormGroup>
        <Button color="primary" block className="mt-3" disabled={isDisabled}>
          Register
        </Button>
      </FormGroup>
      <Divider className="mt-4">or register with</Divider>

      <FormGroup className="mb-0">
        <Row noGutters>
          <Col sm={6} className="pr-sm-1">
            <Button onClick={handleGoogleLogin} color="outline-google-plus" size="sm" block className="mt-2" to="#!">
              <FontAwesomeIcon icon={['fab', 'google-plus-g']} transform="grow-8" className="mr-2" /> google
            </Button>
          </Col>
          <Col sm={6} className="pl-sm-1">
            <Button onClick={handleFacebookLogin} color="outline-facebook" size="sm" block className="mt-2" to="#!">
              <FontAwesomeIcon icon={['fab', 'facebook-square']} transform="grow-8" className="mr-2" /> facebook
            </Button>
          </Col>
        </Row>
      </FormGroup>
    </Form>
  );
};

export default RegistrationForm;
