import React, { useContext } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import WizardError from './WizardError';
import { AuthWizardContext } from '../../../context/Context';
import Datetime from 'react-datetime';
import classNames from 'classnames';
import { AuthWizardUser } from 'src/types';

interface WizardInputProps {
  label?: React.ReactNode;
  id: string;
  name: string;
  errors: any;
  tag?: any;
  type?: string;
  options?: any[];
  placeholder?: string;
  className?: string;
  customType?: string;
  isUsingPlaceholder?: boolean;
  ref?: any;
}

const WizardInput = ({
  label,
  id,
  name,
  errors,
  tag: Tag = Input,
  type = 'text',
  options = [],
  placeholder,
  className,
  customType,
  isUsingPlaceholder,
  ref,
  ...rest
}: WizardInputProps) => {
  const { user, handleInputChange } = useContext(AuthWizardContext);

  if (customType === 'datetime') {
    let defaultValue;
    if (name in user) {
      const userName = name as keyof AuthWizardUser;
      const value = user[userName];
      if (typeof value === 'string') {
        defaultValue = value;
      }
    }
    return (
      <FormGroup>
        {!!label && <Label>{label}</Label>}
        <Datetime
          dateFormat="DD/MM/YYYY"
          timeFormat={false}
          defaultValue={defaultValue}
          onChange={(setStartDate) =>
            handleInputChange({
              name,
              value: typeof setStartDate === 'string' ? setStartDate : setStartDate.toString(),
            })
          }
          inputProps={{
            name,
            id,
            placeholder,
            ref,
          }}
          {...rest}
        />
      </FormGroup>
    );
  }

  if (type === 'checkbox' || type === 'switch' || type === 'radio') {
    return (
      <FormGroup>
        <Tag
          name={name}
          id={id}
          type={type}
          className={className}
          label={
            <>
              {label}
              <WizardError error={errors[name]?.message} className="fs--1 font-weight-normal d-block" />
            </>
          }
          innerRef={ref}
          {...rest}
        />
      </FormGroup>
    );
  }
  if (type === 'select') {
    let defaultValue;
    if (name in user) {
      const userName = name as keyof AuthWizardUser;
      const value = user[userName];
      defaultValue = value;
    }
    return (
      <FormGroup>
        {!!label && <Label for={id}>{label}</Label>}
        <Tag
          name={name}
          id={id}
          defaultValue={defaultValue}
          type={type}
          label={label}
          className={classNames(className, { 'border-danger': errors[name]?.message })}
          innerRef={ref}
          {...rest}
          style={isUsingPlaceholder ? { color: 'rgb(182, 193, 210)' } : {}}
        >
          <option value="">{placeholder}</option>
          {options.map((option, index) => (
            <option value={option} key={index}>
              {option}
            </option>
          ))}
        </Tag>
        <WizardError error={errors[name]?.message} className="mt-1" />
      </FormGroup>
    );
  }
  let defaultValue;
  if (name in user) {
    const userName = name as keyof AuthWizardUser;
    const value = user[userName];
    if (typeof value === 'string') {
      defaultValue = value;
    }
  }
  return (
    <FormGroup>
      {!!label && <Label for={id}>{label}</Label>}
      <Tag
        name={name}
        id={id}
        defaultValue={defaultValue}
        type={type}
        placeholder={placeholder}
        className={classNames(className, { 'border-danger': errors[name]?.message })}
        innerRef={ref}
        {...rest}
      />
      <WizardError error={errors[name]?.message} className="mt-1" />
    </FormGroup>
  );
};

export default WizardInput;
